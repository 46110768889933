import { IMessage } from "../../Types/ConversationTypes/ConversationTypes";
import { Box } from "@mui/system";
import moment from "moment";
import { imgUrl } from "../../Helpers/utils/Constant";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

interface IProps {
  sMessage: IMessage;
}
const ShowConvMessage = ({ sMessage }: IProps) => {
  const {
    support_conversation_msg_text,
    support_conversation_msg_text_time,
    support_conversation_msg_atchments,
    support_conversation_msg_sender,
  } = sMessage;

  const isAdminMessage = support_conversation_msg_sender === "admin";
  const getMessageClass = () =>
    isAdminMessage ? "admin-message" : "own-message message-box";
  const getMessageDivClass = () =>
    isAdminMessage ? "admin-message-div" : "own-message-div";
  const getAttachmentClass = () =>
    isAdminMessage ? "admin-atchment" : "own-atchment";
  return (
    <Box className="show-message-container">
      <Box className={getMessageClass()}>
        <Box className={getMessageDivClass()}>
          {support_conversation_msg_text && (
            <h3 style={{ wordBreak: "break-all" }}>
              {support_conversation_msg_text}
            </h3>
          )}

          {support_conversation_msg_atchments.length > 0 && (
            <Box className={getAttachmentClass()}>
              {support_conversation_msg_atchments.map((attachment, index) => (
                <Zoom key={index}>
                  <img
                    width={70}
                    height={60}
                    style={{ objectFit: "contain" }}
                    src={`${imgUrl}/support_conversation_files/${attachment}`}
                    alt=""
                  />
                </Zoom>
              ))}
            </Box>
          )}

          <p>{moment(support_conversation_msg_text_time).fromNow()}</p>
        </Box>
      </Box>
    </Box>
    // <Box className="show-message-container">
    //   <Box
    //     className={
    //       support_conversation_msg_sender === "admin"
    //         ? "admin-message"
    //         : "own-message message-box"
    //     }
    //   >
    //     <Box
    //       className={
    //         support_conversation_msg_sender === "admin"
    //           ? "admin-message-div"
    //           : "own-message-div"
    //       }
    //     >
    //       {support_conversation_msg_text && (
    //         <h3 style={{ wordBreak: "break-all" }}>
    //           {support_conversation_msg_text}
    //         </h3>
    //       )}

    //       {support_conversation_msg_atchments.length ? (
    //         <Box
    //           className={
    //             support_conversation_msg_sender === "admin"
    //               ? "admin-atchment"
    //               : "own-atchment"
    //           }
    //         >
    //           {support_conversation_msg_atchments?.map(
    //             (sAtchments: string, index: number) => {
    //               return (
    //                 <Zoom key={index}>
    //                   <img
    //                     width={70}
    //                     height={60}
    //                     style={{ objectFit: "contain" }}
    //                     src={`${imgUrl}/support_conversation_files/${sAtchments}`}
    //                     alt=""
    //                   />
    //                 </Zoom>
    //               );
    //             }
    //           )}
    //         </Box>
    //       ) : (
    //         ""
    //       )}
    //       <p>{moment(support_conversation_msg_text_time).fromNow()}</p>
    //     </Box>
    //   </Box>
    // </Box>
  );
};

export default ShowConvMessage;
