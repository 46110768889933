import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import { getStatusLabel } from '../../Utils/Label/status-label';
import ModalsWrapper from '../../Components/Modals/ModalsWrapper';
import CreateOwnershipApplication from './CreateOwnershipApplication';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { useChangeOwnershipQuery } from '../../rest/useChangeOwnership ';
import { IOwnershipChange } from '../../rest/types';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import TableNoDataMessage from '../../Components/TableMessage/TableNoDataMessage';
import { Button, Grid } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
interface Data {
  ownership_change_application_id: number;
  user_member_company_name: string;
  ownership_change_application_status: string;
  ownership_change_application_created_at: string;
  user_member_representative_photo: string;
  action?: string;
}

function createData(
  ownership_change_application_id: number,
  user_member_company_name: string,
  ownership_change_application_status: string,
  ownership_change_application_created_at: string,
  user_member_representative_photo: string
): Partial<IOwnershipChange> {
  return {
    ownership_change_application_id,
    user_member_company_name,
    ownership_change_application_status,
    ownership_change_application_created_at,
    user_member_representative_photo,
  };
}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'ownership_change_application_id',
    numeric: false,
    disablePadding: false,
    label: 'Application ID',
  },
  {
    id: 'ownership_change_application_status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'ownership_change_application_created_at',
    numeric: false,
    disablePadding: false,
    label: 'Created at',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({ setOpen }: { setOpen: any }) {
  const { user } = useAuthContext();
  return (
    <Grid
      container
      justifyContent={'space-between'}
      sx={{ p: 2 }}
      alignContent={'center'}
    >
      <Grid item>
        <Typography variant='h6' id='tableTitle' component='div'>
          Ownership Change Application
        </Typography>
      </Grid>
      <Grid item>
        {' '}
        {user?.user_member_account_status !== 'pending' &&
          user?.user_member_account_status !== 'inactive' &&
          user?.user_member_account_status !== 'rejected' && (
            <Button
              onClick={() => setOpen(true)}
              size='small'
              component='label'
              variant='contained'
              startIcon={<BorderColorIcon />}
              fullWidth
            >
              Create Ownership Change Application
            </Button>
          )}{' '}
      </Grid>
    </Grid>
  );
}

export default function OwnershipApplication() {
  const { user } = useAuthContext();
  const { companyOwnership, isLoading } = useChangeOwnershipQuery({
    ownership_change_application_member_id: user.user_member_id!,
  });
  const [order, setOrder] = React.useState<Order>('asc');
  const [open, setOpen] = React.useState<boolean>(false);
  const [orderBy, setOrderBy] = React.useState<keyof Data>(
    'ownership_change_application_id'
  );
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.ownership_change_application_id!);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, name: number) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const rows = companyOwnership.map((row) =>
    createData(
      row.ownership_change_application_id,
      row.user_member_company_name,
      row.ownership_change_application_status,
      row.ownership_change_application_created_at,
      row.user_member_representative_photo
        ? row.user_member_representative_photo
        : 'public/logo.png'
    )
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Box sx={{ width: '100%', mt: 5 }}>
        <Paper sx={{ width: '100%', mb: 2, mt: 5 }}>
          <EnhancedTableToolbar setOpen={setOpen} />
          <TableContainer>
            <Table aria-labelledby='tableTitle' size={'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, row.ownership_change_application_id!)
                      }
                      tabIndex={-1}
                      key={row.user_member_company_name}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell id={labelId} align='left'>
                        {row.ownership_change_application_id}
                      </TableCell>
                      <TableCell align='left'>
                        {getStatusLabel(
                          row?.ownership_change_application_status!
                        )}
                      </TableCell>
                      <TableCell align='left'>
                        {moment(
                          `${row.ownership_change_application_created_at}`
                        ).format('DD-MMM-YYYY')}
                      </TableCell>
                      <TableCell align='left'>
                        <Tooltip title='View'>
                          <Link
                            to={`/ownership-change-applications/${row.ownership_change_application_id}`}
                          >
                            <IconButton>
                              <VisibilityIcon fontSize='small' />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {!isLoading && rows.length === 0 && <TableNoDataMessage />}
                {isLoading && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell align='center' colSpan={6}>
                      Loading...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <ModalsWrapper
        modalData={{ title: 'Create Ownership application' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <CreateOwnershipApplication handelClose={setOpen} />
      </ModalsWrapper>
    </>
  );
}
