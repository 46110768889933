import React, { useState } from 'react';
import BankPayment from './BankPayment';
import { useSearchParams } from 'react-router-dom';
import ManualWalletPayment from './ManualWalletPayment';

const ManulPayment = ({
  setRefetch,
  setManualPayment,
}: {
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  setManualPayment: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [beneficiaryType, setBeneficiaryType] = useState(
    searchParams.get('beneficiaryType') || 'bank'
  );

  return beneficiaryType === 'bank' ? (
    <BankPayment
      setBeneficiaryType={setBeneficiaryType}
      setRefetch={setRefetch}
      setManualPayment={setManualPayment}
    />
  ) : (
    <ManualWalletPayment setBeneficiaryType={setBeneficiaryType} />
  );
};

export default ManulPayment;
