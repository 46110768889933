import axios from 'axios';
import { getToken } from './get-token';
import { url } from '../../Helpers/utils/Constant';
import { destroyCookie } from 'nookies';

const http = axios.create({
  baseURL: url,
  timeout: 10000,
});

// Change request data/error here
http.interceptors.request.use(
  (config) => {
    const token = getToken();
    config.headers.Authorization = `Bearer ${token ? token : ''}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const retryRequest: any = async (error: any, retries = 3) => {
  if (retries <= 0) {
    return Promise.reject(error);
  }
  await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before retrying
  return http(error.config).catch((err) => retryRequest(err, retries - 1));
};

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('auth');
      destroyCookie(null, 'haab_ua');
      window.location.href = '/login';
    }
    if (axios.isCancel(error)) {
      console.log('Request cancelled:', error.message);
    } else if (
      error.code === 'ECONNABORTED' &&
      error.message.indexOf('timeout') !== -1
    ) {
      console.log('Request timeout:', error);
      return retryRequest(error);
    }
    return Promise.reject(error);
  }
);

export class HttpClient {
  static async get<T>(url: string, params?: unknown) {
    const response = await http.get<T>(url, { params });
    return response.data;
  }

  static async post<T>(url: string, data: unknown, options?: any) {
    const response = await http.post<T>(url, data, options);
    return response.data;
  }

  static async put<T>(url: string, data?: unknown) {
    const response = await http.put<T>(url, data);
    return response.data;
  }
  static async patch<T>(url: string, data: unknown) {
    const response = await http.patch<T>(url, data);
    return response.data;
  }

  static async delete<T>(url: string) {
    const response = await http.delete<T>(url);
    return response.data;
  }
}
