import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModalsWrapper from '../../Components/Modals/ModalsWrapper';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import TableLoader from '../../Components/Spinner/TableLoader';
import TableNoDataMessage from '../../Components/TableMessage/TableNoDataMessage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { getStatusLabel } from '../../Utils/Comibined';
import AddAgencyIfoModal from './AddAgencyIfoModal';
import { useAgencyInfoUpdate } from '../../rest/useAgencyInfoUpdateApplication';
import moment from 'moment';
import BorderColorIcon from '@mui/icons-material/BorderColor';
const AgencyInfoUpdateApplication = () => {
  const { user } = useAuthContext();

  const [open, setOpen] = useState(false);
  const { agencyInfo, isLoading } = useAgencyInfoUpdate(user.user_member_id!);

  return (
    <>
      <Card sx={{ mt: 5 }}>
        <Grid
          container
          justifyContent={'space-between'}
          sx={{ p: 2 }}
          alignContent={'center'}
        >
          <Grid item>
            <Typography variant='h6' id='tableTitle' component='div'>
              Agency Information Update
            </Typography>
          </Grid>
          <Grid item>
            {' '}
            {user?.user_member_account_status !== 'pending' &&
              user?.user_member_account_status !== 'inactive' &&
              user?.user_member_account_status !== 'rejected' && (
                <Button
                  onClick={() => setOpen(true)}
                  size='small'
                  component='label'
                  variant='contained'
                  startIcon={<BorderColorIcon />}
                  fullWidth
                >
                  Create Agency information update Application
                </Button>
              )}{' '}
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>

                <TableCell sx={{ fontWeight: 700 }}>Created at</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableLoader />
              ) : (
                <>
                  {agencyInfo?.length ? (
                    <>
                      {agencyInfo.map((item) => {
                        return (
                          <TableRow
                            key={item.agency_info_update_application_id}
                          >
                            <TableCell>
                              {item.agency_info_update_application_id}
                            </TableCell>

                            <TableCell>
                              {moment(item.agency_info_created_at).format(
                                'MMM Do YYYY'
                              )}
                            </TableCell>
                            <TableCell>{getStatusLabel(item.status)}</TableCell>
                            <TableCell>
                              <Link
                                to={`/agency-info-update-application/${item.agency_info_update_application_id}`}
                              >
                                {' '}
                                <VisibilityIcon sx={{ color: 'black' }} />
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableNoDataMessage />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <ModalsWrapper
        modalData={{ title: 'Agency information update application' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <AddAgencyIfoModal handleClose={() => setOpen(false)} />
      </ModalsWrapper>
    </>
  );
};

export default AgencyInfoUpdateApplication;
