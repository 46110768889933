import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import PageLoader from '../../Components/Spinner/PageLoader';

import { getStatusLabel } from '../../Utils/Comibined';
import { useSingleIDCardQuery } from './api/idCardEndPoint';
import PdfAndImageView from '../../Common/PdfAndImageView';
import GetDataApi from '../../Common/FetchData/GetDataApi';
type MemberData = {
  id: number;
  user_member_id: number;
  applicant_name: string;
  designation: string;
  date_of_birth: string; // or Date if you convert it to Date objects
  blood_group: string;
  telephone: string;
  cellphone: string;
  fax: string | null;
  email: string;
  tin_number: string | null;
  website: string;
  tin_file: string;
  trade_license_file: string | null;
  civil_aviation_renewed_certificate_file: string | null;
  photo: string;
  signature_file: string;
  specimen_signature_file: string | null;
  status: string;
  nid_file: string;
  created_at: string; // or Date if converted
  user_member_company_name: string;
  user_member_representative_photo: string | null;
};

const SingleIdCard = () => {
  const { cardID } = useParams();
  const { response: singleIdCard, loading: isLoading } = GetDataApi<MemberData>(
    `/api/member/id-card/application/${Number(cardID)}`
  );

  return (
    <>
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Box>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                gutterBottom
                fontSize={17}
              >
                ID Card
              </Typography>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                fontSize={17}
                sx={{ pl: 1 }}
                gutterBottom
              >
                {/* # {singleIdCard?.id} */}
              </Typography>
            </Box>
          </Box>
          <Divider />

          {isLoading ? (
            <PageLoader />
          ) : singleIdCard ? (
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Application Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {moment(singleIdCard?.created_at).format('MMM Do YY')}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>Company Name</Typography>
                    </TableCell>
                    <TableCell>
                      {singleIdCard?.user_member_company_name}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Applicant Name
                      </Typography>
                    </TableCell>
                    <TableCell>{singleIdCard?.applicant_name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>Designation</Typography>
                    </TableCell>
                    <TableCell>{singleIdCard?.designation}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>Status</Typography>
                    </TableCell>
                    <TableCell>
                      {getStatusLabel(singleIdCard?.status)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download TIN File
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView file={singleIdCard?.tin_file} />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download Applicant Photo
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView file={singleIdCard?.photo} />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download NID File
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView file={singleIdCard?.nid_file} />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle2'>
                        Download Signature
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PdfAndImageView file={singleIdCard?.signature_file} />
                    </TableCell>
                  </TableRow>

                  {singleIdCard?.specimen_signature_file && (
                    <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2'>
                          Download Specimen Signature
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <PdfAndImageView
                          file={singleIdCard?.specimen_signature_file}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SingleIdCard;
