import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { HttpClient } from "../../../rest/utils/http";
import { IRequester, IRequesterResponse } from "../../../Types/RequesterTypes";

const fetchAllProposerRequest = async (id: number, value: string) => {
  const fetchData = await HttpClient.get<IRequesterResponse>(
    `${API_ENDPOINTS.PROPOSER_REQUEST}/${id}/${value}`
  );
  return fetchData;
};

const fetchProposerReceiver = async (id: number, value: string) => {
  const fetchData = await HttpClient.get<IRequesterResponse>(
    `${API_ENDPOINTS.PROPOSER_RECEIVER}/${id}/${value}`
  );
  return fetchData;
};

// query
export function useAllProposerRequestQuery(id: number, value: string) {
  const { data, isLoading, error } = useQuery<IRequesterResponse, Error>(
    [API_ENDPOINTS.PROPOSER_REQUEST, { id, value }],
    () => fetchAllProposerRequest(id, value),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    requester: data?.data ?? ([] as IRequester[]),
    isLoading,
    error,
  };
}
export function useProposerReceiverQuery(id: number, value: string) {
  const { data, isLoading, error } = useQuery<IRequesterResponse, Error>(
    [API_ENDPOINTS.PROPOSER_RECEIVER, { id, value }],
    () => fetchProposerReceiver(id, value),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    proposerReceiver: data?.data ?? ([] as IRequester[]),
    isLoading,
    error,
  };
}

// handelReject
export const PostHandelReject = async (data: any) => {
  return await HttpClient.post(`${API_ENDPOINTS.REJECT_PROPOSER}`, data);
};
