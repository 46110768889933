import React, { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Box, Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import { imgUrl } from '../Helpers/utils/Constant';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import CloseIcon from '@mui/icons-material/Close';
const PdfAndImageView = ({
  file,
  text,
  color,
  icon,
  fontSize,
}: {
  file: string | null | undefined;
  text?: string;
  color?: string;
  icon?: React.ReactNode;

  fontSize?: 'inherit' | 'small' | 'large' | 'medium';
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const type = file?.split('.')?.[1];
  const docs = [{ uri: `${imgUrl}/${file}`, fileType: type }];
  const [imagePreview, setImagePreview] = useState(false);

  const renderPreviewIcon = () => {
    if (text) {
      return (
        <Grid>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1, color: color ? color : '#00e' }}
            gutterBottom
          >
            <span>{text}</span>
          </Typography>
        </Grid>
      );
    }

    if (type === 'pdf') {
      return <PictureAsPdfSharpIcon />;
    }

    if (icon) {
      return <RemoveRedEyeSharpIcon color={'success'} fontSize={fontSize} />;
    }

    if (['jpg', 'jpeg', 'png', 'gif'].includes(type || '')) {
      return (
        <img
          src={`${imgUrl}/${file}`}
          alt=''
          height={50}
          width={50}
          style={{ objectFit: 'contain' }}
        />
      );
    }

    if (file === null || 'undefined') {
      return 'Not Provided';
    }

    return <RemoveRedEyeSharpIcon />;
  };

  return (
    <>
      <span onClick={() => setImagePreview(true)} style={{ cursor: 'pointer' }}>
        {renderPreviewIcon()}
      </span>

      <Dialog
        maxWidth='md'
        fullWidth
        open={imagePreview}
        onClose={() => setImagePreview(false)}
      >
        <DialogTitle>
          <Grid container justifyContent={'space-between'}>
            <Grid item>Preview Image</Grid>
            <Grid
              item
              onClick={() => setImagePreview(false)}
              sx={{ cursor: 'pointer' }}
            >
              <CloseIcon />{' '}
            </Grid>
          </Grid>
        </DialogTitle>
        <div style={{ padding: '0px 10px' }}>
          {file && type ? (
            <>
              {type === 'pdf' ? (
                <div style={{ width: '100%', maxHeight: '80%' }}>
                  <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js'>
                    <div>
                      <Viewer
                        fileUrl={`${imgUrl}/${file}`}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </div>
                  </Worker>
                  <a
                    href={`${imgUrl}/${file}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Download
                  </a>
                </div>
              ) : (
                <>
                  {type === 'docx' ? (
                    <>
                      <DocViewer
                        documents={docs}
                        style={{ height: '600px', width: '800px' }}
                        pluginRenderers={DocViewerRenderers}
                      />
                      <Box>
                        <a
                          href={`${imgUrl}/${file}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Download
                        </a>
                      </Box>
                    </>
                  ) : (
                    <>
                      <img
                        src={`${imgUrl}/${file}`}
                        alt=''
                        height={550}
                        width={550}
                        style={{ objectFit: 'contain' }}
                      />
                      <Box>
                        <a
                          href={`${imgUrl}/${file}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Download
                        </a>
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <Box py={4} alignItems={'center'} justifyContent={'center'}>
              No file found
            </Box>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default PdfAndImageView;
