import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";

import { getStatusLabel } from "../../Utils/Label/status-label";
import ModalsWrapper from "../../Components/Modals/ModalsWrapper";
import { ICompanyProposerReceiver } from "../../rest/types";
import { useProposerReceiver } from "../../rest/useRequest";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import UpdateProposerStatus from "./UpdateProposerStatus";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import TableLoader from "../../Components/Spinner/TableLoader";
import TableNoDataMessage from "../../Components/TableMessage/TableNoDataMessage";

interface Data {
  proposer_request_id: number;
  proposer_request_to_company_name: string;
  proposer_request_to_representative_name: string;
  proposer_request_status: string;
  proposer_request_date: string;
  action: string;
}

function createData(
  proposer_request_id: number,
  proposer_request_to_company_name: string,
  proposer_request_to_representative_name: string,
  proposer_request_status: string,
  proposer_request_date: string
): Partial<ICompanyProposerReceiver> {
  return {
    proposer_request_id,
    proposer_request_to_company_name,
    proposer_request_to_representative_name,
    proposer_request_status,
    proposer_request_date,
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "proposer_request_id",
    numeric: false,
    disablePadding: false,
    label: "Request ID",
  },
  {
    id: "proposer_request_to_company_name",
    numeric: false,
    disablePadding: false,
    label: "Company name",
  },
  {
    id: "proposer_request_to_representative_name",
    numeric: false,
    disablePadding: false,
    label: "Representative name",
  },
  {
    id: "proposer_request_status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "proposer_request_date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar() {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
          p: 2,
          fontWeight: 700,
          lineHeight: 1.34,
          fontSize: 17,
        }}
        id="tableTitle"
        component="div"
      >
        Your proposer request list
      </Typography>
    </Toolbar>
  );
}

export default function ProposerReceiver() {
  const { user } = useAuthContext();
  const { companyProposer, isLoading } = useProposerReceiver(
    user?.user_member_id?.toString() as string
  );

  const [open, setOpen] = useState<boolean>(false);
  const [Id, setId] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const rows = companyProposer.map((row) =>
    createData(
      row.proposer_request_id,
      row.proposer_request_to_company_name,
      row.proposer_request_to_representative_name,
      row.proposer_request_status,
      row.proposer_request_date
    )
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleFun = (event: number) => {
    setId(event);
    setOpen(true);
  };

  return (
    <>
      <Box sx={{ width: "100%", mt: 5 }}>
        <Paper sx={{ width: "100%", mb: 2, mt: 5 }}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <EnhancedTableHead />
              <TableBody>
                {isLoading ? (
                  <TableLoader />
                ) : (
                  <>
                    {rows.length ? (
                      <>
                        {rows.map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={index}
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell id={labelId} align="left">
                                {row.proposer_request_id}
                              </TableCell>
                              <TableCell align="left">
                                {row.proposer_request_to_company_name}
                              </TableCell>
                              <TableCell align="left">
                                {row.proposer_request_to_representative_name}
                              </TableCell>
                              <TableCell align="left">
                                {getStatusLabel(row.proposer_request_status!)}
                              </TableCell>
                              <TableCell align="left">
                                {moment(`${row.proposer_request_date}`).format(
                                  "DD-MMM-YYYY"
                                )}
                              </TableCell>
                              {row.proposer_request_status === "pending" ? (
                                <TableCell align="left">
                                  <Tooltip title={"Update status"}>
                                    <IconButton
                                      onClick={() =>
                                        handleFun(row.proposer_request_id!)
                                      }
                                    >
                                      <EditOutlinedIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              ) : (
                                <TableCell align="left">
                                  <Tooltip title={"Can't update status"}>
                                    <IconButton>
                                      <EditOutlinedIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      </>
                    ) : (
                      <TableNoDataMessage />
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <ModalsWrapper
        modalData={{ title: "Update request status" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <UpdateProposerStatus setOpen={() => setOpen(false)} id={Id} />
      </ModalsWrapper>
    </>
  );
}
