import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { UPDATE_AUTH_USER_INFO } from '../../Helpers/utils/Constant';
import CompanyInformationIndex from './IndexFile/CompanyInformationIndex';
import TradeLicenseIndex from './IndexFile/TradeLicenseIndex';
import CivilAviationIndex from './IndexFile/CivilAviationIndex';
import CompanyDocumentIndex from './IndexFile/CompanyDocumentIndex';
import ProposerIndex from './IndexFile/ProposerIndex';
import BusinessLicenseInfoIndex from './IndexFile/BusinessLicenseInfoIndex';
import CompanyOwnerIndex from './IndexFile/CompanyOwnerIndex';
import { showToast } from '../../Toaster/Toaster';
import { useMutation, useQueryClient } from 'react-query';
import { PostApplicationSubmit } from './api/newMemberApplicationEndPoint';
import { API_ENDPOINTS } from '../../rest/utils/api-endpoints';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useMediaQuery, Theme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Tin from './IndexFile/Tin';
import OwnerPassport from './IndexFile/OwnerPassport';

function NewMemberApplications() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tabValue');
  const [activeStep, setActiveStep] = useState(Number(activeTab));
  const { user, dispatch } = useAuthContext();
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const [skipped, setSkipped] = useState(new Set<number>());
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: number) => {
      return PostApplicationSubmit(id);
    },
    onSuccess: () => {
      showToast(`Member application submit successfully`, 'success');
      dispatch?.({
        type: UPDATE_AUTH_USER_INFO,
        payload: {
          is_applied: 1,
        },
      });
      setActiveStep((prevActiveStep) => prevActiveStep * 0);
      setSearchParams({ tabValue: String(0) });
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.APPLICATION_APPLIED],
      });
    },
  });

  const handelSubmit = () => {
    mutation.mutate(adminId?.user_member_id);
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    setSearchParams({ tabValue: String(activeStep + 1) });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSearchParams({ tabValue: String(activeStep - 1) });
  };

  const steps = [
    {
      label: "Owner's  Information",
      description: <CompanyOwnerIndex />,
    },
    {
      label: 'Company Information',
      description: <CompanyInformationIndex />,
    },
    {
      label: 'Trade License ',
      description: <TradeLicenseIndex />,
    },
    {
      label: 'TIN/BIN',
      description: <Tin />,
    },
    {
      label: 'Civil Aviation Certificate',
      description: <CivilAviationIndex />,
    },
    {
      label: "Owner's Passport",
      description: <OwnerPassport />,
    },

    {
      label: 'Company document',
      description: <CompanyDocumentIndex />,
    },
    {
      label: 'Business license ',
      description: <BusinessLicenseInfoIndex />,
    },
    {
      label: 'Agency Proposer',
      description: <ProposerIndex />,
    },
  ];

  if (!user.is_applied) {
    steps.push({
      label: 'Final Step',
      description: (
        <Box mt={10}>
          {!user.is_applied && (
            <Grid item xs={12}>
              <Box sx={{ width: '100%', textAlign: 'center', pb: 5 }}>
                {mutation.isLoading ? (
                  <Button
                    className='add-remove-button'
                    variant='contained'
                    sx={{ width: '25%', mt: 1 }}
                    size='small'
                  >
                    loading...
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    sx={{ width: '25%', mt: 1 }}
                    onClick={handelSubmit}
                    size='small'
                  >
                    Submit your application
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </Box>
      ),
    });
  }
  const handleStep = (step: number) => () => {
    setActiveStep(step);
    setSearchParams({ tabValue: String(step) });
  };

  const isXsScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const orientation = isXsScreen ? 'vertical' : 'horizontal';

  return (
    <>
      <Box sx={{ width: '100%', mt: 6 }}>
        <Stepper nonLinear activeStep={activeStep} orientation={orientation}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepButton color='inherit' onClick={handleStep(index)}>
                {label.label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          <React.Fragment>
            <Box> {steps[activeStep]?.description}</Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                pb: { xs: 15 },
                pt: { xs: 5, md: 5 },
              }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant='contained'
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }} variant='contained'>
                Next
              </Button>
            </Box>
          </React.Fragment>
        </div>
      </Box>
    </>
  );
}

export default NewMemberApplications;
