import { useEffect, useState } from 'react';
import { HttpClient } from '../../rest/utils/http';
import { showToast } from '../../Toaster/Toaster';

interface IResponse<T> {
  success: boolean;
  data: T;
  message?: string;
}

const DeleteDataApi = <T,>(url: string) => {
  const [response, setResponse] = useState<T | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const deleteData = async (id: string | number) => {
    setError(null);

    try {
      setLoading(true);
      const res: IResponse<T> = await HttpClient.delete(`${url}/${id}`);
      if (res.success) {
        setSuccess(true);
        setResponse(res.data);
        showToast('Successfully created', 'success');
      } else {
        setSuccess(false);
        setError('something went wrong');
        showToast('something went wrong', 'error');
      }
    } catch (err: any) {
      setSuccess(false);
      setError(err?.message);
      showToast(err?.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    response,
    loading,
    error,
    deleteData,
    success,
    setRefetch,
    setSuccess,
  };
};

export default DeleteDataApi;
