import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Draft: React.FC = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="80vh"
        textAlign="center"
        p={3}
      >
        <Typography variant="h1" color="green">
          Registration Successfully !!!
        </Typography>
        <Typography variant="h2" color="black" mt={2}>
          Your status is <span style={{ color: "red" }}>Pending</span>. Once
          confirmed, you will be able to log in.
        </Typography>
        <Typography variant="h3" color="textSecondary" mt={2}>
          Please contact the Haab for further assistance.
        </Typography>
        <Link to="/login">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
              background: "#31805F",
            }}
          >
            Sign In
          </Button>
        </Link>
      </Box>
    </>
  );
};

export default Draft;
