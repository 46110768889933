import TableNoDataMessage from "../../TableMessage/TableNoDataMessage";
import { useDashboardNoticeQuery } from "../api/dashboardEndPoint";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import PdfAndImageView from "../../../Common/PdfAndImageView";
import moment from "moment";

const MemberNotice = () => {
  const adminId = JSON.parse(localStorage.getItem("auth") as any);
  const { allNotice } = useDashboardNoticeQuery(adminId?.user_member_id);

  return (
    <>
      {allNotice?.length ? (
        <>
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableRow>
                  <TableCell
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>
                      {" "}
                      List of recent notice
                    </Box>
                    <Box sx={{ fontWeight: "bold" }}>Download</Box>
                  </TableCell>
                </TableRow>
                <TableBody sx={{ width: "100%" }}>
                  {allNotice?.map((notice, index) => (
                    <>
                      <TableRow hover role="checkbox">
                        <TableCell
                          size="small"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: index % 2 === 0 ? "#FBFBFC" : "",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 2,
                            }}
                          >
                            <PlayArrowIcon color="primary" />{" "}
                            {notice.member_notice_title}
                            {", "}
                            {moment(notice.member_notice_created_at).format(
                              "DD-MMMM-YYYY"
                            )}
                          </Box>{" "}
                          <PdfAndImageView file={notice.member_notice_file} />
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <TableNoDataMessage />
        </Box>
      )}
    </>
  );
};

export default MemberNotice;
