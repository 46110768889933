import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField, Grid, Button } from '@mui/material';
import { useBankPostMutation } from '../../api/paymentEndPoint';
import { IAddBankTypeFormData } from '../../type/payment_type';

export default function BankDetailsForm({
  setBankOpen,
}: {
  setBankOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddBankTypeFormData>();
  const { mutate, isLoading, isSuccess } = useBankPostMutation();
  const onSubmit = (data: IAddBankTypeFormData) => {
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      setBankOpen(false);
    }
  }, [isSuccess]);
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
        padding: '20px',
        bgcolor: 'background.paper',
        boxShadow: 3,
      }}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Account Holder Name'
            variant='outlined'
            size='small'
            {...register('account_holder_name', {
              required: 'Account holder name is required',
            })}
            placeholder='Enter Account Holder Name'
            InputLabelProps={{ shrink: true }}
            error={!!errors.account_holder_name}
            helperText={errors.account_holder_name?.message as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Account Number'
            variant='outlined'
            size='small'
            {...register('account_number', {
              required: 'Account number is required',
            })}
            type='number'
            placeholder='Enter Account Number'
            InputLabelProps={{ shrink: true }}
            error={!!errors.account_number}
            helperText={errors.account_number?.message as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Bank Name'
            variant='outlined'
            placeholder='Enter Bank Name'
            InputLabelProps={{ shrink: true }}
            size='small'
            {...register('bank_name', { required: 'Bank name is required' })}
            error={!!errors.bank_name}
            helperText={errors.bank_name?.message as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='District Name'
            variant='outlined'
            size='small'
            placeholder='Enter District Name'
            InputLabelProps={{ shrink: true }}
            {...register('district_name', {
              required: 'District name is required',
            })}
            error={!!errors.district_name}
            helperText={errors.district_name?.message as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Branch Name'
            variant='outlined'
            size='small'
            placeholder='Enter Branch Name'
            InputLabelProps={{ shrink: true }}
            {...register('branch_name', {
              required: 'Branch name is required',
            })}
            error={!!errors.branch_name}
            helperText={errors.branch_name?.message as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Routing Number'
            variant='outlined'
            size='small'
            placeholder='Enter Routing Number'
            InputLabelProps={{ shrink: true }}
            {...register('routing_number', {
              required: 'Routing number is required',
            })}
            type='number'
            error={!!errors.routing_number}
            helperText={errors.routing_number?.message as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Mobile Number'
            variant='outlined'
            size='small'
            placeholder='Enter Mobile Number'
            InputLabelProps={{ shrink: true }}
            {...register('mobile_number', {
              required: 'Mobile number is required',
            })}
            type='number'
            error={!!errors.mobile_number}
            helperText={errors.mobile_number?.message as string}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={'center'}>
            <Grid item xs={8}>
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                fullWidth
              >
                {isLoading ? 'loading..' : 'Add Bank Account'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
