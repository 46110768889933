import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import moment from 'moment';
import { useParams } from 'react-router';
import PageLoader from '../../Components/Spinner/PageLoader';
import { imgUrl } from '../../Helpers/utils/Constant';
import { getStatusLabel } from '../../Utils/Label/status-label';
import { useSingleAgencyInfo } from '../../rest/useAgencyInfoUpdateApplication';
import Description, { DescriptionChild } from '../../Common/Description';
import PdfAndImageView from '../../Common/PdfAndImageView';

const SingleAgencyUpdateInfo = () => {
  const { id } = useParams();

  const { singleAgencyInfo: application, isLoading } = useSingleAgencyInfo(
    Number(id)
  );

  return !isLoading ? (
    <Container sx={{ pt: 5 }}>
      <Card>
        <CardHeader title='Application information'></CardHeader>
        <CardContent>
          {application ? (
            <Description>
              <DescriptionChild label='Application Date'>
                {moment(application.agency_info_created_at).format('MMM Do YY')}
              </DescriptionChild>

              <DescriptionChild label='Status'>
                {getStatusLabel(application.status)}
              </DescriptionChild>
              <DescriptionChild label='Phone'>
                {application.phone}
              </DescriptionChild>
              <DescriptionChild label='Cellphone'>
                {application.cellphone}
              </DescriptionChild>
              <DescriptionChild label='Website'>
                <a
                  href={application.member_company_website}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {application.member_company_website}
                </a>
              </DescriptionChild>
              <DescriptionChild label='Company Logo'>
                <PdfAndImageView file={application.member_company_logo} />
              </DescriptionChild>
              <DescriptionChild label='Representative Photo'>
                <PdfAndImageView
                  file={application.user_member_representative_photo}
                />
              </DescriptionChild>
              {application.note && (
                <DescriptionChild label='Note'>
                  {application.note}
                </DescriptionChild>
              )}
            </Description>
          ) : (
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              No Data found
            </Typography>
          )}
        </CardContent>
      </Card>

      {/* <Box display={"none"}>
        <AgencyInfoDownloadForm componentRef={componentRef} />
      </Box> */}
    </Container>
  ) : (
    <PageLoader />
  );
};

export default SingleAgencyUpdateInfo;
