import { useState } from 'react';
import { Grid, Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { useCompanyProposerUpdate } from '../../../rest/useCompanyInfo';
import SearchCompanyByLicenseNo from '../../../Common/SearchCompanyByLicenseNo';
import { showToast } from '../../../Toaster/Toaster';

const AddCompanyProposerInfo = () => {
  const { user } = useAuthContext();
  const { handleSubmit } = useForm();
  const { isLoading, mutate } = useCompanyProposerUpdate();
  const [proposerInput1, setProposerInput1] = useState<{
    user_member_id: number;
    user_member_hajj_license: number;
    company_owner_email: string;
    company_owner_phone: string;
    company_owner_designation: string;
    company_owner_name: string;
  }>();
  const [proposerInput2, setProposerInput2] = useState<{
    user_member_id: number;
    user_member_hajj_license: number;
    company_owner_email: string;
    company_owner_phone: string;
    company_owner_designation: string;
    company_owner_name: string;
  }>();

  const isDuplicateProposer = (selectedProposer: any, currentProposer: any) => {
    return selectedProposer?.user_member_id === currentProposer?.user_member_id;
  };
  const handleSetProposerInput1 = (value: any) => {
    if (
      isDuplicateProposer(value, proposerInput2) &&
      proposerInput2?.user_member_id
    ) {
      showToast('The same proposer cannot be selected twice', 'error');
    } else {
      setProposerInput1(value);
    }
  };

  const handleSetProposerInput2 = (value: any) => {
    if (
      isDuplicateProposer(value, proposerInput1) &&
      proposerInput1?.user_member_id
    ) {
      showToast('The same proposer cannot be selected twice.', 'error');
    } else {
      setProposerInput2(value);
    }
  };
  const onSubmit = async (inputData: any) => {
    const body = {
      member_1st_proposer_id: proposerInput1?.user_member_id,
      member_2nd_proposer_id: proposerInput2?.user_member_id,
    };
    if (body.member_1st_proposer_id && body.member_2nd_proposer_id) {
      mutate({
        input: {
          member_1st_proposer_id: proposerInput1?.user_member_id as number,
          member_2nd_proposer_id: proposerInput2?.user_member_id as number,
        },
      });
    } else {
      showToast('Please Fillup Two filled', 'error');
    }
  };
  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        p={2}
        maxWidth={800}
        margin={'0 auto'}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container rowGap={1}>
                <Grid xs={12}>
                  <label htmlFor=''>First proposer</label>
                  <Box>
                    <SearchCompanyByLicenseNo
                      setWholeValue={handleSetProposerInput1}
                    />
                  </Box>
                </Grid>
                {proposerInput1?.user_member_id && (
                  <>
                    <Grid xs={12}>
                      <TextField
                        variant='standard'
                        disabled
                        defaultValue={proposerInput1?.user_member_hajj_license}
                        size='small'
                        label='Hajj License Number'
                        id='outlined-basic'
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        disabled
                        defaultValue={proposerInput1?.company_owner_name}
                        size='small'
                        label="License Owner's Name"
                        id='outlined-basic'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        disabled
                        defaultValue={proposerInput1?.company_owner_designation}
                        size='small'
                        label="Owner's Designation"
                        id='outlined-basic'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        disabled
                        defaultValue={proposerInput1?.company_owner_phone}
                        size='small'
                        label="Owner's Mobile Number"
                        id='outlined-basic'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        disabled
                        defaultValue={proposerInput1?.company_owner_email}
                        size='small'
                        label="Owner's Email Address"
                        id='outlined-basic'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container rowGap={1}>
                <Grid xs={12}>
                  <label htmlFor=''>Second Proposer</label>
                  <Box>
                    <SearchCompanyByLicenseNo
                      setWholeValue={handleSetProposerInput2}
                    />
                  </Box>
                </Grid>
                {proposerInput2?.user_member_id && (
                  <>
                    <Grid xs={12}>
                      <TextField
                        variant='standard'
                        disabled
                        defaultValue={proposerInput2?.user_member_hajj_license}
                        size='small'
                        label='Hajj License Number'
                        id='outlined-basic'
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        disabled
                        defaultValue={proposerInput2?.company_owner_name}
                        size='small'
                        label="License Owner's Name"
                        id='outlined-basic'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        disabled
                        defaultValue={proposerInput2?.company_owner_designation}
                        size='small'
                        label="Owner's Designation"
                        id='outlined-basic'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        disabled
                        defaultValue={proposerInput2?.company_owner_phone}
                        size='small'
                        label="Owner's Mobile Number"
                        id='outlined-basic'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        disabled
                        defaultValue={proposerInput2?.company_owner_email}
                        size='small'
                        label="Owner's Email Address"
                        id='outlined-basic'
                        variant='standard'
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {!user.is_applied ? (
          <Box sx={{ mt: 2, textAlign: 'end', mb: 1 }}>
            {isLoading ? (
              <Button
                disabled
                variant='contained'
                disableElevation
                className='add-remove-button'
                size='small'
              >
                Loading...
              </Button>
            ) : (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className='add-remove-button'
                size='small'
              >
                Save
              </Button>
            )}
          </Box>
        ) : (
          ''
        )}
      </Box>
    </>
  );
};

export default AddCompanyProposerInfo;
