import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import TableLoader from '../../../Components/Spinner/TableLoader';
import TableNoDataMessage from '../../../Components/TableMessage/TableNoDataMessage';
import GetDataApi from '../../../Common/FetchData/GetDataApi';
import { IInvoiceList } from '../type/payment_type';
import moment from 'moment';
import ViewAllVoucher from './ViewAllVoucher';
import getStatusChip from '../../../Common/getStatusChip';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DirectPayment from './AddPaymentmanagement/DirectPayment/DirectPayment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ManulPayment from './AddPaymentmanagement/ManualPayment/ManulPayment';
const PaymentManagement = () => {
  const adminId = JSON.parse(localStorage.getItem('auth') as any);

  const [filter, setFilter] = useState<{
    page: string | number;
    listType: string | number;
    limit: string | number;
  }>({
    listType: 'all',
    page: 1,
    limit: 30,
  });
  const { response, loading, total, getData } = GetDataApi<IInvoiceList[]>(
    `/api/atab/invoice/get/all/by/user-member/status/${adminId?.user_member_id}/${filter.listType}/?limit=${filter.limit}&page=${filter.page}`,
    true
  );
  const [singleInvoice, setSingleInvoice] = useState<IInvoiceList>();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tabValue');
  const [openManualPayment, setManualPayment] = useState(activeTab || 'list');
  useEffect(() => {
    getData();
  }, [filter]);
  return (
    <Card sx={{ mt: 5 }}>
      <CardHeader
        title={
          <Typography sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}>
            Payment Management
          </Typography>
        }
        action={
          openManualPayment === 'manual' ||
          openManualPayment === 'd_payment' ? (
            <Button
              variant='outlined'
              size='small'
              onClick={() => {
                setManualPayment('list');
                setSearchParams({ tabValue: 'list' });
              }}
            >
              <ArrowBackIcon /> Back To Voucher List
            </Button>
          ) : (
            <Grid container sx={{ width: 385 }} columnGap={2}>
              <Grid item xs={6}>
                <Select
                  size='small'
                  fullWidth
                  variant='outlined'
                  placeholder='Payment type'
                  defaultValue={'all'}
                  sx={{ padding: 0, height: 35 }}
                  onChange={(e) =>
                    setFilter({ ...filter, listType: e.target.value })
                  }
                >
                  <MenuItem value='all'>All</MenuItem>
                  <MenuItem value='unpaid'>Unpaid</MenuItem>
                  <MenuItem value='pending'>Pending</MenuItem>
                </Select>
              </Grid>
              {adminId?.user_member_account_status !== 'pending' &&
              adminId?.user_member_account_status !== 'inactive' &&
              adminId?.user_member_account_status !== 'rejected' ? (
                <Grid item>
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={() => {
                      setManualPayment('manual');
                      setSearchParams({ tabValue: 'manual' });
                    }}
                  >
                    Add Manual Payment
                  </Button>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          )
        }
      />

      {openManualPayment === 'manual' ? (
        <ManulPayment
          setRefetch={getData}
          setManualPayment={setManualPayment}
        />
      ) : openManualPayment === 'd_payment' ? (
        <DirectPayment
          singleInvoice={singleInvoice}
          setSingleInvoice={setSingleInvoice}
          setRefetch={getData}
          setManualPayment={setManualPayment}
        />
      ) : (
        <Box>
          <TableContainer>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell size='small' align='center'>
                    SL no
                  </TableCell>
                  <TableCell align='center' size='small'>
                    Voucher No
                  </TableCell>
                  <TableCell align='center' size='small'>
                    Date
                  </TableCell>
                  <TableCell align='center' size='small'>
                    Voucher Amount
                  </TableCell>
                  <TableCell align='center' size='small'>
                    Due Amount
                  </TableCell>
                  <TableCell align='center' size='small'>
                    Status
                  </TableCell>
                  <TableCell align='center' size='small'>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableLoader />
                ) : (
                  <>
                    {response?.length ? (
                      <>
                        {response?.map((sPayment, index) => (
                          <TableRow
                            key={sPayment.atab_payment_invoice_id}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align='center' size='small'>
                              {index + 1}
                            </TableCell>
                            <TableCell align='center' size='small'>
                              {sPayment.voucher_no}
                            </TableCell>

                            <TableCell align='center' size='small'>
                              {moment(
                                sPayment.atab_payment_invoice_issue_date
                              ).format('DD-MMM-YYYY')}
                            </TableCell>
                            <TableCell align='center' size='small'>
                              {Number(
                                sPayment.atab_payment_invoice_grand_total
                              )}
                            </TableCell>
                            <TableCell align='center' size='small'>
                              {Number(sPayment.due)}
                            </TableCell>
                            <TableCell align='center' size='small'>
                              {getStatusChip(
                                sPayment.atab_payment_invoice_status
                              )}
                            </TableCell>

                            <TableCell
                              sx={{
                                cursor: 'pointer',
                                paddingTop: 1,
                                paddingBottom: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  width: 140,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  mx: 'auto',
                                }}
                              >
                                <ViewAllVoucher
                                  voucherId={sPayment.atab_payment_invoice_id}
                                />
                                {(sPayment.atab_payment_invoice_status ===
                                  'unpaid' ||
                                  sPayment.atab_payment_invoice_status ===
                                    'partially paid') && (
                                  <Button
                                    variant='text'
                                    onClick={() => {
                                      setManualPayment('d_payment');
                                      setSearchParams({
                                        tabValue: 'd_payment',
                                      });
                                      setSingleInvoice(sPayment);
                                      // Store in localStorage temporarily
                                      localStorage.setItem(
                                        'singleInvoice',
                                        JSON.stringify(sPayment)
                                      );
                                    }}
                                  >
                                    Payment
                                  </Button>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableNoDataMessage />
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component='div'
              count={total as number}
              onPageChange={(_event: any, newPage: number) =>
                setFilter({ ...filter, page: newPage })
              }
              onRowsPerPageChange={(event) =>
                // setLimit(parseInt(event.target.value))
                setFilter({ ...filter, limit: event.target.value })
              }
              page={Number(filter.page) && Number(filter.page)}
              rowsPerPage={Number(filter.limit)}
              rowsPerPageOptions={[30, 50, 75, 100]}
            />
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default PaymentManagement;
