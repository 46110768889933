import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  Button,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableNoDataMessage from '../../../../Components/TableMessage/TableNoDataMessage';
import TableLoader from '../../../../Components/Spinner/TableLoader';
import { useEffect, useState } from 'react';
import ModalsWrapper from '../../../../Components/Modals/ModalsWrapper';
import BankDetailsEdit from './BankDetailsEdit';
import DeleteDataApi from '../../../../Common/FetchData/DeleteDataApi';
import ConfirmDelete from '../../../../Common/ConfirmDelete';
import { useAuthContext } from '../../../../Context/AuthContext/AuthContext';
import { useBankDeleteMutation, useBankList } from '../../api/paymentEndPoint';
import BankDetailsForm from './BankDetailsForm';
const BankList = () => {
  const { user } = useAuthContext();
  const [bankOpen, setBankOpen] = useState(false);
  const { data } = useBankList();
  const { mutate, isSuccess } = useBankDeleteMutation();
  // const { deleteData, success, setSuccess } = DeleteDataApi<BankAccount[]>(
  //   'api/atab/beneficiary/bank-account'
  // );

  useEffect(() => {
    if (isSuccess) {
    }
  }, [isSuccess]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}>
            Bank Information
          </Typography>
        }
        action={
          user.is_applied &&
          user?.user_member_account_status !== 'inactive' &&
          user?.user_member_account_status !== 'rejected' ? (
            <Button
              variant='outlined'
              size='small'
              onClick={() => setBankOpen(true)}
            >
              Add Bank Account
            </Button>
          ) : (
            <></>
          )
        }
      />
      <Divider />

      <Box>
        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell size='small' align='center'>
                  Serial no
                </TableCell>
                <TableCell align='center' size='small'>
                  Account Holder Name
                </TableCell>
                <TableCell align='center' size='small'>
                  Account Number
                </TableCell>
                <TableCell align='center' size='small'>
                  Bank Name
                </TableCell>
                <TableCell align='center' size='small'>
                  Branch Name
                </TableCell>
                <TableCell align='center' size='small'>
                  District Name
                </TableCell>
                <TableCell align='center' size='small'>
                  Routing Number
                </TableCell>
                <TableCell align='center' size='small'>
                  Status
                </TableCell>
                <TableCell align='center' size='small'>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {false ? (
                <TableLoader />
              ) : (
                <>
                  {data?.data?.length ? (
                    <>
                      {data?.data?.map((sPayment, index) => (
                        <TableRow
                          key={sPayment.id}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align='center' size='small'>
                            {index + 1}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.account_holder_name}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.account_number}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.bank_name}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.branch_name}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.district_name}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.routing_number}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.is_active ? (
                              <Button
                                size='small'
                                variant='text'
                                color='success'
                              >
                                Active
                              </Button>
                            ) : (
                              <Button size='small' variant='text' color='error'>
                                InActive
                              </Button>
                            )}
                          </TableCell>

                          <TableCell
                            align='center'
                            sx={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            <BankDetailsEdit data={sPayment} />
                            <ConfirmDelete
                              confirmDelete={async () =>
                                await mutate({ id: sPayment.id })
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableNoDataMessage />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ModalsWrapper
        modalData={{ title: 'Add your Bank information' }}
        showModal={bankOpen}
        setShowModal={setBankOpen}
      >
        <BankDetailsForm setBankOpen={setBankOpen} />
      </ModalsWrapper>
    </Card>
  );
};

export default BankList;
export interface BankAccount {
  id: number;
  account_holder_name: string;
  account_number: string;
  bank_name: string;
  district_name: string;
  branch_name: string;
  routing_number: string;
  mobile_number: string;
  user_member_id: number;
  is_active: number;
  created_at: string;
}
