import { useState } from 'react';
import { Box, Grid, ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import BankList from './Component/Bank/BankList';
import WalletList from './Component/Wallet/WalletList';
import { useSearchParams } from 'react-router-dom';

const Payments = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tabValue');
  const [selectedOption, setSelectedOption] = useState<any>(
    activeTab || 'bank'
  );

  return (
    <Box mt={5}>
      <Grid>
        <ButtonGroup sx={{ maxWidth: 500, width: '100%', mb: 2 }}>
          <Button
            type='button'
            variant={selectedOption === 'bank' ? 'contained' : 'outlined'}
            color={selectedOption === 'bank' ? 'primary' : 'success'}
            fullWidth
            onClick={() => {
              setSelectedOption('bank');
              setSearchParams({ tabValue: 'bank' });
            }}
          >
            Bank Account
          </Button>{' '}
          <Button
            type='button'
            variant={selectedOption === 'wallet' ? 'contained' : 'outlined'}
            color={selectedOption === 'wallet' ? 'primary' : 'success'}
            fullWidth
            onClick={() => {
              setSelectedOption('wallet');
              setSearchParams({ tabValue: 'wallet' });
            }}
          >
            Wallet
          </Button>
        </ButtonGroup>
        {selectedOption === 'bank' ? <BankList /> : <WalletList />}
      </Grid>
    </Box>
  );
};

export default Payments;
