import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { IAllTicket } from '../type/type';
import EditTicket from './EditTicket';
import getStatusChip from '../../../Common/getStatusChip';
import DeleteDataApi from '../../../Common/FetchData/DeleteDataApi';
import { useEffect } from 'react';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
const TableRowTicket = ({
  data,
  index,
  refetch,
}: {
  data: IAllTicket;
  index: number;
  refetch: () => Promise<void>;
}) => {
  const { user } = useAuthContext();
  const { loading, success, deleteData } = DeleteDataApi<any>(
    `/api/atab/user-member/ticket`
  );
  const handleDelete = (id: number) => {
    deleteData(id);
  };
  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);
  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant='body1' color='text.primary' gutterBottom noWrap>
            {index + 1}
          </Typography>
        </TableCell>

        <TableCell>{data.type}</TableCell>

        <TableCell>{data.details}</TableCell>
        <TableCell>{getStatusChip(data.status)}</TableCell>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          {user?.user_member_account_status !== 'pending' &&
            user?.user_member_account_status !== 'inactive' &&
            user?.user_member_account_status !== 'rejected' && (
              <EditTicket data={data} refetch={refetch} />
            )}
          {user?.user_member_account_status !== 'pending' &&
            user?.user_member_account_status !== 'inactive' &&
            user?.user_member_account_status !== 'rejected' && (
              <Box
                sx={{ color: 'red', cursor: 'pointer' }}
                onClick={() => handleDelete(data.id)}
              >
                Delete
              </Box>
            )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableRowTicket;
