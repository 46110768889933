import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, DialogActions, Divider } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

type IProps = {
  children: React.ReactNode;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  modalData: any;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
};
const ModalsWrapper = ({
  children,
  showModal,
  setShowModal,
  modalData,
  maxWidth,
  fullWidth,
}: IProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={maxWidth || 'md'}
        fullWidth={fullWidth || false}
        open={showModal}
        onClose={() => {
          return setShowModal(!showModal);
        }}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle>
          {modalData.title}

          <Divider />
        </DialogTitle>
        {children}
        <DialogActions>
          <Button
            autoFocus
            color='error'
            size='small'
            variant='outlined'
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalsWrapper;
