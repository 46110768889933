import { Grid, TextField } from '@mui/material';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

const Payorder = ({
  register,
  errors,
}: {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
}) => {
  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Pay Order Issue Bank'
          variant='outlined'
          size='small'
          placeholder='Pay Order Issue Bank'
          InputLabelProps={{ shrink: true }}
          {...register('payorder_issue_bank', {
            required: 'pay order Issue Bank name is required',
          })}
          error={!!errors.payorder_issue_bank}
          helperText={errors.payorder_issue_bank?.message as string}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Pay Order Number'
          variant='outlined'
          size='small'
          placeholder='Pay Order Number'
          InputLabelProps={{ shrink: true }}
          {...register('payorder_number', {
            required: 'Pay Order Number is required',
          })}
          type='number'
          error={!!errors.payorder_number}
          helperText={errors.payorder_number?.message as string}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Pay Order Issue Date'
          variant='outlined'
          size='small'
          placeholder='Pay Order issue date'
          InputLabelProps={{ shrink: true }}
          {...register('payorder_issue_date', {
            required: 'Payorder issue date is required',
          })}
          type='date'
          error={!!errors.payorder_issue_date}
          helperText={errors.payorder_issue_date?.message as string}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Upload Pay Order (pdf)'
          variant='outlined'
          size='small'
          placeholder='Upload Pay Order'
          InputLabelProps={{ shrink: true }}
          {...register('payorder_photo', {
            required: 'pay order file is required',
          })}
          inputProps={{
            accept: 'application/pdf', // Only allow PDF files
          }}
          type='file'
          error={!!errors.payorder_photo}
          helperText={errors.payorder_photo?.message as string}
        />
      </Grid>
    </>
  );
};

export default Payorder;
