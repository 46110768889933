import { Box, CardContent } from "@mui/material";
import { imgUrl } from "../../../Helpers/utils/Constant";
import PageLoader from "../../../Components/Spinner/PageLoader";
import moment from "moment";
import { ICompanyLicense } from "../../../rest/types";
import Description, { DescriptionChild } from "../../../Common/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PdfAndImageView from "../../../Common/PdfAndImageView";
type IProps = {
  data: ICompanyLicense;
  loading: boolean;
};

const CardCompanyTradeLicense = ({ data, loading }: IProps) => {
  return (
    <>
      {!loading ? (
        <CardContent>
          <Box maxWidth={1000} sx={{ margin: "0 auto" }}>
            <Description>
              <DescriptionChild label="Company trade license">
                {data.company_trade_license}
              </DescriptionChild>
              <DescriptionChild label="Download company Trade license">
                <PdfAndImageView
                  file={data.company_trade_license_file}
                  color="#00e"
                />
              </DescriptionChild>
              <DescriptionChild label="Company trade license expiry date">
                {moment(data.company_trade_license_expiry_date).format(
                  "Do-MMM-YYYY"
                )}
              </DescriptionChild>
            </Description>
          </Box>
        </CardContent>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default CardCompanyTradeLicense;
