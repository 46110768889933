import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { HttpClient } from '../../../rest/utils/http';
import { ISingleIdCard, ISingleIdCardResponse } from '../../../Types/IdCard';
import { GetResponseType } from '../../../rest/types';
import { showToast } from '../../../Toaster/Toaster';

const fetchSingleIdCard = async (id: number) => {
  const fetchData = await HttpClient.get<ISingleIdCardResponse>(
    `${API_ENDPOINTS.SINGLE_ID_CARD}/${id}`
  );
  return fetchData;
};

type ICardType = {
  c_id: number;
  c_issue_date: string;
  c_photo: string;
  c_renew_year: string;
  c_type: string;
  status: number;
  user_member_company_name: string;
  user_member_id: number;
};
export function useAllIdCardQuery(id: number) {
  return useQuery(
    '',
    async () => {
      const fetchData = await HttpClient.get<GetResponseType<ICardType[]>>(
        `/api/atab/user-member/certificate/${id}?c_type=ID_CARD`
      );
      return fetchData;
    },
    {
      staleTime: Infinity,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError: (error) => {
        showToast('Something went wrong', 'error');
      },
    }
  );
}

export function useSingleIDCardQuery(id: number) {
  const { data, isLoading, error } = useQuery<ISingleIdCardResponse, Error>(
    [API_ENDPOINTS.SINGLE_ID_CARD, { id }],
    () => fetchSingleIdCard(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    singleIdCard: data?.data ?? ({} as ISingleIdCard),
    isLoading,
    error,
  };
}
