import React, { useState } from 'react';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import Typography from '@mui/material/Typography';
import Spinner from '../../Components/Spinner/Spinner';
import { showToast } from '../../Toaster/Toaster';
import { Iuser } from '../../Context/AuthContext/AuthTyps';
import ProposerReceiverModal from './ProposerReceiverModal';
import {
  PostHandelReject,
  useProposerReceiverQuery,
} from '../proposer-receiver/api/proposerEndPoint';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../../rest/utils/api-endpoints';

interface IProposerSubmit {
  user_member_id: Iuser['user_member_id'];
  proposer_request_id: number;
  proposer_request_status: string;
  proposer_request_reject_reason?: string;
}
const ProposerReciver = () => {
  const { user } = useAuthContext();
  const [value, setValue] = React.useState('pending');
  const [rejectReason, setRejectReason] = useState<string>('');
  const [requestId, setRequestId] = useState<number>(0);
  // modal
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { proposerReceiver, isLoading } = useProposerReceiverQuery(
    user.user_member_id!,
    value
  );
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return PostHandelReject(data);
    },
    onSuccess: () => {
      // showToast(`Successfully joined`, "success");
      setOpen(false);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.PROPOSER_RECEIVER],
      });
    },
  });
  const handelReceiver = (requesterId: number, action: string) => {
    if (requesterId && user.user_member_id!) {
      const data: IProposerSubmit = {
        user_member_id: user.user_member_id!,
        proposer_request_id: requesterId,
        proposer_request_status: action,
      };

      if (rejectReason) {
        data.proposer_request_reject_reason = rejectReason;
      }

      mutation.mutate(data);
    }
  };

  return (
    <div>
      {' '}
      <h2>Proposer request you get </h2>
      <>
        <>
          <Grid pt={2}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  width: '100%',
                  typography: 'body1',
                }}
              >
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    <TabList
                      onChange={(e: any, newValue: any) => setValue(newValue)}
                      aria-label='lab API tabs example'
                    >
                      <Tab label='pending' value='pending' />
                      <Tab label='accepted' value='accepted' />
                      <Tab label='rejected' value='rejected' />
                    </TabList>
                  </Box>
                  {isLoading ? (
                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                      <Spinner />
                    </Box>
                  ) : (
                    <>
                      {proposerReceiver.length ? (
                        <Box>
                          <TabPanel value={value}>
                            <TableContainer component={Paper}>
                              <Table aria-label='simple table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align='center'>
                                      Request id
                                    </TableCell>
                                    <TableCell align='center'>
                                      Company name
                                    </TableCell>
                                    <TableCell align='center'>Status</TableCell>
                                    <TableCell align='center'>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {proposerReceiver.map((sRequester) => {
                                    const {
                                      proposer_request_id,
                                      proposer_request_to_company_name,
                                      proposer_request_status,
                                    } = sRequester;
                                    return (
                                      <TableRow
                                        key={proposer_request_id}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell align='center'>
                                          {proposer_request_id}
                                        </TableCell>
                                        <TableCell align='center'>
                                          {proposer_request_to_company_name}
                                        </TableCell>
                                        <TableCell align='center'>
                                          <p
                                            className={
                                              proposer_request_status ===
                                              'accepted'
                                                ? 'approved'
                                                : proposer_request_status ===
                                                  'pending'
                                                ? 'pending'
                                                : 'reject'
                                            }
                                          >
                                            {proposer_request_status}
                                          </p>
                                        </TableCell>
                                        <TableCell align='center'>
                                          {proposer_request_status ===
                                          'pending' ? (
                                            <Box>
                                              <CheckCircleOutlineIcon
                                                sx={{
                                                  mr: 1,
                                                  cursor: 'pointer',
                                                }}
                                                color='success'
                                                onClick={() =>
                                                  handelReceiver(
                                                    proposer_request_id,
                                                    'accepted'
                                                  )
                                                }
                                              />
                                              <CancelIcon
                                                color='error'
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  handleOpen();
                                                  setRequestId(
                                                    proposer_request_id
                                                  );
                                                }}
                                              />
                                            </Box>
                                          ) : (
                                            ''
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </TabPanel>
                        </Box>
                      ) : (
                        <Box pt={6} textAlign={'center'}>
                          <Typography variant='h5' gutterBottom>
                            No requester yet!
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </>
        <ProposerReceiverModal
          open={open}
          handleClose={handleClose}
          setRejectReason={setRejectReason}
          handelReceiver={handelReceiver}
          requestId={requestId}
        />
      </>
    </div>
  );
};

export default ProposerReciver;
