import { Box, styled, Card, Typography } from '@mui/material';
import moment from 'moment';
import { IReplyies } from './ShowAllComplain';
import { imgUrl } from '../../Helpers/utils/Constant';

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(0.5, 1)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      display: inline-flex;
      word-break: break-all;
`
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(0.5, 1)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      display: inline-flex;
      word-break: break-all;
`
);

const ShowComplainMessage = ({ conversation }: { conversation: IReplyies }) => {
  console.log(conversation);
  return (
    <>
      <Box>
        {conversation?.type === 'admin' ? (
          <>
            <Box
              display='flex'
              alignItems='flex-end'
              justifyContent='flex-start'
            >
              <Box ml={2}>
                <Box mt={1}>
                  <img
                    width={200}
                    height={100}
                    src={`${imgUrl}/${conversation.file}`}
                    alt=''
                  />
                </Box>

                {conversation.content && (
                  <CardWrapperSecondary>
                    {conversation.content}
                  </CardWrapperSecondary>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              display='flex'
              alignItems='flex-start'
              justifyContent='flex-end'
              py={0.5}
            >
              <Box
                display='flex'
                alignItems='flex-end'
                flexDirection='column'
                justifyContent='flex-end'
                mr={2}
              >
                <Box mt={1}>
                  <img
                    width={200}
                    height={100}
                    src={`${imgUrl}/${conversation.file}`}
                    alt=''
                  />
                </Box>
                {conversation.content && (
                  <CardWrapperPrimary>
                    {conversation.content}
                  </CardWrapperPrimary>
                )}

                <Typography sx={{ fontSize: 10 }}>
                  {moment(conversation.created_at).fromNow()}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ShowComplainMessage;
