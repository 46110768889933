import { Card, CardContent, Typography } from '@mui/material';
import { Container } from '@mui/system';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSingleAddressQuery } from '../../rest/useAddressChange';
import { getStatusLabel } from '../../Utils/Label/status-label';
import PageLoader from '../../Components/Spinner/PageLoader';
import Description, { DescriptionChild } from '../../Common/Description';
import PdfAndImageView from '../../Common/PdfAndImageView';

const SingleAddressApplication = () => {
  const { id } = useParams();
  const {
    data: application,
    fetchData,
    loading,
  } = useSingleAddressQuery(id !== undefined ? id : '');

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return !loading ? (
    <Container sx={{ pt: 5 }}>
      <Card>
        <CardContent>
          {application ? (
            <Description>
              <DescriptionChild label='Application Date'>
                {moment(
                  application.address_change_application_created_at
                ).format('MMM Do YY')}
              </DescriptionChild>

              <DescriptionChild label='Company Name'>
                {application.user_member_company_name}
              </DescriptionChild>

              <DescriptionChild label='Address'>
                {application.address_change_application_addressline}
              </DescriptionChild>

              <DescriptionChild label='Postal Code'>
                {application.address_change_application_postal_code}
              </DescriptionChild>

              <DescriptionChild label='Division'>
                {application.division_name}
              </DescriptionChild>

              <DescriptionChild label='District'>
                {application.district_name}
              </DescriptionChild>

              <DescriptionChild label='Area'>
                {application.area_name}
              </DescriptionChild>

              <DescriptionChild label='Thana'>
                {application.thana_name}
              </DescriptionChild>

              <DescriptionChild label='Status'>
                {getStatusLabel(application.address_change_application_status)}
              </DescriptionChild>

              <DescriptionChild label='Download Civil Aviation'>
                <PdfAndImageView
                  file={application.address_change_application_civil_aviation}
                />
              </DescriptionChild>

              <DescriptionChild label='Download Trade License'>
                <PdfAndImageView
                  file={application.address_change_application_trade_license}
                />
              </DescriptionChild>

              <DescriptionChild label='Download Hajj License'>
                <PdfAndImageView
                  file={application.address_change_application_hajj_license}
                />
              </DescriptionChild>

              {application.address_change_application_reject_reason && (
                <DescriptionChild label='Rejection Reason'>
                  {application.address_change_application_reject_reason}
                </DescriptionChild>
              )}
            </Description>
          ) : (
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              No Data found
            </Typography>
          )}
        </CardContent>
      </Card>
    </Container>
  ) : (
    <PageLoader />
  );
};

export default SingleAddressApplication;
