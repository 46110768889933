import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { useCompanyDocumentUpdate } from '../../../rest/useCompanyInfo';
import FormHelperText from '@mui/material/FormHelperText';

const AddCompanyDocument = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isLoading, isSuccess, mutate } = useCompanyDocumentUpdate();
  const [officeAgreement, setOfficeAgreement] = useState<string>('');
  const [organizationOfficePhoto, setOrganizationOfficePhoto] = useState<any>(
    []
  );
  const [error, setError] = useState({
    officeAgreement: false,
  });

  useEffect(() => {
    if (isSuccess) {
      setOrganizationOfficePhoto([]);
    }
  }, [isSuccess]);

  const onSubmit = (data: any) => {
    if (!officeAgreement) {
      setError({
        ...error,
        officeAgreement: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append('ownership_status', user.member_company_type);
    formData.append('office_agreement', officeAgreement);

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'object') {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    });
    organizationOfficePhoto.forEach((img: string, index: number) => {
      const name = 'office_photographs' + (index + 1);
      formData.append(name, img);
    });
    mutate({ input: formData });
  };
  const handelOfficephotoRemove = (id: number) => {
    const restPhoto = organizationOfficePhoto.filter(
      (_img: string, index: number) => index !== id
    );
    setOrganizationOfficePhoto(restPhoto);
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <FormControl sx={{ width: '100%', mt: 2 }} size='small'>
              <InputLabel id='demo-simple-select-label'>
                Ownership Type
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='ownership_status'
                label='Ownership Type'
                value={user.member_company_type}
                required
                disabled
                defaultValue={user.member_company_type}
              >
                <MenuItem value={'Proprietor'}>Proprietor</MenuItem>
                <MenuItem value={'Partnership'}>Partnership</MenuItem>
                <MenuItem value={'Limited'}>Limited</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {user?.member_company_type === 'Partnership' && (
            <Box>
              <Box>
                <label htmlFor=''>
                  Upload Deed of partnership(pdf)
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  required
                  variant='outlined'
                  type='file'
                  fullWidth
                  inputProps={{
                    accept: 'application/pdf',
                  }}
                  {...register('company_partnership_deed_file', {
                    required: 'Deed of partnership is required ',
                  })}
                  error={
                    errors.company_partnership_deed_file &&
                    Boolean(errors.company_partnership_deed_file)
                  }
                  helperText={
                    Boolean(errors?.company_partnership_deed_file?.message)
                      ? `${errors?.company_partnership_deed_file?.message}`
                      : ''
                  }
                />
              </Box>
            </Box>
          )}
          {user?.member_company_type === 'Limited' && (
            <>
              <Box mt={2}>
                <label htmlFor=''>
                  Upload Incorporation certificate (pdf)
                  <span style={{ color: 'red' }}>*</span>
                </label>

                <TextField
                  size='small'
                  id='outlined-basic'
                  required
                  variant='outlined'
                  type='file'
                  inputProps={{
                    accept: 'application/pdf',
                  }}
                  fullWidth
                  {...register('company_incorporation_certificate', {
                    required: 'Incorporation certificate is required',
                  })}
                  error={
                    errors.company_incorporation_certificate &&
                    Boolean(errors.company_incorporation_certificate)
                  }
                  helperText={
                    Boolean(errors?.company_incorporation_certificate?.message)
                      ? `${errors?.company_incorporation_certificate?.message}`
                      : ''
                  }
                />
              </Box>
              <Box mt={2}>
                <label htmlFor=''>
                  Upload Memorandum document of Association (pdf)
                  <span style={{ color: 'red' }}>*</span>
                </label>

                <TextField
                  size='small'
                  id='outlined-basic'
                  required
                  variant='outlined'
                  type='file'
                  fullWidth
                  inputProps={{
                    accept: 'application/pdf',
                  }}
                  {...register('company_memorandum_file', {
                    required: 'Memorandum document is required',
                  })}
                  error={
                    errors.company_memorandum_file &&
                    Boolean(errors.company_memorandum_file)
                  }
                  helperText={
                    Boolean(errors?.company_memorandum_file?.message)
                      ? `${errors?.company_memorandum_file?.message}`
                      : ''
                  }
                />
              </Box>
              <Box mt={2}>
                <label htmlFor=''>
                  Upload Form 12(pdf)
                  <span style={{ color: 'red' }}>*</span>
                </label>

                <TextField
                  size='small'
                  id='outlined-basic'
                  required
                  variant='outlined'
                  type='file'
                  fullWidth
                  inputProps={{
                    accept: 'application/pdf',
                  }}
                  {...register('company_form_12', {
                    required: ' Upload Form 12 is required',
                  })}
                  error={
                    errors.company_form_12 && Boolean(errors.company_form_12)
                  }
                  helperText={
                    Boolean(errors?.company_form_12?.message)
                      ? `${errors?.company_form_12?.message}`
                      : ''
                  }
                />
              </Box>
              <Box mt={2}>
                <label htmlFor=''>
                  Upload Form 117 (pdf)
                  <span style={{ color: 'red' }}>*</span>
                </label>

                <TextField
                  size='small'
                  id='outlined-basic'
                  required
                  variant='outlined'
                  type='file'
                  fullWidth
                  inputProps={{
                    accept: 'application/pdf',
                  }}
                  {...register('company_form_117', {
                    required: ' Upload Form 117 is required',
                  })}
                  error={
                    errors.company_form_117 && Boolean(errors.company_form_117)
                  }
                  helperText={
                    Boolean(errors?.company_form_117?.message)
                      ? `${errors?.company_form_117?.message}`
                      : ''
                  }
                />
              </Box>
            </>
          )}

          {/* office agreement */}
          <Box mt={2}>
            <FormControl
              sx={{ width: '100%' }}
              error={error.officeAgreement}
              size='small'
            >
              <InputLabel id='demo-simple-select-label'>
                Select office agreement
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label=' Select office agreement'
                sx={{ borderRadius: '10px' }}
                value={officeAgreement}
                required
                onChange={(e: any) => {
                  setOfficeAgreement(e.target.value);
                  setError({
                    ...error,
                    officeAgreement: false,
                  });
                }}
              >
                <MenuItem value={'Self premise'}>Self premise</MenuItem>
                <MenuItem value={'Rented premise'}>Rented premise</MenuItem>
              </Select>
            </FormControl>
            {error.officeAgreement && (
              <FormHelperText error>
                OfficeAgreement status is required
              </FormHelperText>
            )}
          </Box>

          {officeAgreement && (
            <Box mt={2}>
              <label htmlFor=''>
                {officeAgreement === 'Self premise'
                  ? 'Upload deed of agreement of self premise office space'
                  : 'Upload deed of agreement of rented premise office space'}{' '}
                <span style={{ color: 'red' }}>*</span>
              </label>

              <TextField
                size='small'
                id='outlined-basic'
                required
                variant='outlined'
                type='file'
                fullWidth
                {...register('company_agreement_file', {
                  required:
                    officeAgreement === 'self premise'
                      ? 'Deed of agreement of self premise office space is required'
                      : 'Deed of agreement of rented premise office space is required',
                })}
                error={
                  errors.company_agreement_file &&
                  Boolean(errors.company_agreement_file)
                }
                helperText={
                  Boolean(errors?.company_agreement_file?.message)
                    ? `${errors?.company_agreement_file?.message}`
                    : ''
                }
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor=''>
              {' '}
              Upload staff list on office pad with seal and signature of owner
              (pdf)
              <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              type='file'
              fullWidth
              inputProps={{
                accept: 'application/pdf',
              }}
              {...register('company_staff_list_file', {
                required:
                  'Staff list on office pad with seal and signature of owner is required',
              })}
              error={
                errors.company_staff_list_file &&
                Boolean(errors.company_staff_list_file)
              }
              helperText={
                Boolean(errors?.company_staff_list_file?.message)
                  ? `${errors?.company_staff_list_file?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Box>
              <label htmlFor=''>
                Upload internal &#38; external photographs of office including
                Signboard (pdf)
                <span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                type='file'
                inputProps={{
                  accept: 'application/pdf',
                }}
                required
                fullWidth
                {...register('company_signboard_file', {
                  required:
                    'Upload internal external photographs of office Signboard',
                })}
                error={
                  errors.company_signboard_file &&
                  Boolean(errors.company_signboard_file)
                }
                helperText={
                  Boolean(errors?.company_signboard_file?.message)
                    ? `${errors?.company_signboard_file?.message}`
                    : ''
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {!user.is_applied ? (
        <Box sx={{ mt: 2, textAlign: 'end', mb: 1 }}>
          {isLoading ? (
            <Button
              disabled
              variant='contained'
              disableElevation
              className='add-remove-button'
            >
              Loading...
            </Button>
          ) : (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              className='add-remove-button'
            >
              Save
            </Button>
          )}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AddCompanyDocument;
