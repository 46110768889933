/* eslint-disable react-hooks/rules-of-hooks */
import { Chip, useTheme } from '@mui/material';

const getStatusChip = (status: string) => {
  const theme = useTheme();
  switch (status) {
    case 'unpaid':
      return <Chip sx={{ width: 70 }} label='Unpaid' color='error' />;
    case 'pending':
      return <Chip label='Pending' color='warning' />;
    case 'approved':
      return <Chip label='Paid' color='success' />;
    case 'partially paid':
      return (
        <Chip
          label='Partially Paid'
          sx={{
            background: `linear-gradient(55deg, ${theme.palette.success.main} 30%, ${theme.palette.info.main} 65%)`,
            color: 'white',
          }}
        />
      );
    case 'completed':
      return <Chip label='Completed' color='success' />;
    case 'paid':
      return <Chip sx={{ width: 70 }} label='Paid' color='success' />;
    case 'Approved':
      return <Chip label='Approved' color='success' sx={{ width: 90 }} />;
    case 'Pending':
      return <Chip label='Pending' color='warning' sx={{ width: 90 }} />;
    case 'Draft':
      return <Chip label='Draft' sx={{ background: '#FCDE70', width: 90 }} />;
    case 'overdue':
      return <Chip label='Overdue' color='error' />;
    default:
      return <Chip label='Unknown' color='default' />;
  }
};

export default getStatusChip;
