import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import SubscriptionType from '../../Component/SubscriptionType';
import GetDataApi from '../../../../../Common/FetchData/GetDataApi';
import { useEffect } from 'react';
import { IActiveForAdmin } from '../../../type/payment_type';
import { useSearchParams } from 'react-router-dom';

const ManualWalletPayment = ({
  setBeneficiaryType,
}: {
  setBeneficiaryType: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries(searchParams.entries());

  const { response: activeMobileBankingForAdmin } = GetDataApi<IActiveForAdmin>(
    '/api/atab/account/member?account_type=Mobile Banking'
  );

  // Populate form fields when data is fetched
  useEffect(() => {
    if (activeMobileBankingForAdmin) {
      // Ensure values are updated once the data is fetched
      setValue(
        'wallet_account_type',
        activeMobileBankingForAdmin?.wallet_account_type || ''
      );
      setValue('account_holder_name', activeMobileBankingForAdmin?.name || '');
      setValue('account_number', activeMobileBankingForAdmin?.account_no || '');
      setValue('admin_account_id', activeMobileBankingForAdmin?.id || '');
      setValue('mobile_number', activeMobileBankingForAdmin?.account_no || '');
      setValue('wallet_type', activeMobileBankingForAdmin?.wallet_type || '');
      setValue(
        'mobile_operator_name',
        activeMobileBankingForAdmin?.mobile_operator_name || ''
      );
    }
  }, [activeMobileBankingForAdmin, setValue]);

  const onSubmit = (data: any) => {
    console.log('Form submitted:', data);
  };

  return (
    <Card sx={{ mt: 5 }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: 600,
          margin: '0 auto',
          padding: '20px',
          bgcolor: 'background.paper',
          boxShadow: 3,
        }}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth size='small'>
              <InputLabel>Beneficiary Type</InputLabel>
              <Select
                fullWidth
                variant='outlined'
                label='Beneficiary Type'
                placeholder='beneficiary Type'
                {...register('beneficiary_type', {
                  required: 'Beneficiary Type is required',
                })}
                onChange={(e: any) => {
                  setBeneficiaryType(e.target.value);
                  setSearchParams({
                    ...currentParams,
                    beneficiaryType: 'bank',
                  });
                }}
                error={!!errors.beneficiary_type}
                defaultValue={'wallet'} // Set initial default value
              >
                <MenuItem value='bank'>Bank Account</MenuItem>
                <MenuItem value='wallet'>Mobile Wallet</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size='small'
              fullWidth
              disabled
              label='Select Account Type'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              {...register('wallet_account_type')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Wallet Type'
              variant='outlined'
              size='small'
              disabled
              InputLabelProps={{ shrink: true }}
              {...register('wallet_type', {
                required: 'Wallet Type is required',
              })}
            />
          </Grid>

          {/* Mobile Number */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Mobile Number'
              variant='outlined'
              size='small'
              disabled
              placeholder='Mobile Number'
              InputLabelProps={{ shrink: true }}
              {...register('mobile_number', {
                required: 'Mobile number is required',
              })}
              error={!!errors.mobile_number}
              helperText={errors.mobile_number?.message as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size='small'
              fullWidth
              disabled
              label='Mobile Operator Name'
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              {...register('mobile_operator_name', {
                required: 'Mobile operator name is required',
              })}
              error={!!errors.mobile_operator_name}
            />
          </Grid>

          <SubscriptionType
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />

          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Transaction ID'
              variant='outlined'
              size='small'
              placeholder='Transaction ID'
              InputLabelProps={{ shrink: true }}
              {...register('transaction_id', {
                required: 'Transaction ID is required',
              })}
              error={!!errors.transaction_id}
              helperText={errors.transaction_id?.message as string}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Payment Date'
              variant='outlined'
              size='small'
              placeholder='Payment Date'
              InputLabelProps={{ shrink: true }}
              {...register('payment_date', {
                required: 'Payment date is required',
              })}
              type='date'
              error={!!errors.payment_date}
              helperText={errors.payment_date?.message as string}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent={'center'}>
              <Grid xs={8}>
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default ManualWalletPayment;
