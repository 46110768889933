import { Box, CardContent } from "@mui/material";
import { imgUrl } from "../../../Helpers/utils/Constant";
import PageLoader from "../../../Components/Spinner/PageLoader";
import { ICompanyLicense, ICompanyTinBin } from "../../../rest/types";
import Description, { DescriptionChild } from "../../../Common/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PdfAndImageView from "../../../Common/PdfAndImageView";
type IProps = {
  data: ICompanyTinBin;
  loading: boolean;
};

const CardTin = ({ data, loading }: IProps) => {
  return (
    <>
      {!loading ? (
        <CardContent>
          <Box maxWidth={1000} sx={{ margin: "0 auto" }}>
            <Description>
              <DescriptionChild label="Company Tin number">
                {data.tin_number}
              </DescriptionChild>
              <DescriptionChild label="Download company Tin file">
                <PdfAndImageView file={data.tin_file} color="#00e" />
              </DescriptionChild>
              <DescriptionChild label="Company Bin number">
                {data.tin_number}
              </DescriptionChild>
              <DescriptionChild label="Download company Bin file">
                <PdfAndImageView file={data.bin_file} color="#00e" />
              </DescriptionChild>
            </Description>
          </Box>
        </CardContent>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default CardTin;
