import { CardContent, Grid, Box, Tooltip, IconButton } from '@mui/material';
import PageLoader from '../../../Components/Spinner/PageLoader';
import moment from 'moment';
import { ICompanyProposer } from '../../../rest/types';
import { getStatusLabel } from '../../../Utils/Label/status-label';
import { useState } from 'react';
import UpdateCompanyProposer from '../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCompanyProposer';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import EditIcon from '@mui/icons-material/Edit';
import Description, { DescriptionChild } from '../../../Common/Description';
import PdfAndImageView from '../../../Common/PdfAndImageView';
type IProps = {
  data: ICompanyProposer[];
  loading: boolean;
};

const CardCompanyOProposer = ({ data, loading }: IProps) => {
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const { user } = useAuthContext();
  const [toggle, setToggle] = useState(false);

  return (
    <>
      {!loading ? (
        <>
          {data?.map((item: ICompanyProposer, index: number) => {
            return toggle ? (
              <Box key={index}>
                <UpdateCompanyProposer
                  index={index}
                  memberId={adminId?.user_member_id}
                  companyProposer={item}
                  setToggle={setToggle}
                  placeholder={item.proposer_request_to_company_name}
                  allData={data}
                />
              </Box>
            ) : (
              <Box key={index}>
                <CardContent sx={{ position: 'relative' }}>
                  <Box p={2} maxWidth={1000} margin={'0 auto'}>
                    <Grid container spacing={0}>
                      <Description>
                        <DescriptionChild label='Proposer request name'>
                          {item.from_company_owner_name}
                        </DescriptionChild>
                        <DescriptionChild label='Proposer request company name'>
                          {item.proposer_request_from_company_name}
                        </DescriptionChild>
                        <DescriptionChild label='Proposer request status'>
                          {getStatusLabel(item.proposer_request_status)}
                        </DescriptionChild>
                        <DescriptionChild label='Proposer request date'>
                          {moment(item.proposer_request_date).format(
                            'MMM Do YY'
                          )}
                        </DescriptionChild>
                        <DescriptionChild label='Proposer Approval Owner name'>
                          {item.to_company_owner_name}
                        </DescriptionChild>
                        <DescriptionChild label='Proposer Approval Company name'>
                          {item.proposer_request_to_company_name}
                        </DescriptionChild>

                        <DescriptionChild label='Proposer Approval signature'>
                          <PdfAndImageView
                            file={item.to_company_owner_signature}
                          />
                        </DescriptionChild>
                      </Description>
                    </Grid>
                  </Box>
                  <Box sx={{ position: 'absolute', top: 0, right: 20 }}>
                    <Tooltip title='Update' sx={{ mr: 2 }}>
                      <IconButton onClick={() => setToggle(!toggle)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Box>
            );
          })}
        </>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default CardCompanyOProposer;
