import { CardContent, Box, Tooltip, IconButton } from '@mui/material';
import PageLoader from '../../../Components/Spinner/PageLoader';
import { ICompanyOwnerList } from '../../../rest/types';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import UpdateCompanyOwner from '../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCompanyOwner';
import Description, { DescriptionChild } from '../../../Common/Description';
import PageTitle from '../../../Components/PageTitle';
import ModalsWrapper from '../../../Components/Modals/ModalsWrapper';
import { AddCompanyOwner } from '../components/AddCompanyOwner';
import moment from 'moment';
import PdfAndImageView from '../../../Common/PdfAndImageView';

type IProps = {
  data: ICompanyOwnerList[];
  loading: boolean;
};

const CardCompanyOwner = ({ data, loading }: IProps) => {
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [singleOwner, setSingleOwner] = useState<ICompanyOwnerList>(
    {} as ICompanyOwnerList
  );
  const [mainOwener, setMainOwener] = useState(false);
  return (
    <>
      {data[0]?.member_company_type !== 'Proprietor' && (
        <Box display={'flex'} padding={1}>
          <PageTitle actionButtonTitle={'Add Owner'} setAction={setOpen} />
        </Box>
      )}

      {!loading ? (
        data.map((item: ICompanyOwnerList, index: number) => {
          return (
            <Box key={index}>
              <CardContent sx={{ margin: 1 }}>
                <Box>
                  <Box display={'flex'} justifyContent={'end'}>
                    <Tooltip title='Update'>
                      <IconButton
                        onClick={() => {
                          setToggle(!toggle);
                          setSingleOwner(item);
                          setUpdateModal(true);
                          setMainOwener(index === 0 ? true : false);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Description>
                    <DescriptionChild label='Owner Name'>
                      {item.company_owner_name}
                    </DescriptionChild>
                    <DescriptionChild label='Owner Email'>
                      {item.company_owner_email}
                    </DescriptionChild>
                    <DescriptionChild label='Owner NID'>
                      {item.company_owner_nid}
                    </DescriptionChild>
                    <DescriptionChild label='Date of Birth'>
                      {item.date_of_birth &&
                        moment(item.date_of_birth).format('DD-MMM-YYYY')}
                    </DescriptionChild>
                    <DescriptionChild label='Owner Address'>
                      {item.company_owner_address}
                    </DescriptionChild>
                    <DescriptionChild label='Owner Phone'>
                      {item.company_owner_phone}
                    </DescriptionChild>
                    <DescriptionChild label='Download owner bio-data'>
                      <PdfAndImageView
                        file={item.company_owner_biodata}
                        color='#00e'
                      />
                    </DescriptionChild>
                    <DescriptionChild label='Download owner photo'>
                      <PdfAndImageView
                        file={item.company_owner_photo}
                        color='#00e'
                      />
                    </DescriptionChild>

                    <DescriptionChild label='Download owner NID'>
                      <PdfAndImageView
                        file={item.company_owner_nid_file}
                        color='#00e'
                      />
                    </DescriptionChild>
                    <DescriptionChild label='Download owner signature'>
                      <PdfAndImageView
                        file={item.company_owner_signature}
                        color='#00e'
                      />
                    </DescriptionChild>

                    <DescriptionChild label='Owner designation'>
                      {item.company_owner_designation}
                    </DescriptionChild>
                  </Description>
                </Box>
              </CardContent>
            </Box>
          );
        })
      ) : (
        <PageLoader />
      )}

      {/* Add multiple data in modal  */}
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: 'Add company owner',
        }}
      >
        <AddCompanyOwner
          companyType={data[0]?.member_company_type}
          setOpen={setOpen}
        />
      </ModalsWrapper>
      {/* update modal  */}
      <ModalsWrapper
        setShowModal={setUpdateModal}
        showModal={updateModal}
        modalData={{
          title: 'Update company owner',
        }}
      >
        <UpdateCompanyOwner
          singleOwner={singleOwner}
          setRefresh={setRefresh}
          refresh={refresh}
          setUpdateModal={setUpdateModal}
          mainOwener={mainOwener}
        />
      </ModalsWrapper>
    </>
  );
};

export default CardCompanyOwner;
