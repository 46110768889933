import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentIcon from '@mui/icons-material/Payment';
import EventIcon from '@mui/icons-material/Event';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LockResetIcon from '@mui/icons-material/LockReset';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InfoIcon from '@mui/icons-material/Info';
import {
  ListItem,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import CardMembershipTwoToneIcon from '@mui/icons-material/CardMembershipTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import DisplaySettingsSharpIcon from '@mui/icons-material/DisplaySettingsSharp';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import Groups2Icon from '@mui/icons-material/Groups2';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useState } from 'react';
const SidebarMenu = () => {
  const location = useLocation();
  const currPath = location.pathname.split('/')[1];
  const [paymentOpen, setPaymentOpen] = useState(
    [
      '/payments/add-beneficiary',
      '/payments/payment-management',
      '/payments/transaction-history',
    ].includes(location.pathname)
  );
  const [support, setSupport] = useState(
    ['/support/create-ticket'].includes(location.pathname)
  );
  const navList = [
    {
      path: '/',
      pathname: 'Dashboard',
      icon: <AdminPanelSettingsIcon fontSize='small' />,
    },
    {
      path: '/new-member-applications',
      pathname: 'Update your information',
      icon: <InfoIcon fontSize='small' />,
    },
    {
      path: '/renewal-applications',
      pathname: 'Renewal application',
      icon: <DisplaySettingsSharpIcon fontSize='small' />,
    },
    {
      path: '/address-change-applications',
      pathname: 'Address change application',
      icon: <BusinessTwoToneIcon fontSize='small' />,
    },
    {
      path: '/ownership-change-applications',
      pathname: 'Ownership change application',
      icon: <PublishedWithChangesOutlinedIcon fontSize='small' />,
    },
    {
      path: '/duplicate-certificate-application',
      pathname: 'Duplicate certificate application',
      icon: <CardMembershipTwoToneIcon fontSize='small' />,
    },
    {
      path: '/agency-info-update-application',
      pathname: 'Agency information update application',
      icon: <Groups2Icon fontSize='small' />,
    },
    {
      path: '/id-card-application',
      pathname: 'ID card application',
      icon: <InfoIcon fontSize='small' />,
    },
    {
      path: '/certificate',
      pathname: 'HAAB Certificate',
      icon: <DashboardIcon fontSize='small' />,
    },
    // {
    //   path: "/member",
    //   pathname: "Information update",
    //   icon: <BadgeIcon fontSize='small' />,
    //   children: [
    //     {
    //       path: "/member/company-info",
    //       pathname: "Company Information",
    //       icon: <InfoIcon fontSize='small' />,
    //     },
    //     {
    //       path: "/member/owner-info",
    //       pathname: "Owner Information",
    //       icon: <BadgeIcon fontSize='small' />,
    //     },
    //     {
    //       path: "/member/document-info",
    //       pathname: "Document Information",
    //       icon: <BadgeIcon fontSize='small' />,
    //     },
    //     {
    //       path: "/member/trade-license-info",
    //       pathname: "Trade License Information",
    //       icon: <InfoIcon fontSize='small' />,
    //     },
    //     {
    //       path: "/member/business-info",
    //       pathname: "Business license Information",
    //       icon: <LocationCityIcon fontSize='small' />,
    //     },
    //     {
    //       path: "/member/association-info",
    //       pathname: "Other business license",
    //       icon: <CorporateFareRoundedIcon fontSize='small' />,
    //     },
    //     {
    //       path: "/member/civilAviation-info",
    //       pathname: "Civil aviation information",
    //       icon: <InfoIcon fontSize='small' />,
    //     },
    //     {
    //       path: "/member/proposer-info",
    //       pathname: "Proposer information",
    //       icon: <InsertInvitationIcon fontSize='small' />,
    //     },
    //     {
    //       path: "/member/representative-info",
    //       pathname: "Representative Information",
    //       icon: <InfoIcon fontSize='small' />,
    //     },
    //   ],
    // },
    {
      path: '/joined-meeting',
      pathname: 'Joined Meeting',
      icon: <MeetingRoomIcon fontSize='small' />,
    },
    {
      path: '/your-proposer-request',
      pathname: 'Introducer Request',
      icon: <ChromeReaderModeOutlinedIcon fontSize='small' />,
    },
    {
      path: '/joined-event',
      pathname: 'Joined Event',
      icon: <MeetingRoomIcon fontSize='small' />,
    },
    // {
    // 	path: '/id-card',
    // 	pathname: 'ID Card',
    // 	icon: <CardMembershipIcon fontSize="small" />,
    // },
    // {
    //   path: '/joined-event',
    //   pathname: 'Joined Event',
    //   icon: <EventIcon fontSize='small' />,
    // },
    {
      path: '/payments',
      pathname: 'Payment',
      icon: <PaymentIcon fontSize='small' />,
      children: [
        {
          path: '/payments/add-beneficiary',
          pathname: 'Add Beneficiary',
          icon: <PersonAddAltIcon style={{ fontSize: '18px' }} />,
        },
        {
          path: '/payments/payment-management',
          pathname: 'Payment Management',
          icon: <PointOfSaleIcon style={{ fontSize: '18px' }} />,
        },
        {
          path: '/payments/transaction-history',
          pathname: 'Transaction History',
          icon: <ManageSearchIcon style={{ fontSize: '18px' }} />,
        },
      ],
    },
    {
      path: '/view-complain',
      pathname: 'View Complain',
      icon: <FactCheckIcon fontSize='small' />,
    },
    {
      path: '/notification',
      pathname: 'Notification',
      icon: <NotificationsIcon fontSize='small' />,
    },
    {
      path: '/change-password',
      pathname: 'Change Password',
      icon: <LockResetIcon fontSize='small' />,
    },
    {
      path: '/support',
      pathname: 'Support',
      icon: <SupportAgentIcon fontSize='small' />,
      children: [
        {
          path: '/support/create-ticket',
          pathname: 'Ticket Create',
          icon: <PersonAddAltIcon style={{ fontSize: '18px' }} />,
        },
      ],
    },
  ];

  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          pr: 0,
        }}
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <Link to='/'>
            <img
              style={{
                marginLeft: '25%',
                paddingBottom: '8px',
              }}
              width={70}
              height={70}
              src={'/logo.png'}
              alt=''
            />
          </Link>
        }
      >
        {navList?.map((item, i) =>
          Array.isArray(item.children) ? (
            <Accordion
              key={item.path + i}
              expanded={item.pathname === 'Payment' ? paymentOpen : support}
              onChange={(e: any, r) => {
                item.pathname === 'Payment'
                  ? setPaymentOpen(!paymentOpen)
                  : setSupport(!support);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel-content'
                id='panel-header'
              >
                <ListItemIcon style={{ minWidth: '33px' }}>
                  {item?.icon}
                </ListItemIcon>
                <ListItemText primary={`${item.pathname}`} />
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingLeft: 2,
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                  backgroundColor: '#F2F5F9',
                }}
              >
                {item?.children?.map((childItem, index) => (
                  <NavLink
                    key={childItem.path + index}
                    className={
                      location.pathname === childItem.path
                        ? 'activeSidebarLink'
                        : 'unActiveLink'
                    }
                    to={childItem.path}
                  >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon style={{ minWidth: '33px' }}>
                          {childItem?.icon}
                        </ListItemIcon>
                        <ListItemText primary={childItem.pathname} />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                ))}
              </AccordionDetails>
            </Accordion>
          ) : (
            <NavLink
              key={item.path + i}
              className={
                `/${currPath}` === item.path
                  ? 'activeSidebarLink'
                  : 'unActiveLink'
              }
              to={item.path}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon style={{ minWidth: '33px' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={`${item.pathname}`} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          )
        )}
      </List>
      <List style={{ marginTop: `auto` }}>
        <ListItem>
          <ListItemText>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <img src='/trabil.png' alt='' style={{ height: 50 }} />

              <div style={{ textAlign: 'center' }}>
                <p style={{ fontSize: '0.75rem' }}>
                  Trabill is the best software for your Travel Agency Business.
                </p>
                <a
                  href='https://trabill.app'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Click here to see the software demo
                </a>
                <br />
                <span>Call: +8809638336699</span>
              </div>
            </div>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};

export default SidebarMenu;
