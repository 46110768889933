import { Box, Typography, styled } from '@mui/material';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        @media (min-width: ${theme.breakpoints.values.md}px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
`
);
interface IsingleSupportConversation {
  support_conversation_id: number;
  support_conversation_status: string;
  user_member_id: number;
  user_member_representative_name: string;
  user_member_company_name: string;
  user_member_representative_photo: string | null;
}
function TopBarContent() {
  return (
    <>
      <RootWrapper>
        <Box display='flex' alignItems='center'>
          <Box
            style={{
              background: '#BCC1CB',
              borderRadius: '100%',
              height: '40px',
              width: '40px',
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            color={'white'}
          >
            H
          </Box>

          <Box ml={1}>
            <Typography variant='h4'>HAAB Admin</Typography>
          </Box>
        </Box>
      </RootWrapper>
    </>
  );
}

export default TopBarContent;
