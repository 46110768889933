import { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Button,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useForm } from 'react-hook-form';
import { IBusinessLicense } from '../../../Types/UpdateInfotypes/UpdateCompanyInfoStateTypes';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { useCompanyBusinessLicenseUpdate } from '../../../rest/useCompanyInfo';

const AddBusinessLicenseInfo = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useCompanyBusinessLicenseUpdate();
  const [businessLicense, setBusinessLicense] = useState<IBusinessLicense>({
    hajj_license: 0,
    umrah_license: 0,
    recruting_license: 0,
    iata_agent: 0,
    baira: 0,
    toab: 0,
    tdab: 0,
  });
  const onSubmit = async (inputsData: any) => {
    inputsData = { ...inputsData, ...businessLicense };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    mutate({ input: formData });
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant='h6' gutterBottom>
            Business License ( please tick mark in box )
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              textAlign: 'center',
            }}
          >
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='Hajj License'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      hajj_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      hajj_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='Umrah License'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      umrah_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      umrah_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='Recruiting License'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      recruting_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      recruting_license: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>

          <Box>
            {businessLicense.hajj_license ? (
              <Box>
                <label htmlFor=''>
                  Member organization Hajj license{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('hajj_license_file', {
                    required: 'Hajj license file is required',
                  })}
                  error={
                    errors.hajj_license_file &&
                    Boolean(errors.hajj_license_file)
                  }
                  helperText={
                    Boolean(errors?.hajj_license_file?.message)
                      ? `${errors?.hajj_license_file?.message}`
                      : ''
                  }
                />
              </Box>
            ) : (
              ''
            )}
            {businessLicense.umrah_license ? (
              <Box>
                <label htmlFor=''>
                  Member organization Umrah license{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('umrah_license_file', {
                    required: 'Umrah license file is required',
                  })}
                  error={
                    errors.umrah_license_file &&
                    Boolean(errors.umrah_license_file)
                  }
                  helperText={
                    Boolean(errors?.umrah_license_file?.message)
                      ? `${errors?.umrah_license_file?.message}`
                      : ''
                  }
                />
              </Box>
            ) : (
              ''
            )}
            {businessLicense.recruting_license ? (
              <Box>
                <label htmlFor=''>
                  Member organization Recruiting license
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('recruting_license_file', {
                    required: 'Recruiting license is required',
                  })}
                  error={
                    errors.recruting_license_file &&
                    Boolean(errors.recruting_license_file)
                  }
                  helperText={
                    Boolean(errors?.recruting_license_file?.message)
                      ? `${errors?.recruting_license_file?.message}`
                      : ''
                  }
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Grid>
      </Grid>

      <Typography variant='h6' gutterBottom>
        Other Certificates (if any)
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='IATA Certificate'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      iata_agent: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      iata_agent: 0,
                    });
                  }
                }}
              />
            </Box>
            <Grid item xs={4}>
              {businessLicense.iata_agent ? (
                <Box>
                  <label htmlFor=''>
                    Upload association IATA document{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <TextField
                    size='small'
                    id='outlined-basic'
                    variant='outlined'
                    type='file'
                    required
                    fullWidth
                    sx={{ pb: 2 }}
                    {...register('iata_agent_file', {
                      required: 'IATA membership document is required',
                    })}
                    error={
                      errors.iata_agent_file && Boolean(errors.iata_agent_file)
                    }
                    helperText={
                      Boolean(errors?.iata_agent_file?.message)
                        ? `${errors?.iata_agent_file?.message}`
                        : ''
                    }
                  />
                </Box>
              ) : (
                ''
              )}
            </Grid>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='BAIRA Certificate'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      baira: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      baira: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>

          <Grid item xs={4}>
            {businessLicense.baira ? (
              <Box>
                <label htmlFor=''>
                  Upload association BAIRA document{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('baira_file', {
                    required: 'BAIRA membership document is required',
                  })}
                  error={errors.baira_file && Boolean(errors.baira_file)}
                  helperText={
                    Boolean(errors?.baira_file?.message)
                      ? `${errors?.baira_file?.message}`
                      : ''
                  }
                />
              </Box>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='TOAB Certificate'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      toab: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      toab: 0,
                    });
                  }
                }}
              />
            </Box>
            {businessLicense.toab ? (
              <Box>
                <label htmlFor=''>
                  Upload association TOAB document{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('toab_file', {
                    required: 'TOAB membership document is required',
                  })}
                  error={errors.toab_file && Boolean(errors.toab_file)}
                  helperText={
                    Boolean(errors?.toab_file?.message)
                      ? `${errors?.toab_file?.message}`
                      : ''
                  }
                />
              </Box>
            ) : (
              ''
            )}
            <Box sx={{ pl: { xs: 12, md: 0 } }}>
              <FormControlLabel
                control={<Checkbox />}
                label='TDAB Certificate'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      tdab: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      tdab: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
          <Box>
            {businessLicense.tdab ? (
              <Box>
                <label htmlFor=''>
                  Upload association TDAB document{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('tdab_file', {
                    required: 'TDAB membership document is required',
                  })}
                  error={errors.tdab_file && Boolean(errors.tdab_file)}
                  helperText={
                    Boolean(errors?.tdab_file?.message)
                      ? `${errors?.tdab_file?.message}`
                      : ''
                  }
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Grid>
      </Grid>

      {!user.is_applied ? (
        <Box sx={{ mt: 2, textAlign: 'end', mb: 1 }}>
          {isLoading ? (
            <Button
              disabled
              variant='contained'
              disableElevation
              className='add-remove-button'
              size='small'
            >
              Loading...
            </Button>
          ) : (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              className='add-remove-button'
              size='small'
            >
              Save
            </Button>
          )}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AddBusinessLicenseInfo;
