import { Box, CardContent } from '@mui/material';
import PageLoader from '../../../Components/Spinner/PageLoader';
import { ICompanyDocument } from '../../../rest/types';
import Description, { DescriptionChild } from '../../../Common/Description';
import PdfAndImageView from '../../../Common/PdfAndImageView';
type IProps = {
  data: ICompanyDocument;
  loading: boolean;
};

const CardCompanyDocument = ({ data: item, loading }: IProps) => {
  const companyOwnerType = item.company_ownership_status;
  const officeAgreement = item.company_office_agreement;

  return (
    <>
      {!loading ? (
        <CardContent>
          <Box maxWidth={1000} sx={{ margin: '30px auto' }}>
            <Description>
              <DescriptionChild label='OwnerShip status'>
                {item.company_ownership_status}
              </DescriptionChild>
              <DescriptionChild label='Office agreement'>
                {item.company_office_agreement}
              </DescriptionChild>
              <DescriptionChild label='Upload staff list on office pad with seal and signature of owner (PDF)'>
                <PdfAndImageView
                  file={item.company_staff_list_file}
                  color='#00e'
                />
              </DescriptionChild>
              <DescriptionChild label='Upload internal & external photographs of office Including Signboard (PDF)'>
                <PdfAndImageView
                  file={item.company_signboard_file}
                  color='#00e'
                />
              </DescriptionChild>
              {companyOwnerType === 'Limited' && (
                <DescriptionChild label='Download company memorandum file'>
                  <PdfAndImageView
                    file={item.company_memorandum_file}
                    color='#00e'
                  />
                </DescriptionChild>
              )}
              {companyOwnerType === 'Limited' && (
                <DescriptionChild label='Download Form 12 file'>
                  <PdfAndImageView file={item.company_form_12} color='#00e' />
                </DescriptionChild>
              )}
              {companyOwnerType === 'Limited' && (
                <DescriptionChild label='Download Form 117 file'>
                  <PdfAndImageView file={item?.company_form_117} color='#00e' />
                </DescriptionChild>
              )}
              {companyOwnerType === 'Limited' && (
                <DescriptionChild label='Download company incorporation certificate'>
                  <PdfAndImageView
                    file={item?.company_incorporation_certificate}
                    color='#00e'
                  />
                </DescriptionChild>
              )}
              {companyOwnerType === 'Partnership' && (
                <DescriptionChild label='Download company partnership deed file'>
                  <PdfAndImageView
                    file={item?.company_partnership_deed_file}
                    color='#00e'
                  />
                </DescriptionChild>
              )}

              {officeAgreement === 'Rented premise' && (
                <DescriptionChild label='Download Rented premise company agreement file'>
                  <PdfAndImageView
                    file={item?.company_office_agreement}
                    color='#00e'
                  />
                </DescriptionChild>
              )}
              {officeAgreement === 'Self premise' && (
                <DescriptionChild label='Download Self premise company agreement file'>
                  <PdfAndImageView
                    file={item.company_agreement_file}
                    color='#00e'
                  />
                </DescriptionChild>
              )}
            </Description>
          </Box>
        </CardContent>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default CardCompanyDocument;
