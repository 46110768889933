import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';

const TableLoader = () => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={100}>
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <CircularProgress style={{ color: '#00984a' }} />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableLoader;
export function TableNotFound() {
  return (
    <TableRow>
      <TableCell
        sx={{ fontSize: '20px', fontWeight: 'bold' }}
        colSpan={100}
        align='center'
      >
        No data found
      </TableCell>
    </TableRow>
  );
}
