import React, { useState, useCallback } from 'react';
import { Typography, CircularProgress, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box } from '@mui/system';
import axios from 'axios';
import {
  SET_MESSAGE,
  SET_NEW_MESSAGE,
  url,
} from '../../Helpers/utils/Constant';
import { IConversation } from '../../Types/ConversationTypes/ConversationTypes';
import { useEffect } from 'react';
import ShowConvMessage from './ShowConvMessage';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FilesUploadModal from './FilesUploadModal';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';

interface IConvoBox {
  setSupportShow: React.Dispatch<React.SetStateAction<boolean>>;
  conversation: IConversation | undefined;
  userId: number;
}

const ConvoBox = ({ setSupportShow, conversation, userId }: IConvoBox) => {
  const { dispatch, messages } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [files, setFiles] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const setRef = useCallback((node: any) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);

  useEffect(() => {
    if (conversation?.support_conversation_id && !messages.length) {
      setLoading(true);
      axios
        .get(
          `${url}/api/atab/support-conversation/get/all/message/conversation/${conversation.support_conversation_id}`
        )
        .then(function (res) {
          if (res.data.success) {
            dispatch?.({ type: SET_MESSAGE, payload: res.data.data });
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(function (err) {
          if (err.response.data.message) {
            setLoading(false);
          }
        });
    }
  }, [conversation?.support_conversation_id, dispatch, messages.length]);

  const handelSendMessage = () => {
    const formData = new FormData();
    if (conversation?.support_conversation_id) {
      formData.append(
        'support_conversation_msg_conv_id',
        conversation?.support_conversation_id.toString()
      );
    }
    if (message) {
      formData.append('support_conversation_msg_text', message);
    }
    if (files.length) {
      files.forEach((file: any, index: number) => {
        const name = 'support_conversation_msg_atchment' + index + 1;
        formData.append(name, file);
      });
    }
    formData.append('support_conversation_msg_sender', 'member');
    formData.append('support_conversation_sender_member_id', userId.toString());
    axios
      .post(`${url}/api/atab/support-conversation/send-message`, formData)
      .then(function (res) {
        if (res.data.success) {
          const newMessage: any = {
            support_conversation_msg_text: message,
            support_conversation_msg_conv_id:
              conversation?.support_conversation_id,
            support_conversation_msg_text_time:
              res.data.data.support_conversation_msg_text_time,
            support_conversation_msg_id:
              res.data.data.support_conversation_msg_id,
            support_conversation_msg_atchments:
              res.data.data.support_conversation_msg_atchments,
            support_conversation_sender_member_id: userId,
          };
          dispatch?.({ type: SET_NEW_MESSAGE, payload: newMessage });
          setMessage('');
          setFiles([]);
        }
      })
      .catch(function (err) {});
  };

  return (
    <Box
      bgcolor='#ffff'
      height={450}
      width={310}
      border={1}
      borderColor={'#00984a'}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        p={1}
        bgcolor='#00984a'
      >
        <Box display='flex' alignItems='center' gap={1}>
          <img
            src='https://embed.tawk.to/_s/v4/assets/images/default-profile.svg'
            alt=''
            width={40}
          />
          <Typography variant='body2' color='white'>
            HAAB Admin
          </Typography>
        </Box>
        <Box>
          <HighlightOffIcon
            onClick={() => setSupportShow(false)}
            sx={{ color: 'white', cursor: 'pointer' }}
          />
        </Box>
      </Box>
      <Box className={messages.length > 5 ? 'show-message-box' : ''}>
        {loading ? (
          <Box sx={{ textAlign: 'center', mt: 6 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {messages?.length ? (
              <Box>
                {messages?.map((sMessage: any, index: number) => {
                  const lastMessage = messages.length - 1 === index;

                  return (
                    <Box
                      ref={lastMessage ? setRef : null}
                      key={sMessage.support_conversation_msg_id}
                    >
                      <ShowConvMessage sMessage={sMessage} />
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <>
                <Typography
                  variant='body1'
                  gutterBottom
                  textAlign={'center'}
                  pt={10}
                >
                  No message yet
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
      <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <Box className='input-field-and-button'>
          <IconButton
            color='primary'
            aria-label='upload picture'
            component='label'
            onClick={handleOpen}
          >
            <DriveFolderUploadIcon
              sx={{ fontSize: '30px', color: '#5e60ff' }}
            />
          </IconButton>
          <input
            type='text'
            value={message}
            className='input-field'
            placeholder='Enter your message...'
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
              e.key === 'Enter' && handelSendMessage();
            }}
          />
          {message || files.length ? (
            <button className='send-button' onClick={handelSendMessage}>
              Send
            </button>
          ) : (
            ''
          )}
        </Box>
      </Box>

      <FilesUploadModal
        open={open}
        handleClose={handleClose}
        files={files}
        setFiles={setFiles}
        handelSendMessage={handelSendMessage}
        setMessage={setMessage}
        message={message}
      />
    </Box>
  );
};

export default ConvoBox;
