import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  TextField,
  Typography,
  InputLabel,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import { Link } from "react-router-dom";
import { showToast } from "../../Toaster/Toaster";
import Lottie from "react-lottie";
import { LottiedefaultOptions2 } from "../../Utils/Comibined";
import { muiStyles } from "../../Utils/Styles";
import axios from "axios";
import EmailVerifiModal from "./Page/EmailVerifiModal";
import { url } from "../../Helpers/utils/Constant";
const forLimited = ["Managing Director", "Director", "Chairman", "CEO"];
const forPartnerShip = ["Managing Partner", "CEO"];
const forProprietor = ["Proprietor"];
const useStyles = muiStyles;
const Register = () => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState<any>({});

  const defaultOptions2 = LottiedefaultOptions2;

  const [open, setOpen] = React.useState(false);

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    const {
      user_member_confirm_password,
      user_member_email,
      user_member_hajj_license,
      ...body
    } = inputs;

    try {
      axios
        .post(`${url}/api/atab/common/send-email-otp`, {
          email: user_member_email,
          type: "register_member",
          user_member_hajj_license: user_member_hajj_license,
        })
        .then(function (res) {
          if (res.data.success) {
            setOpen(true);
            showToast(
              `We sent verification code to ${user_member_email}`,
              "success"
            );

            setInputData({
              user_member_hajj_license,
              ...body,
              new_member: "1",
              user_member_email,
            });

            setLoading(false);
          }
        })
        .catch(function (err) {
          showToast(`${err.response.data.message}`, "error");
          setLoading(false);
        });
    } catch (error) {}
  };
  const companyType: "Proprietor" | "Limited" | "Partnership" = watch(
    "member_company_type"
  );
  useEffect(() => {
    if (companyType === "Proprietor") {
      setValue("company_owner_designation", "Proprietor");
    } else {
      setValue("company_owner_designation", undefined);
    }
  }, [companyType, setValue]);

  return (
    <>
      <div className="register-page">
        <div className="register-wrapper">
          <div>
            <div className="register-left-col">
              <Lottie options={defaultOptions2} />
            </div>
          </div>
          <div>
            <div className="register-right-col">
              <Container component="main" maxWidth="md">
                <CssBaseline />

                <div>
                  <div className="right-top-div">
                    <img src="/logo.png" alt="" />
                    <div className="pt-3">
                      <Typography
                        gutterBottom
                        sx={{
                          fontVariant: "small-caps",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          color: "#004e2e",
                          pb: 2,
                          fontSize: "30px",
                        }}
                      >
                        Become a Member
                      </Typography>
                    </div>
                  </div>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-grid">
                      <TextField
                        margin="normal"
                        fullWidth
                        id="outlined-basic"
                        required
                        label="Enter company name"
                        placeholder="Enter company name"
                        {...register("user_member_company_name", {
                          required: "Company name is required",
                        })}
                        error={
                          errors.user_member_company_name &&
                          Boolean(errors.user_member_company_name)
                        }
                        helperText={
                          Boolean(errors.user_member_company_name?.message)
                            ? "Company name is required"
                            : ""
                        }
                        type="text"
                        autoFocus
                        variant="standard"
                      />

                      <TextField
                        margin="normal"
                        fullWidth
                        id="Hajj-license"
                        required
                        label="Hajj license no"
                        placeholder="Hajj license no"
                        {...register("user_member_hajj_license", {
                          required: "Hajj license number is required",
                          onChange: (e) => {
                            const value = e.target.value;

                            if (!/^\d*$/.test(value)) {
                              setError("user_member_hajj_license", {
                                type: "manual",
                                message: "Only Number Allows",
                              });
                            }

                            if (value.length > 4) {
                              setError("user_member_hajj_license", {
                                type: "manual",
                                message: "must be exactly 4 digits",
                              });
                            } else if (
                              value.length === 4 &&
                              /^\d{4}$/.test(value)
                            ) {
                              clearErrors("user_member_hajj_license");
                            }
                          },
                        })}
                        type="text"
                        autoFocus
                        size="small"
                        variant="standard"
                        error={!!errors.user_member_hajj_license}
                        helperText={
                          errors.user_member_hajj_license?.message as string
                        }
                      />

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="phone"
                        label="Enter Owner phone"
                        placeholder="Enter Owner phone"
                        {...register("user_member_phone", {
                          required: "Phone number is required",
                          pattern: {
                            value:
                              /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/,
                            message: "Invalid phone number",
                          },
                        })}
                        error={
                          errors.user_member_phone &&
                          Boolean(errors.user_member_phone)
                        }
                        helperText={
                          Boolean(errors.user_member_phone?.message)
                            ? `${errors.user_member_phone?.message}`
                            : ""
                        }
                        type="number"
                        autoFocus
                        variant="standard"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="Owner name"
                        label="Owner name"
                        placeholder="owner name"
                        {...register("company_owner_name", {
                          required: "Owner name is required",
                        })}
                        error={
                          errors.user_member_representative_name &&
                          Boolean(errors.user_member_representative_name)
                        }
                        helperText={
                          Boolean(
                            errors.user_member_representative_name?.message
                          )
                            ? "Representative name is required"
                            : ""
                        }
                        type="text"
                        autoFocus
                        variant="standard"
                      />

                      <FormControl fullWidth variant="standard" size="small">
                        <InputLabel id="demo-simple-select-label">
                          Company Type
                        </InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Company Type"
                          {...register("member_company_type")}
                        >
                          <MenuItem value={"Proprietor"}>Proprietor</MenuItem>
                          <MenuItem value={"Partnership"}>Partnership</MenuItem>
                          <MenuItem value={"Limited"}>Limited</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth size="small">
                        <Controller
                          name="company_owner_designation"
                          control={control}
                          defaultValue={
                            companyType === "Proprietor" ? forProprietor[0] : ""
                          }
                          render={({
                            field: { onChange, value, ...fieldProps },
                          }) => (
                            <Autocomplete
                              freeSolo
                              options={
                                companyType === "Proprietor"
                                  ? forProprietor
                                  : companyType === "Limited"
                                  ? forLimited
                                  : forPartnerShip
                              }
                              value={value}
                              onChange={(event, newValue) => {
                                onChange(newValue); // Update form value
                                setValue("company_owner_designation", newValue); // Ensure form is aware of the change
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Enter Owner designation"
                                  required
                                  size="small"
                                  variant="standard" // Move variant here
                                />
                              )}
                              onInputChange={(event, newValue) => {
                                setValue("company_owner_designation", newValue);
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl
                        fullWidth
                        variant="standard"
                        error={
                          errors.user_member_zone_id &&
                          Boolean(errors.user_member_zone_id)
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select zone
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select-error"
                          label="Select zone"
                          variant="standard"
                          required
                          {...register("user_member_zone_id", {
                            required: "Zone is required",
                          })}
                        >
                          <MenuItem value={1}>Dhaka</MenuItem>
                          <MenuItem value={2}>Chittagong</MenuItem>
                          <MenuItem value={3}>Sylhet</MenuItem>
                        </Select>
                        {Boolean(errors.user_member_zone_id?.message) && (
                          <FormHelperText>"Zone is required"</FormHelperText>
                        )}
                      </FormControl>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Enter Owner email"
                        placeholder="Enter Owner email"
                        {...register("user_member_email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email",
                          },
                        })}
                        error={
                          errors.user_member_email &&
                          Boolean(errors.user_member_email)
                        }
                        helperText={
                          Boolean(errors.user_member_email?.message)
                            ? `${errors.user_member_email?.message}`
                            : ""
                        }
                        autoComplete="email"
                        type="email"
                        autoFocus
                        variant="standard"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        {...register("user_member_password", {
                          required: "Password is required",
                        })}
                        error={
                          errors.user_member_password &&
                          Boolean(errors.user_member_password)
                        }
                        helperText={
                          Boolean(errors.user_member_password?.message)
                            ? "Password is required"
                            : ""
                        }
                        label="Enter password"
                        placeholder="Enter password"
                        type={`${showPassword ? "text" : "password"}`}
                        id="password"
                        autoComplete="current-password"
                        variant="standard"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        {...register("user_member_confirm_password", {
                          required: "Confirm password is required",
                          validate: (value) => {
                            if (watch("user_member_password") !== value) {
                              return "Password does not match";
                            }
                          },
                        })}
                        error={
                          errors.user_member_confirm_password &&
                          Boolean(errors.user_member_confirm_password)
                        }
                        helperText={
                          Boolean(errors.user_member_confirm_password?.message)
                            ? "Confirm password is required"
                            : ""
                        }
                        label="Enter confirm password"
                        placeholder="Enter confirm password"
                        type={`${showPassword ? "text" : "password"}`}
                        id="password2"
                        autoComplete="current-password"
                        variant="standard"
                      />
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => setShowPassword(!showPassword)}
                          value={showPassword}
                          color="primary"
                        />
                      }
                      label="Show Password"
                    />
                    {loading ? (
                      <Button
                        disabled
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, background: "#004e2e" }}
                      >
                        Loading...
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disableElevation
                        sx={{
                          mt: 3,
                          mb: 2,
                          py: 1,
                          background: "#004e2e",
                        }}
                        className={classes.signInButton}
                      >
                        Sign Up
                      </Button>
                    )}
                    <Grid container>
                      <Grid item>
                        <Typography color="GrayText">
                          Do you have an account?{" "}
                          <Link to="/login">
                            <span
                              style={{ textDecoration: "underline" }}
                              className="text-main fw-bold "
                            >
                              login
                            </span>
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <EmailVerifiModal
        open={open}
        handleClose={() => setOpen(false)}
        inputData={inputData}
        setLoading={setLoading}
      />
    </>
  );
};

export default Register;
