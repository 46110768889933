import { Grid, TextField } from '@mui/material';
import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

const BearerCashCheque = ({
  register,
  errors,
}: {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
}) => {
  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Cheque Issue Bank'
          variant='outlined'
          size='small'
          placeholder='Cheque Issue Bank'
          InputLabelProps={{ shrink: true }}
          {...register('cheque_issue_bank', {
            required: 'Cheque Issue Bank name is required',
          })}
          error={!!errors.cheque_issue_bank}
          helperText={errors.cheque_issue_bank?.message as string}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Cheque Number'
          variant='outlined'
          size='small'
          placeholder='Cheque Number'
          InputLabelProps={{ shrink: true }}
          {...register('cheque_number', {
            required: 'Cheque number is required',
          })}
          type='number'
          error={!!errors.cheque_number}
          helperText={errors.cheque_number?.message as string}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Cheque Issue Date'
          variant='outlined'
          size='small'
          placeholder='Cheque issue date'
          InputLabelProps={{ shrink: true }}
          {...register('cheque_issue_date', {
            required: 'Cheque issue date is required',
          })}
          type='date'
          error={!!errors.cheque_issue_date}
          helperText={errors.cheque_issue_date?.message as string}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Upload Cheque (pdf)'
          variant='outlined'
          size='small'
          placeholder='Cheque upload'
          InputLabelProps={{ shrink: true }}
          {...register('cheque_photo', {
            required: 'Cheque file is required',
          })}
          inputProps={{
            accept: 'application/pdf', // Only allow PDF files
          }}
          type='file'
          error={!!errors.cheque_photo}
          helperText={errors.cheque_photo?.message as string}
        />
      </Grid>
    </>
  );
};

export default BearerCashCheque;
