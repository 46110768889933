import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	ICompanyProposerReceiver,
	ICompanyProposerReceiverResponse,
} from './types';
import { API_ENDPOINTS } from './utils/api-endpoints';
import { fetchProposerReceiver, postUpdateProposerRequest } from '.';
import { useState } from 'react';
import { showToast } from '../Toaster/Toaster';

export function useProposerReceiver(id: string) {
	const { data, isLoading, error } = useQuery<
		ICompanyProposerReceiverResponse,
		Error
	>(
		[API_ENDPOINTS.PROPOSER_REQUEST_UPDATE],
		() => fetchProposerReceiver(id as string),
		{
			refetchOnWindowFocus: false,
		}
	);
	return {
		companyProposer: data?.data ?? ([] as ICompanyProposerReceiver[]),
		isLoading,
		error,
	};
}

export function useProposerStatusUpdate() {
	const [formError, setFormError] = useState(null);
	const queryClient = useQueryClient();
	const { mutate, isLoading, isSuccess } = useMutation(
		postUpdateProposerRequest,
		{
			onSuccess: (data) => {
				if (data?.success) {
					showToast(`Successfully updated status`, 'success');
				}
			},
			onError: (error) => {
				const {
					response: { data },
				}: any = error ?? {};
				showToast(`${data.message}`, 'error');
				setFormError(data?.message);
			},
			onSettled: () => {
				queryClient.invalidateQueries({
					queryKey: [API_ENDPOINTS.PROPOSER_REQUEST_UPDATE],
				});
			},
		}
	);

	return { mutate, isLoading, formError, setFormError, isSuccess };
}
