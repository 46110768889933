/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { useCreateIDCard } from '../../rest/useCompanyIDCard';
import { useCompanyOwnerQuery } from '../../rest/useCompanyInfo';
import moment from 'moment';

type IProps = {
  handleClose: () => void;
};

const IdCardFormV2 = ({ handleClose }: IProps) => {
  const { companyOwnerData } = useCompanyOwnerQuery();
  const { user } = useAuthContext();
  const { register, handleSubmit, setValue } = useForm();
  const { isLoading, mutate, isSuccess } = useCreateIDCard();
  const [bloodGroup, setBloodGroup] = useState<string>('');

  useEffect(() => {
    if (companyOwnerData && companyOwnerData[0]) {
      const ownerData = companyOwnerData[0];
      setValue('applicant_name', ownerData.company_owner_name || '');
      setValue('designation', ownerData.company_owner_designation || '');
      setValue(
        'date_of_birth',
        ownerData.date_of_birth
          ? moment(ownerData.date_of_birth).format('YYYY-MM-DD')
          : ''
      );
      setValue('cellphone', ownerData.company_owner_phone || '');
      setValue('email', ownerData.company_owner_email || '');
      setValue('nid_file', ownerData.company_owner_nid_file || '');
      setValue('photo', ownerData.company_owner_photo || '');
      setValue('website', ownerData.member_company_website || '');
      setValue('telephone', ownerData.cellphone || '');
    }
  }, [companyOwnerData, setValue]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  const onSubmit = async (data: any) => {
    const ownerId = companyOwnerData[0]?.company_owner_id;

    data.blood_group = bloodGroup;
    data.owner_id = ownerId;
    data.user_member_id = user?.user_member_id;

    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (
        item === 'nid_file' ||
        item === 'tin_file' ||
        item === 'signature_file' ||
        item === 'photo'
      ) {
        formData.append(item, data[item][0]);
      } else {
        formData.append(item, data[item]);
      }
    });
    mutate(formData);
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2} mb={2}>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            label='Owner Name'
            required
            InputLabelProps={{ shrink: true }}
            {...register('applicant_name')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            label='Designation'
            required
            InputLabelProps={{ shrink: true }}
            {...register('designation')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            required
            type='date'
            label='Date of Birth'
            InputLabelProps={{ shrink: true }}
            {...register('date_of_birth')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <FormControl fullWidth size='small' required>
            <InputLabel id='blood-group-label'>Select Blood Group</InputLabel>
            <Select
              labelId='blood-group-label'
              value={bloodGroup}
              onChange={(e) => setBloodGroup(e.target.value)}
              label='Select Blood Group'
            >
              {['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'].map(
                (group) => (
                  <MenuItem key={group} value={group.toLowerCase()}>
                    {group}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            label='Telephone number'
            required
            InputLabelProps={{ shrink: true }}
            {...register('telephone')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            label='Mobile Number'
            required
            InputLabelProps={{ shrink: true }}
            {...register('cellphone')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            label='Email'
            required
            type='email'
            InputLabelProps={{ shrink: true }}
            {...register('email')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            required
            label='Website'
            InputLabelProps={{ shrink: true }}
            {...register('website')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            label='NID (Both sides, PDF)'
            required
            type='file'
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: 'application/pdf' }}
            {...register('nid_file')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            label='TIN Certificate (PDF)'
            required
            type='file'
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: 'application/pdf' }}
            {...register('tin_file')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            label='Signature with Seal (PDF)'
            required
            type='file'
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: 'application/pdf' }}
            {...register('signature_file')}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            size='small'
            fullWidth
            label='Up-to-date Photo (JPG/JPEG/PNG)'
            required
            type='file'
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: 'image/jpeg,image/png' }}
            {...register('photo')}
          />
        </Grid>
      </Grid>
      <Box mt={5}>
        {!isLoading ? (
          <Button type='submit' fullWidth variant='contained' disableElevation>
            Submit
          </Button>
        ) : (
          <LoadingButton
            loading
            loadingIndicator='Loading...'
            variant='contained'
            fullWidth
            disabled
          >
            <span>Loading...</span>
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};

export default IdCardFormV2;
