import { Card, CardContent } from '@mui/material';
import { Container } from '@mui/system';
import moment from 'moment';
import { useParams } from 'react-router';
import { getStatusLabel } from '../../Utils/Label/status-label';
import PageLoader from '../../Components/Spinner/PageLoader';
import Description, { DescriptionChild } from '../../Common/Description';
import PdfAndImageView from '../../Common/PdfAndImageView';
import { useDuplicateSingleQuery } from './apiEndpoints/apiEndpoints';

const SingleDuplicateCertificateApplication = () => {
  const { id } = useParams();
  const { data, isLoading } = useDuplicateSingleQuery(Number(id)!);

  return !isLoading ? (
    <Container sx={{ pt: 5 }}>
      <Card>
        <CardContent>
          <Description>
            <DescriptionChild label='Application Date'>
              {moment(
                data?.data?.duplicate_certificate_application_created_at
              ).format('MMM Do YY')}
            </DescriptionChild>
            <DescriptionChild label=' Company name'>
              {data?.data?.user_member_company_name}
            </DescriptionChild>
            <DescriptionChild label='Status'>
              {getStatusLabel(
                data?.data?.duplicate_certificate_application_status!
              )}
            </DescriptionChild>
            <DescriptionChild label='Download Civil Aviation'>
              <PdfAndImageView
                file={
                  data?.data?.duplicate_certificate_application_civil_aviation
                }
              />
            </DescriptionChild>
            <DescriptionChild label='Download Trade License'>
              <PdfAndImageView
                file={
                  data?.data?.duplicate_certificate_application_trade_license
                }
              />
            </DescriptionChild>
            <DescriptionChild label='Download Membership Card'>
              <PdfAndImageView
                file={
                  data?.data?.duplicate_certificate_application_memberhsip_card
                }
              />
            </DescriptionChild>
            <DescriptionChild label='Download Tax return'>
              <PdfAndImageView
                file={data?.data?.duplicate_certificate_application_tax_return}
              />
            </DescriptionChild>
            <DescriptionChild label='Download Previous Certificate'>
              <PdfAndImageView
                file={
                  data?.data
                    ?.duplicate_certificate_application_previous_certificate
                }
              />
            </DescriptionChild>
            <DescriptionChild label='Download Duplicate Certificate application'>
              <PdfAndImageView
                file={
                  data?.data?.duplicate_certificate_application_application_file
                }
              />
            </DescriptionChild>
          </Description>
        </CardContent>
      </Card>
    </Container>
  ) : (
    <PageLoader />
  );
};

export default SingleDuplicateCertificateApplication;
