import { Grid, Typography } from '@mui/material';
import React from 'react';

const Description = ({ children }: { children: React.ReactNode }) => {
  // Normalize children into an array
  const normalizedChildren = React.Children.toArray(children);

  return (
    <Grid container spacing={0} display={'flex'}>
      {normalizedChildren?.map((child: any, index: number) => (
        <React.Fragment key={index}>
          <Grid
            item
            xs={6}
            sx={{
              background: 'rgba(0, 0, 0, 0.02)',
              padding: 1,
              borderBottom:
                normalizedChildren.length - 1 === index
                  ? '1px solid rgba(5, 5, 5, 0.06)'
                  : '0px',
              borderTop: '1px solid rgba(5, 5, 5, 0.06)',
              borderLeft: '1px solid rgba(5, 5, 5, 0.06)',
              borderRight: '0px',
              borderTopLeftRadius: index === 0 ? '8px' : '0px', // Top left corner
              borderBottomLeftRadius:
                normalizedChildren.length - 1 === index ? '8px' : '0px', // Bottom left corner
            }}
          >
            <Typography
              variant='body1'
              style={{ color: 'rgba(0, 0, 0, 0.65)', fontWeight: '600' }}
            >
              {child?.props?.label}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              background: '#FFF',
              padding: 1,
              borderBottom:
                normalizedChildren.length - 1 === index
                  ? '1px solid rgba(5, 5, 5, 0.06)'
                  : '0px',
              borderTop: '1px solid rgba(5, 5, 5, 0.06)',
              borderLeft: '1px solid rgba(5, 5, 5, 0.06)',
              borderRight: '1px solid rgba(5, 5, 5, 0.06)',
              borderTopRightRadius: index === 0 ? '8px' : '0px',
              borderBottomRightRadius:
                normalizedChildren.length - 1 === index ? '8px' : '0',
            }}
          >
            <Typography variant='body1'>{child?.props?.children}</Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default Description;

export const DescriptionChild = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return <>{children}</>;
};
