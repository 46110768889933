import { Box, Button, TextField } from '@mui/material';
import ModalsWrapper from '../../../Components/Modals/ModalsWrapper';
import { IAllTicket } from '../type/type';
import { useEffect, useState } from 'react';
import UpdateDataApi from '../../../Common/FetchData/UpdateDataApi';
const EditTicket = ({
  data,
  refetch,
}: {
  data: IAllTicket;
  refetch: () => Promise<void>;
}) => {
  const [open, setOpen] = useState(false);
  const { loading, updateData, success } = UpdateDataApi<any>(
    `/api/atab/user-member/ticket/${data.id}`
  );
  const [formData, setFormData] = useState({
    type: data.type,
    details: data.details,
  });

  // Function to handle input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    updateData(formData);
    console.log('Form Data:', formData);
  };

  useEffect(() => {
    if (success) {
      refetch();
      setOpen(false);
    }
  }, [success]);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Edit</Button>
      <ModalsWrapper
        modalData={{ title: 'Create Ticket' }}
        showModal={open}
        setShowModal={setOpen}
      >
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{ width: '100%', maxWidth: 500, margin: 'auto', padding: 2 }}
        >
          {/* Title Input */}
          <TextField
            size='small'
            fullWidth
            label='Title'
            variant='outlined'
            name='type'
            value={formData.type}
            onChange={handleChange}
            margin='normal'
            required
          />

          {/* Details (Textarea) */}
          <TextField
            fullWidth
            defaultValue={data.details}
            label='Details'
            variant='outlined'
            name='details'
            value={formData.details}
            onChange={handleChange}
            multiline
            rows={4}
            margin='normal'
            required
          />

          {/* Submit Button */}
          <Button type='submit' variant='contained' fullWidth sx={{ mt: 2 }}>
            {loading ? 'loading' : ' Submit'}
          </Button>
        </Box>
      </ModalsWrapper>
    </>
  );
};

export default EditTicket;
