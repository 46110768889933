import { Box, Grid, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { useCompanyLicenseUpdate } from '../../../rest/useCompanyInfo';

const AddTradeLicenseInfo = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useCompanyLicenseUpdate();

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    mutate({ input: formData });
  };
  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2} noValidate>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <label htmlFor=''>
                  Trade license number <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  placeholder='Enter trade license number '
                  type='text'
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('company_trade_license', {
                    required: ' Trade license number is required',
                  })}
                  error={
                    errors.company_trade_license &&
                    Boolean(errors.company_trade_license)
                  }
                  helperText={
                    Boolean(errors?.company_trade_license?.message)
                      ? `${errors?.company_trade_license?.message}`
                      : ''
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <label htmlFor=''>
                  Trade license expiry date{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='date'
                  type='date'
                  required
                  {...register('company_trade_license_expiry_date', {
                    required: ' Trade license expiry date is required',
                  })}
                  error={
                    errors.company_trade_license_expiry_date &&
                    Boolean(errors.company_trade_license_expiry_date)
                  }
                  helperText={
                    Boolean(errors?.company_trade_license_expiry_date?.message)
                      ? `${errors?.company_trade_license_expiry_date?.message}`
                      : ''
                  }
                  sx={{ width: '100%', pb: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <label htmlFor=''>
                  Upload trade License (pdf)
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  required
                  fullWidth
                  inputProps={{
                    accept: 'application/pdf',
                  }}
                  sx={{ pb: 2 }}
                  {...register('company_trade_license_file', {
                    required: 'Trade license file is required',
                  })}
                  error={
                    errors.company_trade_license_file &&
                    Boolean(errors.company_trade_license_file)
                  }
                  helperText={
                    Boolean(errors?.company_trade_license_file?.message)
                      ? `${errors?.company_trade_license_file?.message}`
                      : ''
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {
          <Box sx={{ mt: 2, textAlign: 'end', mb: 1 }}>
            {isLoading ? (
              <Button
                disabled
                variant='contained'
                disableElevation
                className='add-remove-button'
                size='small'
              >
                Loading...
              </Button>
            ) : (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className='add-remove-button'
                size='small'
              >
                Save
              </Button>
            )}
          </Box>
        }
      </Box>
    </>
  );
};

export default AddTradeLicenseInfo;
