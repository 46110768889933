export function removeUndefinedAndNull(obj: any) {
  const newObj: any = {};

  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null && obj[key].length !== 0) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}

export function getTokenDuration(token: string): any | null {
  try {
    // Decode the payload
    const payloadBase64 = token.split('.')[1];
    const payloadJson = atob(payloadBase64);
    const payload = JSON.parse(payloadJson);

    // Ensure 'iat' (issued at) and 'exp' (expiration) are present in the token
    if (!payload.iat || !payload.exp) {
      return null;
    }

    const totalTime = payload.exp - payload.iat; // Total valid time in seconds
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const remainingTime = payload.exp - currentTime; // Remaining time in seconds

    // If the token is expired
    if (remainingTime <= 0) {
      return {
        totalMinutes: Math.floor(totalTime / 60),
        totalSeconds: totalTime % 60,
        remainingMinutes: 0,
        remainingSeconds: 0,
      };
    }

    // Calculate total time in minutes and seconds
    const totalMinutes = Math.floor(totalTime / 60);
    const totalSeconds = totalTime % 60;

    // Calculate remaining time in minutes and seconds
    const remainingMinutes = Math.floor(remainingTime / 60);
    const remainingSeconds = remainingTime % 60;

    return {
      totalMinutes,
      totalSeconds,
      remainingMinutes,
      remainingSeconds,
    };
  } catch (error) {
    console.error('Invalid token format or parsing error:', error);
    return null;
  }
}
