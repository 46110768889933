import './App.css';
import '../src/Styles/Login_reg.css';
import '../src/Styles/responsive.css';
import '../src/Styles/Dashboard.css';
import '../src/Styles/Conversation.css';
import '../src/Styles/Message.css';
import '../src/Styles/Notification.css';
import '../src/Styles/Common.css';

import { RouterProvider } from 'react-router-dom';
import { routers } from './Utils/Routers';
import { Toaster } from 'react-hot-toast';
import ThemeProvider from './Utils/theme/ThemeProvider';
// Create a client
function App() {
  const router = routers;

  return (
    <>
      <ThemeProvider>
        <Toaster />
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  );
}

export default App;
