import { Box, Typography, Grid, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { ICompanyBusinessLicense } from "../../../rest/types";
import { imgUrl } from "../../../Helpers/utils/Constant";
import Description, { DescriptionChild } from "../../../Common/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PdfAndImageView from "../../../Common/PdfAndImageView";
type IProps = {
  companyBusinessLicense: ICompanyBusinessLicense;
};

const CardBusinessLicense = ({ companyBusinessLicense }: IProps) => {
  return (
    <Box p={2} maxWidth={1000} margin={"0 auto"}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Business License Information
          </Typography>
        </Grid>

        <Description>
          <DescriptionChild label="Hajj">
            <FormControlLabel
              sx={{ height: "10px" }}
              checked={companyBusinessLicense.hajj_license !== 0 ? true : false}
              disabled
              control={<Checkbox />}
              label="Hajj"
            />
          </DescriptionChild>
          <DescriptionChild label="Umrah">
            <FormControlLabel
              sx={{ height: "10px" }}
              checked={
                companyBusinessLicense.umrah_license !== 0 ? true : false
              }
              disabled
              control={<Checkbox />}
              label="Umrah"
            />
          </DescriptionChild>

          <DescriptionChild label="Recruiting">
            <FormControlLabel
              sx={{ height: "10px" }}
              checked={
                companyBusinessLicense.recruting_license !== 0 ? true : false
              }
              disabled
              control={<Checkbox />}
              label="Recruiting"
            />
          </DescriptionChild>

          {companyBusinessLicense.hajj_license_file && (
            <DescriptionChild label="Download Member organization Hajj license">
              <PdfAndImageView
                file={companyBusinessLicense.hajj_license_file}
                color="#00e"
              />
            </DescriptionChild>
          )}
          {companyBusinessLicense.umrah_license_file && (
            <DescriptionChild label="Download Member organization Umrah license">
              <PdfAndImageView
                file={companyBusinessLicense.umrah_license_file}
                color="#00e"
              />
            </DescriptionChild>
          )}
          {companyBusinessLicense.recruting_license_file && (
            <DescriptionChild label="Download Member organization Recruiting license">
              <PdfAndImageView
                file={companyBusinessLicense.recruting_license_file}
                color="#00e"
              />
            </DescriptionChild>
          )}
        </Description>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Other Certificates
          </Typography>
        </Grid>

        <Description>
          <DescriptionChild label="IATA Certificate">
            <FormControlLabel
              sx={{ height: "10px" }}
              checked={companyBusinessLicense.iata_agent !== 0 ? true : false}
              disabled
              control={<Checkbox />}
              label="IATA Certificate"
            />
          </DescriptionChild>
          <DescriptionChild label="BAIRA Certificate">
            <FormControlLabel
              sx={{ height: "10px" }}
              checked={companyBusinessLicense.baira !== 0 ? true : false}
              disabled
              control={<Checkbox />}
              label="BAIRA Certificate"
            />
          </DescriptionChild>

          <DescriptionChild label="TOAB Certificate">
            <FormControlLabel
              sx={{ height: "10px" }}
              checked={companyBusinessLicense.toab !== 0 ? true : false}
              disabled
              control={<Checkbox />}
              label="TOAB Certificate"
            />
          </DescriptionChild>
          <DescriptionChild label="TDAB Certificate">
            <FormControlLabel
              sx={{ height: "10px" }}
              checked={companyBusinessLicense.tdab !== 0 ? true : false}
              disabled
              control={<Checkbox />}
              label="TDAB Certificate"
            />
          </DescriptionChild>

          {companyBusinessLicense.iata_agent_file && (
            <DescriptionChild label="Download IATA Certificate">
              <PdfAndImageView
                file={companyBusinessLicense.iata_agent_file}
                color="#00e"
              />
            </DescriptionChild>
          )}
          {companyBusinessLicense.baira_file && (
            <DescriptionChild label="Download BAIRA Certificate">
              <PdfAndImageView
                file={companyBusinessLicense.baira_file}
                color="#00e"
              />
            </DescriptionChild>
          )}
          {companyBusinessLicense.toab_file && (
            <DescriptionChild label="Download TOAB Certificate">
              <PdfAndImageView
                file={companyBusinessLicense.toab_file}
                color="#00e"
              />
            </DescriptionChild>
          )}
          {companyBusinessLicense.tdab_file && (
            <DescriptionChild label="Download TDAB Certificate">
              <PdfAndImageView
                file={companyBusinessLicense.tdab_file}
                color="#00e"
              />
            </DescriptionChild>
          )}
        </Description>
      </Grid>
    </Box>
  );
};

export default CardBusinessLicense;
