export const url: string = 'https://haab.services';
//export const url: string = 'http://192.168.0.59:8000'; //srabon
//export const url: string = 'http://192.168.0.191:8000';

// export const imgUrl: string =
// 	'https://atab.blob.core.windows.net/atab-storage/uploads';
export const imgUrl: string =
  'https://m360ict.s3.ap-south-1.amazonaws.com/haab-storage';

//======== Auth context helper ========//
export const SET_SIGNUP_DATA = 'SET_SIGNUP_DATA';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_FAILED = 'AUTH_USER_FAILED';
export const UPDATE_AUTH_USER_INFO = 'UPDATE_AUTH_USER_INFO';
// notification
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';

// message
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
