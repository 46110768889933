import {
  Button,
  Checkbox,
  CssBaseline,
  TextField,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import { FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { muiStyles } from '../../Utils/Styles';
import Lottie from 'react-lottie';
import axios from 'axios';
import { LottiedefaultOptions2 } from '../../Utils/Comibined';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILED,
  url,
} from '../../Helpers/utils/Constant';
import { showToast } from '../../Toaster/Toaster';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { IFormInput } from '../../Types/commonTypes';
import { setCookie } from 'nookies';
import LoginVerificationModal from './LoginVerificationModal';
import TokenAndEmailFetchData from '../../Common/FetchData/TokenAndEmailFetchData';

const useStyles = muiStyles;
const Login = () => {
  const [searchParams] = useSearchParams();
  const ownerEmail = searchParams.get('email');
  const ownerToken = searchParams.get('admin_data');
  /* this api use for super admin login from admin panel */
  const { postData } = TokenAndEmailFetchData(
    '/api/atab/user-member/admin/login/member'
  );

  const classes = useStyles();
  const { dispatch, isLoading, setIsLoading } = useAuthContext();
  const { register, handleSubmit } = useForm<IFormInput>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [inputData, setInputData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const onSubmit: SubmitHandler<IFormInput> = async (body) => {
    const { email } = body;

    try {
      setIsLoading?.(true);
      const { data } = await axios.post(`${url}/api/auth/member/login`, body);

      if (data.success) {
        //showToast(`Successfully login`, 'success');
        localStorage.setItem('auth', JSON.stringify(data.data));
        dispatch?.({
          type: AUTH_USER_SUCCESS,
          payload: data.data,
        });

        setIsLoading?.(false);
        setCookie(null, 'haab_ua', data.token);

        if (data?.data?.is_first_login === 1) {
          navigate('/public/change-password');
          setIsLoading?.(false);
          return;
        }

        if (data?.data?.user_member_account_status === 'draft') {
          navigate('/draft');
          setIsLoading?.(false);
          return;
        }

        try {
          setLoading(true);
          axios
            .post(`${url}/api/atab/common/send-email-otp`, {
              email: email,
              type: 'login_member',
            })
            .then(function (res) {
              if (res.data.success) {
                setOpen(true);
                showToast(`We sent verification code to ${email}`, 'success');
                setInputData({
                  ...body,
                  email,
                });
                setLoading(false);
              }
            })
            .catch(function (err) {
              showToast(`${err.response.data.message}`, 'error');
              setLoading(false);
            });
        } catch (error) {}
      }
    } catch (err: any) {
      showToast(
        `Log in failed! \n ${err.response?.data?.message as string}`,
        'error'
      );

      dispatch?.({ type: AUTH_USER_FAILED });
    } finally {
      setIsLoading?.(false);
    }
  };

  const defaultOptions2 = LottiedefaultOptions2;

  useEffect(() => {
    /* ---------------Super Admin can login with out password------------- */
    if (ownerEmail && ownerToken) {
      postData({ email: ownerEmail }, ownerToken);
    }
  }, [ownerEmail, ownerToken]);
  return (
    <>
      <div className='login-page'>
        <div className='login-wrapper'>
          <div>
            <div className='login-left-col'>
              <Lottie options={defaultOptions2} />
            </div>
          </div>
          <div>
            <div className='login-right-col'>
              <Container maxWidth='sm' component='main'>
                <CssBaseline />
                <div className='right-top-div '>
                  <img src='/logo.png' alt='' />

                  <div className='pt-3'>
                    <Typography
                      gutterBottom
                      sx={{
                        fontVariant: 'small-caps',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: '#31805F',
                        fontSize: '30px',
                      }}
                    >
                      Member login
                    </Typography>
                  </div>
                </div>
                <Box>
                  <Box component='form' onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='name'
                      label='Enter your email address'
                      placeholder='Enter your email address'
                      {...register('email')}
                      type='text'
                      autoFocus
                      variant='standard'
                    />
                    <TextField
                      margin='normal'
                      required
                      sx={{ pt: 1 }}
                      fullWidth
                      id='password'
                      label='Enter your password'
                      placeholder='Enter your password'
                      {...register('password')}
                      type={`${showPassword ? 'text' : 'password'}`}
                      autoFocus
                      variant='standard'
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => setShowPassword(!showPassword)}
                          value={showPassword}
                          color='primary'
                        />
                      }
                      label='Show Password'
                    />
                    {isLoading || loading ? (
                      <Button
                        disabled
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2, background: '#1113a2' }}
                      >
                        Loading...
                      </Button>
                    ) : (
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        disableElevation
                        sx={{
                          mt: 3,
                          mb: 2,
                          py: 1,
                          background: '#31805F',
                        }}
                        className={classes.signInButton}
                      >
                        Sign In
                      </Button>
                    )}

                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <Link to='/forget-password'>
                          <Typography className='text-main '>
                            Forgot password?
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Link to='/registration'>
                          <Typography className='text-main '>
                            {"Don't have an account? Sign Up"}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <LoginVerificationModal
        open={open}
        handleClose={() => setOpen(false)}
        inputData={inputData}
        setLoading={setLoading}
      />
    </>
  );
};

export default Login;
