import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Button,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { imgUrl } from '../../../Helpers/utils/Constant';
import { showToast } from '../../../Toaster/Toaster';
import { ICompanyBusinessLicense } from '../../../rest/types';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateCompanyBusinessLicenseMutation } from '../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';

interface IProps {
  memberId: number;
  companyBusinessLicense: ICompanyBusinessLicense;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyBusinessLicense = ({
  companyBusinessLicense,
  setToggle,
}: IProps) => {
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const { register, handleSubmit } = useForm();
  const [businessLicenses, setBusinessLicense] = useState<any>({
    hajj_license: companyBusinessLicense.hajj_license,
    umrah_license: companyBusinessLicense.umrah_license,
    recruting_license: companyBusinessLicense.recruting_license,
    iata_agent: companyBusinessLicense.iata_agent,
    toab: companyBusinessLicense.toab,
    tdab: companyBusinessLicense.tdab,
    baira: companyBusinessLicense.baira,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyBusinessLicenseMutation(data);
    },
    onSuccess: () => {
      showToast('successfully updated', 'success');
      setToggle(false);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_BUSINESS_LICENSE],
      });
    },
  });
  const onSubmit = async (inputsData: any) => {
    inputsData = { ...inputsData, ...businessLicenses };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    mutation.mutate(formData);
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid
        container
        spacing={3}
        p={2}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Grid item xs={12} md={6}>
          <Typography variant='h6' gutterBottom>
            Business License
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              textAlign: 'center',
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.hajj_license === 1 ? true : false
                    }
                  />
                }
                label='Hajj'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      hajj_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      hajj_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.umrah_license === 1 ? true : false
                    }
                  />
                }
                label='Umrah'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      umrah_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      umrah_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.recruting_license === 1 ? true : false
                    }
                  />
                }
                label='Recruiting'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      recruting_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      recruting_license: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>

          <Box>
            {businessLicenses.hajj_license ? (
              <Box>
                <label htmlFor=''>
                  Member organization Hajj license{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('hajj_license_file')}
                />
              </Box>
            ) : (
              ''
            )}
            {businessLicenses.umrah_license ? (
              <Box>
                <label htmlFor=''>
                  Member organization Umrah license{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('umrah_license_file')}
                />
              </Box>
            ) : (
              ''
            )}
            {businessLicenses.recruting_license ? (
              <Box>
                <label htmlFor=''>
                  Member organization Recruiting license
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('recruting_license_file')}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Typography variant='h6' gutterBottom>
          Other Certificate
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={
                  businessLicenses.iata_agent === 1 ? true : false
                }
              />
            }
            label='IATA Certificate'
            onClick={(e: any) => {
              if (e.target.checked) {
                setBusinessLicense({
                  ...businessLicenses,
                  iata_agent: 1,
                });
              } else {
                setBusinessLicense({
                  ...businessLicenses,
                  iata_agent: 0,
                });
              }
            }}
          />
        </Grid>
        {businessLicenses.iata_agent ? (
          <Grid item xs={4}>
            <label htmlFor=''>
              IATA Certificate
              <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              variant='outlined'
              type='file'
              fullWidth
              sx={{ pb: 2 }}
              {...register('iata_agent_file')}
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={businessLicenses.baira === 1 ? true : false}
              />
            }
            label='IATA Certificate'
            onClick={(e: any) => {
              if (e.target.checked) {
                setBusinessLicense({
                  ...businessLicenses,
                  baira: 1,
                });
              } else {
                setBusinessLicense({
                  ...businessLicenses,
                  baira: 0,
                });
              }
            }}
          />
        </Grid>
        {businessLicenses.baira ? (
          <Grid item xs={4}>
            <label htmlFor=''>
              BAIRA Certificate
              <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              variant='outlined'
              type='file'
              fullWidth
              sx={{ pb: 2 }}
              {...register('baira_file')}
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={businessLicenses.tdab === 1 ? true : false}
              />
            }
            label='TDAB Certificate'
            onClick={(e: any) => {
              if (e.target.checked) {
                setBusinessLicense({
                  ...businessLicenses,
                  tdab: 1,
                });
              } else {
                setBusinessLicense({
                  ...businessLicenses,
                  tdab: 0,
                });
              }
            }}
          />
        </Grid>
        {businessLicenses.tdab ? (
          <Grid item xs={4}>
            <label htmlFor=''>
              TDAB Certificate
              <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              variant='outlined'
              type='file'
              fullWidth
              sx={{ pb: 2 }}
              {...register('tdab_file')}
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={businessLicenses.toab === 1 ? true : false}
              />
            }
            label='TOAB Certificate'
            onClick={(e: any) => {
              if (e.target.checked) {
                setBusinessLicense({
                  ...businessLicenses,
                  toab: 1,
                });
              } else {
                setBusinessLicense({
                  ...businessLicenses,
                  toab: 0,
                });
              }
            }}
          />
        </Grid>
        {businessLicenses.toab ? (
          <Grid item xs={4}>
            <label htmlFor=''>
              TDAB Certificate
              <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              variant='outlined'
              type='file'
              fullWidth
              sx={{ pb: 2 }}
              {...register('toab_file')}
            />
          </Grid>
        ) : (
          ''
        )}
      </Grid>

      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Loading..
          </Button>
        ) : adminId.user_member_account_status !== 'active' ? (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            Save
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default CompanyBusinessLicense;
