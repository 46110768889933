import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import TableLoader from '../../../Components/Spinner/TableLoader';
import moment from 'moment';
import getStatusChip from '../../../Common/getStatusChip';
import TableNoDataMessage from '../../../Components/TableMessage/TableNoDataMessage';
import GetDataApi from '../../../Common/FetchData/GetDataApi';
import { ITransactionHistory } from '../type/payment_type';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

const TransactionHistory = () => {
  const [filter, setFilter] = useState<{
    page: string | number;
    from_date: string;
    to_date: string;
    limit: string | number;
  }>({
    from_date: '',
    to_date: '',
    page: 1,
    limit: 25,
  });
  const { response, loading, total, getData } = GetDataApi<
    ITransactionHistory[]
  >(
    `/api/atab/member-payment/transaction-history?from_date=${filter.from_date}&to_date=${filter.to_date}&limit=${filter.limit}&page=${filter.page}`
  );
  const handleSubmit = () => {
    getData();
  };
  useEffect(() => {
    if (filter.limit && filter.page) {
      getData();
    }
  }, [filter.limit, filter.page]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  useEffect(() => {
    const debit = response
      ?.filter((transaction) => transaction.type === 'OUT')
      .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0);

    const credit = response
      ?.filter((transaction) => transaction.type === 'IN')
      .reduce((sum, transaction) => sum + parseFloat(transaction.amount), 0);
    if (debit && credit) {
      setTotalDebit(debit);
      setTotalCredit(credit);
    }
  }, [response]);
  return (
    <Card sx={{ mt: 5 }}>
      <CardHeader
        title={
          <Typography sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}>
            Transaction History{' '}
          </Typography>
        }
        action={
          <Box>
            <Grid container alignItems='center'>
              <Grid item xs={4}>
                <DatePicker
                  selected={filter.from_date as any}
                  onChange={(date) =>
                    setFilter({
                      ...filter,
                      from_date: moment(date).format('YYYY-MM-DD'),
                    })
                  }
                  customInput={
                    <TextField
                      label='Start Date'
                      size='small'
                      sx={{ width: '100%' }}
                    />
                  }
                />
              </Grid>
              <Grid
                item
                xs={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Box>
                  <SyncAltIcon />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <DatePicker
                  selected={filter?.to_date as any}
                  onChange={(date) =>
                    setFilter({
                      ...filter,
                      to_date: moment(date).format('YYYY-MM-DD'),
                    })
                  }
                  customInput={
                    <TextField
                      label='End Date'
                      size='small'
                      sx={{ width: '100%' }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={2} sx={{ ml: 1 }}>
                <Button
                  sx={{ paddingBottom: '5px', paddingTop: '5px' }}
                  variant='outlined'
                  onClick={() => handleSubmit()}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </Box>
        }
      />

      <Box>
        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell size='small' align='center'>
                  SL
                </TableCell>
                <TableCell align='center' size='small'>
                  Details
                </TableCell>
                <TableCell align='center' size='small'>
                  Date
                </TableCell>
                <TableCell align='center' size='small'>
                  Account Number
                </TableCell>
                <TableCell align='center' size='small'>
                  Voucher No
                </TableCell>
                <TableCell align='center' size='small'>
                  Transaction ID
                </TableCell>
                <TableCell align='center' size='small'>
                  Debit Amount
                </TableCell>
                <TableCell align='center' size='small'>
                  Credit Amount
                </TableCell>
                <TableCell align='center' size='small'>
                  Remaining Balance
                </TableCell>
                <TableCell align='center' size='small'>
                  Payment Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {response?.length ? (
                    <>
                      {response?.map((sPayment, index) => (
                        <TableRow
                          key={sPayment.id}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align='center' size='small'>
                            {index + 1}
                          </TableCell>
                          <TableCell width={300} align='center' size='small'>
                            {sPayment.details}
                          </TableCell>

                          <TableCell align='center' size='small'>
                            {moment(sPayment.ledger_date).format('DD-MMM-YYYY')}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {Number(sPayment.account_number)
                              ? sPayment.account_number
                              : ''}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {Number(sPayment.account_number)
                              ? ''
                              : sPayment.voucher_no}
                          </TableCell>

                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {sPayment.trx_id}
                          </TableCell>
                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {sPayment.type === 'IN' ? sPayment.amount : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {sPayment.type === 'OUT' ? sPayment.amount : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {sPayment.type === 'OUT' ? sPayment.amount : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              cursor: 'pointer',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            {getStatusChip(sPayment.status)}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow sx={{ background: '#FAF7F0' }}>
                        <TableCell colSpan={6} align='center'>
                          <Typography variant='h4' gutterBottom>
                            Total
                          </Typography>
                        </TableCell>
                        <TableCell align='center'>{totalDebit}</TableCell>
                        <TableCell align='center'>{totalCredit}</TableCell>
                        <TableCell align='center'></TableCell>
                        <TableCell align='center'></TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <TableNoDataMessage />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2} display={'flex'} justifyContent={'start'}>
          <TablePagination
            component='div'
            count={total as number}
            onPageChange={(_event: any, newPage: number) =>
              setFilter({ ...filter, page: newPage + 1 })
            }
            onRowsPerPageChange={(event) =>
              setFilter({ ...filter, limit: event.target.value })
            }
            page={Number(filter.page) && Number(filter.page) - 1}
            rowsPerPage={Number(filter.limit)}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default TransactionHistory;
