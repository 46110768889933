import { useState } from 'react';
import { HttpClient } from '../../rest/utils/http';
import { showToast } from '../../Toaster/Toaster';

interface IResponse<T> {
  success: boolean;
  data: T;
  message?: string;
}

const PostDataApis = <T,>(url: string) => {
  const [response, setResponse] = useState<T | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const postData = async (payload: any) => {
    setError(null);

    try {
      setLoading(true);
      const res: IResponse<T> = await HttpClient.post(url, payload);
      if (res.success) {
        setSuccess(true);
        setResponse(res.data);
        showToast('Successfully created', 'success');
      } else {
        setSuccess(false);
        setError('something went wrong');
        showToast('something went wrong', 'error');
      }
    } catch (err: any) {
      setSuccess(false);
      setError(err?.response?.data?.message);
      showToast(err?.response?.data?.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, postData, success };
};

export default PostDataApis;
