import { FC } from 'react';
import PropTypes from 'prop-types';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Typography, Button, Grid } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
interface PageTitleProps {
  heading?: string;
  subHeading?: string;
  actionButtonTitle?: string;
  setAction?: any;
}

const PageTitle: FC<PageTitleProps> = ({
  heading = '',
  subHeading,
  setAction,
  actionButtonTitle,
  ...rest
}) => {
  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      {...rest}
    >
      <Grid item>
        <Typography
          sx={{ fontSize: 17, fontWeight: 'bold', ml: 1 }}
          gutterBottom
        >
          {heading}
        </Typography>
        {subHeading && <Typography>{subHeading}</Typography>}
      </Grid>
      {actionButtonTitle && (
        <Grid item>
          <Button
            size='small'
            onClick={() => setAction(true)}
            sx={{ mt: { xs: 2, md: 0 } }}
            variant='contained'
            startIcon={
              actionButtonTitle === 'Update request' ? (
                <ModeEditOutlineOutlinedIcon fontSize='small' />
              ) : (
                <AddTwoToneIcon fontSize='small' />
              )
            }
          >
            {actionButtonTitle}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

PageTitle.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
};

export default PageTitle;
