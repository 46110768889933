import React, { useState, useRef, useEffect } from 'react';
import {
  Divider,
  List,
  ListItem,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Popover,
  Badge,
  ListItemButton,
} from '@mui/material';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import moment from 'moment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import { FETCH_NOTIFICATION, url } from '../../Helpers/utils/Constant';
import { Link } from 'react-router-dom';

const Notifications = () => {
  const { notification, user, dispatch } = useAuthContext();
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  useEffect(() => {
    // setLoading(true);
    try {
      if (!notification.notification.length) {
        (async () => {
          const { data } = await axios.get(
            `${url}/api/notification/member?user_member_id=${adminId?.user_member_id}`
          );
          if (data.success) {
            let count = 0;
            data.data.forEach((item: any) => {
              if (!item.read_status) {
                count++;
              }
            });
            dispatch?.({
              type: FETCH_NOTIFICATION,
              payload: { notification: data.data, count },
            });
            // setLoading(false);
          } else {
            // setLoading(false);
          }
        })();
      } else {
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
    }
  }, [user.user_member_id!, dispatch, notification?.notification.length]);
  return (
    <>
      <Tooltip arrow title='Notifications'>
        <IconButton color='primary' ref={ref} onClick={() => setOpen(true)}>
          <Badge color='error' badgeContent={notification?.count.toString()}>
            <NotificationsIcon sx={{ fontSize: '30px', color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        sx={{ mt: 5, height: '500px', width: '650px' }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{ p: 2 }}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h5'>Notifications</Typography>
          <Link to='/notification'>
            <Typography
              variant='h5'
              // onClick={clearData}
              sx={{ cursor: 'pointer' }}
            >
              View all
            </Typography>
          </Link>
        </Box>
        <Divider sx={{ mx: 3 }} />
        <List sx={{ minWidth: 350 }}>
          {notification?.notification?.length ? (
            <>
              {notification?.notification?.map((noti: any) => {
                return (
                  <ListItem disablePadding key={noti.notification_id}>
                    <Link to='/notification'>
                      <ListItemButton
                      // onClick={() => {
                      //   readNoti(
                      //     noti.notification_id,
                      //     noti.read_status,
                      //     noti.notification_type,
                      //     noti.related_id
                      //   );
                      // }}
                      >
                        <Box>
                          <Box
                            display={'flex'}
                            justifyItems={'center'}
                            alignItems={'baseline'}
                            columnGap={1}
                          >
                            <Box
                              sx={{
                                height: '9px',
                                width: '9px',
                                background: '#00984A',
                                borderRadius: '50%',
                              }}
                            ></Box>{' '}
                            <Typography
                              className={
                                noti.read_status
                                  ? 'notification-read'
                                  : 'notification-unread'
                              }
                              color={'GrayText'}
                              fontWeight={600}
                            >
                              {noti.message}
                            </Typography>
                          </Box>

                          <Typography
                            className={
                              noti.read_status
                                ? 'notification-read'
                                : 'notification-unread'
                            }
                            sx={{
                              textTransform: 'none',
                              pt: 0.5,
                              color: 'GrayText',
                              fontWeight: 400,
                              marginLeft: 2,
                            }}
                          >
                            {moment(noti.created_at).fromNow()}
                          </Typography>
                        </Box>
                      </ListItemButton>
                    </Link>
                  </ListItem>
                );
              })}
            </>
          ) : (
            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              No data found
            </Typography>
          )}
        </List>
      </Popover>
    </>
  );
};

export default Notifications;
