import { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Grid, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { useRenewalMutation } from '../../rest/useFetchRenewalApplication';

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
  setReload: Function;
};

const CreateRenewalApplication = ({ handelClose, setReload }: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const { createData, data, loading } = useRenewalMutation();
  useEffect(() => {
    if (data.success) {
      handelClose(false);
      setReload();
    }
  }, [data.success, handelClose, setReload]);

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === 'object') {
        formData.append(item, data[item][0]);
      }
    });
    formData.append(
      'renewal_application_member_id',
      user.user_member_id!?.toString()
    );
    createData(formData);
  };

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2} maxWidth={'sm'}>
          <Grid xs={12} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Civil Aviation Certificate Bangla and English in one file (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('renewal_application_updated_civil_aviation_file')}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Trade License (PDF)'
              required
              id='outlined-required'
              type='file'
              inputProps={{
                accept: 'application/pdf',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              {...register('renewal_application_updated_trade_license_file')}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='TAX Return (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('renewal_application_tax_return_certificate_file')}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Previous HAAB Certificate (PDF)'
              required
              id='outlined-required'
              type='file'
              inputProps={{
                accept: 'application/pdf',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              {...register('renewal_application_prev_atab_certificate_file')}
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          {!loading ? (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              size='small'
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator='Loading...'
              variant='contained'
              fullWidth
              disabled
              size='small'
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateRenewalApplication;
