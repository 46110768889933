import { Box, CardContent, Grid } from '@mui/material';
import PageLoader from '../../../Components/Spinner/PageLoader';
import moment from 'moment';
import { ICompanyDetails } from '../../../rest/types';
import Description, { DescriptionChild } from '../../../Common/Description';
import PdfAndImageView from '../../../Common/PdfAndImageView';
type IProps = {
  data: ICompanyDetails;
  loading: boolean;
};

const CardCompanyInformation = ({ data, loading }: IProps) => {
  return (
    <>
      {!loading ? (
        <CardContent>
          <Grid container spacing={1}>
            <Grid item sm={12} md={6}>
              <Box>
                <Description>
                  <DescriptionChild label='Agency Name'>
                    {data.member_company_name}
                  </DescriptionChild>
                  <DescriptionChild label='Agency Type'>
                    {data.member_company_type}
                  </DescriptionChild>
                  <DescriptionChild label='Agency Stablish Date'>
                    {data.member_company_stablished_date &&
                      moment(data.member_company_stablished_date).format(
                        'DD-MMM-YYYY'
                      )}
                  </DescriptionChild>
                  <DescriptionChild label='Email'>
                    {data.email}
                  </DescriptionChild>

                  {data?.phones?.length &&
                    data?.phones?.map((phone, index) => (
                      <DescriptionChild
                        key={index}
                        label={`Agency Phone${index + 1}`}
                      >
                        {phone}
                      </DescriptionChild>
                    ))}

                  <DescriptionChild label='Cell Phone'>
                    {data.cellphone}
                  </DescriptionChild>

                  {data.member_company_verified_date && (
                    <DescriptionChild label='Agency verified Date'>
                      {data.member_company_verified_date}
                    </DescriptionChild>
                  )}

                  {data?.member_company_website && (
                    <DescriptionChild label='Agency website'>
                      <a
                        href={`${
                          data.member_company_website.startsWith('http') ||
                          data.member_company_website.startsWith('www')
                            ? data.member_company_website
                            : `http://${data.member_company_website}`
                        }`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {data.member_company_website}
                      </a>
                    </DescriptionChild>
                  )}
                  {data.member_company_logo && (
                    <DescriptionChild label='Download Company logo'>
                      <PdfAndImageView
                        file={data.member_company_logo}
                        color='#00e'
                      />
                    </DescriptionChild>
                  )}
                  {/* {data.addressline2 && (
                    <DescriptionChild label='Addressline2'>
                      {data.addressline2}
                    </DescriptionChild>
                  )}
                  {data.addressline3 && (
                    <DescriptionChild label='Addressline3'>
                      {data.addressline3}
                    </DescriptionChild>
                  )} */}
                </Description>
              </Box>
            </Grid>
            <Grid item sm={12} md={6}>
              {' '}
              <Box>
                <Description>
                  <DescriptionChild label='Address'>
                    {data.addressline1}
                  </DescriptionChild>
                  <DescriptionChild label='Police station'>
                    {data.police_station}
                  </DescriptionChild>

                  <DescriptionChild label='Postal code'>
                    {data.postalcode}
                  </DescriptionChild>
                  {data.zone_name && (
                    <DescriptionChild label='Zone name'>
                      {data.zone_name}
                    </DescriptionChild>
                  )}
                  {data.division && (
                    <DescriptionChild label='Division name'>
                      {data.division}
                    </DescriptionChild>
                  )}
                  {data.district && (
                    <DescriptionChild label='District name'>
                      {data.district}
                    </DescriptionChild>
                  )}
                  {data.thana && (
                    <DescriptionChild label='Thana name'>
                      {data.thana}
                    </DescriptionChild>
                  )}
                  {data.area && (
                    <DescriptionChild label='Area name'>
                      {data.area}
                    </DescriptionChild>
                  )}
                </Description>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default CardCompanyInformation;
