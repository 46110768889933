import React, { useState } from 'react';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { Divider, Card, Box, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTin } from '../../../rest/useCompanyInfo';
import AddTin from './AddTin';
import UpdateTin from '../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateTin';
import CardTin from '../card-components/CardTin';

const Tin = () => {
  const { user } = useAuthContext();
  const { companyTin, isLoading } = useTin();
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Card>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <>
            {companyTin?.tin_number && (
              <Tooltip title='Update' sx={{ mr: 2 }}>
                <IconButton onClick={() => setToggle(!toggle)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        </Box>
        <Divider light />

        {toggle ? (
          <UpdateTin
            companyLicense={companyTin}
            memberId={user.user_member_id!}
            setToggle={setToggle}
          />
        ) : (
          <>
            {Object.keys(companyTin).length > 0 ? (
              <CardTin key={5} data={companyTin} loading={isLoading} />
            ) : (
              <AddTin />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default Tin;
