import { Dispatch, SetStateAction, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Divider,
  Autocomplete,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { useChangeOwnershipMutation } from '../../rest/useChangeOwnership ';
import { useCompanyOwnerQuery } from '../../rest/useCompanyInfo';
import { ICompanyOwnerList } from '../../rest/types';

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
};
const forLimited = ['Managing Director', 'Director', 'Chairman', 'CEO'];
const forPartnerShip = ['Managing Partner', 'CEO'];
const CreateOwnershipApplication = ({ handelClose }: IProps) => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm();
  const { isLoading, isSuccess, mutate } = useChangeOwnershipMutation();
  const { companyOwnerData } = useCompanyOwnerQuery();
  const mainOwnerId = companyOwnerData?.find(
    (item) => item.company_owner_is_contact_person === 1
  );

  useEffect(() => {
    if (isSuccess) {
      handelClose(false);
    }
  }, [isSuccess, handelClose]);
  const onSubmit = async (formDataInput: any) => {
    const { ownership_change_application_prev_owner, ...data } = formDataInput;
    console.log(data);
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === 'object') {
        formData.append(item, data[item][0]);
      } else {
        formData.append(item, data[item]);
      }
    });
    formData.append(
      'ownership_change_application_member_id',
      user.user_member_id!?.toString()
    );
    if (mainOwnerId?.company_owner_id) {
      formData.append(
        'ownership_change_application_prev_owner',
        String(mainOwnerId?.company_owner_id)
      );
    }

    console.table(Object.fromEntries(formData));
    mutate(formData);
  };
  const validateFileSize = (file: any, fileName: string) => {
    if (file && file[0]?.size > 2 * 1024 * 1024) {
      setError(fileName, {
        type: 'manual',
        message: 'File size should not exceed 2 MB.',
      });
      return false;
    }
    clearErrors(fileName);
    return true;
  };

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2}>
          <Grid xs={12} md={4} lg={4} item>
            <FormControl size='small' fullWidth>
              <InputLabel id='demo-simple-select-required-label'>
                Select a previous company owner *
              </InputLabel>
              <Select
                disabled
                value={mainOwnerId?.company_owner_id || ''}
                labelId='demo-simple-select-required-label'
                {...register('ownership_change_application_prev_owner')}
                id='demo-simple-select-required'
                required
                label='Select a previous company owner *'
              >
                {companyOwnerData?.length === 0 && (
                  <MenuItem disabled>Not found</MenuItem>
                )}
                {companyOwnerData?.map((company: ICompanyOwnerList) => (
                  <MenuItem value={company?.company_owner_id}>
                    {company?.company_owner_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Divider sx={{ m: 2, width: '98%' }}>New owner information</Divider>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='New company owner name'
              required
              id='outlined-required'
              type='text'
              {...register('company_owner_name')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='New Owner NID'
              id='outlined-required'
              type='text'
              {...register('company_owner_nid')}
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='New Owner Mobile Number'
              id='outlined-required'
              type='text'
              {...register('company_owner_phone')}
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='New Owner email'
              id='outlined-required'
              type='email'
              {...register('company_owner_email')}
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <Autocomplete
              freeSolo
              options={
                companyOwnerData[0]?.member_company_type === 'Limited'
                  ? forLimited
                  : forPartnerShip
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Enter Owner designation'
                  required
                  size='small'
                />
              )}
              onInputChange={(event, newValue) => {
                setValue('company_owner_designation', newValue);
              }}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='New Owner home address'
              id='outlined-required'
              type='text'
              {...register('company_owner_address')}
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='New Owner Photo (JPG/JPEG/PNG)'
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('company_owner_photo')}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='NID (Both Sides, PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('company_owner_nid_file')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='New Owner Bio-Data with Seal and Signature (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('company_owner_biodata')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='New Owner Passport (PDF)'
              id='outlined-required'
              type='file'
              inputProps={{
                accept: 'application/pdf',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              {...register('company_owner_passport')}
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='New Owner signature'
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('company_owner_signature')}
            />
          </Grid>
          <Divider sx={{ m: 2, width: '98%' }}>Supporting Document</Divider>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Updated Bangla and English Civil Aviation Certificate (PDF)'
              title='Updated Bangla and English Civil Aviation Certificate (PDF)'
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register(
                'ownership_change_application_civil_aviation_bn_en',
                {
                  validate: {
                    fileSize: (fileList) =>
                      validateFileSize(
                        fileList,
                        'ownership_change_application_civil_aviation_bn_en'
                      ),
                  },
                }
              )}
              error={!!errors.ownership_change_application_civil_aviation_bn_en}
              helperText={
                !!errors.ownership_change_application_civil_aviation_bn_en &&
                'File size should not exceed 2 MB.'
              }
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Updated TIN (PDF)'
              title='Updated TIN (PDF)'
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('ownership_change_application_tin', {
                validate: {
                  fileSize: (fileList) =>
                    validateFileSize(
                      fileList,
                      'ownership_change_application_tin'
                    ),
                },
              })}
              error={!!errors.ownership_change_application_tin}
              helperText={
                !!errors.ownership_change_application_tin &&
                'File size should not exceed 2 MB.'
              }
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Updated Trade License (PDF)'
              title='Updated Trade License (PDF)'
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('ownership_change_application_trade_license', {
                validate: {
                  fileSize: (fileList) =>
                    validateFileSize(
                      fileList,
                      'ownership_change_application_trade_license'
                    ),
                },
              })}
              error={!!errors.ownership_change_application_trade_license}
              helperText={
                !!errors.ownership_change_application_trade_license &&
                'File size should not exceed 2 MB.'
              }
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Updated Hajj License (Both side, PDF)'
              title='Updated Hajj License (Both side, PDF)'
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('ownership_change_application_hajj_license', {
                validate: {
                  fileSize: (fileList) =>
                    validateFileSize(
                      fileList,
                      'ownership_change_application_hajj_license'
                    ),
                },
              })}
              error={!!errors.ownership_change_application_hajj_license}
              helperText={
                !!errors.ownership_change_application_hajj_license &&
                'File size should not exceed 2 MB.'
              }
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Updated Ownership Deed (PDF)'
              title='Updated Ownership Deed (PDF)'
              id='outlined-required'
              type='file'
              inputProps={{
                accept: 'application/pdf',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              {...register(
                'ownership_change_application_ownership_change_deed',
                {
                  validate: {
                    fileSize: (fileList) =>
                      validateFileSize(
                        fileList,
                        'ownership_change_application_ownership_change_deed'
                      ),
                  },
                }
              )}
              error={
                !!errors.ownership_change_application_ownership_change_deed
              }
              helperText={
                !!errors.ownership_change_application_ownership_change_deed &&
                'File size should not exceed 2 MB.'
              }
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Ownership Change Forwarding Letter (PDF)'
              title='Ownership Change Forwarding Letter (PDF)'
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: 'application/pdf',
              }}
              {...register('ownership_change_application_forwarding_letter', {
                validate: {
                  fileSize: (fileList) =>
                    validateFileSize(
                      fileList,
                      'ownership_change_application_forwarding_letter'
                    ),
                },
              })}
              error={!!errors.ownership_change_application_forwarding_letter}
              helperText={
                !!errors.ownership_change_application_forwarding_letter &&
                'File size should not exceed 2 MB.'
              }
              required
            />
          </Grid>
        </Grid>
        <Box textAlign={'end'} mt={5}>
          {!isLoading ? (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              size='small'
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator='Loading...'
              variant='contained'
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateOwnershipApplication;
