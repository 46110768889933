import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { url } from '../../Helpers/utils/Constant';
import ShowComplainMessage from './ShowComplainMessage';
import socket from '../../Helpers/socket';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import PageLoader from '../Spinner/PageLoader';

export type IReplyies = {
  id: number;
  complain_id: number;
  content: string;
  created_by: number;
  type: string;
  created_at: string;
  name: string;
  photo: string;
  file: string;
};

export type IAllSupportConversations = {
  id: number;
  member_id: number;
  content: string;
  created_by: string;
  created_at: string;
  member_name: string;
  replies: IReplyies[];
};

function ShowAllComplain({
  selectedComplain,
  allSupportConversations,
  setAllSupportConversations,
  setSelectedComplain,
}: {
  selectedComplain: {
    complain_id: string;
    member_id: string;
  };
  setSelectedComplain: React.Dispatch<
    React.SetStateAction<{
      complain_id: string;
      member_id: string;
    }>
  >;
  allSupportConversations: IAllSupportConversations | undefined;
  setAllSupportConversations: React.Dispatch<
    React.SetStateAction<IAllSupportConversations | undefined>
  >;
}) {
  const [loading, setLoading] = useState<Boolean>(false);
  const datas = useAuthContext();
  useEffect(() => {
    const complain_loc_id = localStorage.getItem('complain_id');

    setSelectedComplain({
      complain_id: complain_loc_id as string,
      member_id: '',
    });
    //return () => localStorage.removeItem('complain_id');
  }, []);
  useEffect(() => {
    (async function () {
      if (selectedComplain.complain_id) {
        //when reload data and fetch data with selected data wise
        localStorage.setItem('complain_id', selectedComplain.complain_id);
        setLoading(true);
        try {
          const { data } = await axios.get(
            `${url}/api/atab/complain/${selectedComplain.complain_id}`
          );

          if (data?.success) {
            setAllSupportConversations(data?.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      }
    })();
  }, [selectedComplain.complain_id, datas]);

  useEffect(() => {
    socket.on('complain_reply', (data) => {
      if (data) {
        setAllSupportConversations((prevdata: any) => {
          const existingReply = prevdata?.replies?.find(
            (reply: IReplyies) => reply.id === data.id
          );

          if (!existingReply) {
            return {
              ...prevdata,
              replies: [...prevdata?.replies, data],
            };
          }

          return prevdata;
        });
      }
    });
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('complain_reply');
    };
  }, []);

  const setRef = useCallback((node: any) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);

  return (
    <>
      <Box height='100%' p={3}>
        {loading ? (
          <PageLoader />
        ) : (
          <>
            {allSupportConversations?.replies?.length ? (
              allSupportConversations?.replies?.map(
                (conversation: IReplyies, index: any) => {
                  const lastMessage =
                    allSupportConversations?.replies?.length - 1 === index;
                  return (
                    <Box
                      key={conversation.id}
                      ref={lastMessage ? setRef : null}
                    >
                      <ShowComplainMessage conversation={conversation} />
                    </Box>
                  );
                }
              )
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography variant='h4'>No Complain Found</Typography>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default ShowAllComplain;
