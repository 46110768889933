import React, { useContext, useReducer, useEffect } from 'react';
import reducer from '../../Reducers/AuthReducer/AuthReducer';
import { IauthContextState } from './AuthTyps';
import {
  AUTH_USER_FAILED,
  AUTH_USER_SUCCESS,
  SET_NEW_MESSAGE,
  url,
} from '../../Helpers/utils/Constant';
import * as jose from 'jose';
import { parseCookies, destroyCookie } from 'nookies';
import socket from '../../Helpers/socket';
import axios from 'axios';
import { useState } from 'react';

const adminId = JSON.parse(localStorage.getItem('auth') as any);
const initialState: IauthContextState = {
  user: adminId?.user_member_id
    ? adminId
    : {
        user_member_id: null,
        user_member_phone: '',
        user_member_company_name: '',
        user_member_account_status: '',
        user_member_representative_name: '',
        user_member_email: '',
        member_company_type: '',
        is_applied: 0,
        is_deleted: 0,
        is_first_login: 0,
        new_member: 0,
      },
  notification: { notification: [], count: 0 },
  messages: [],
};

const AuthContext = React.createContext(initialState);
const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const { haab_ua } = parseCookies();
      if (haab_ua) {
        try {
          setIsLoading(true);
          const { payload } = await jose.jwtVerify(
            haab_ua,
            new TextEncoder().encode(
              `MemAT+JN!S@ruqz7$2R&h4QU(*d8Ab%CncTjPgk^mLeDXKfMHvVWwFpABbeR`
            )
          );
          const { data } = await axios.get(
            `${url}/api/atab/user-member/get/a/member/${payload?.user_member_id}`
          );
          dispatch?.({ type: AUTH_USER_SUCCESS, payload: data.data });
          setIsLoading(false);
        } catch (err: any) {
          dispatch({ type: AUTH_USER_FAILED });
          localStorage.removeItem('auth');
          destroyCookie(null, 'haab_ua');
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (state.user?.user_member_id) {
      socket.auth = { id: state.user?.user_member_id, type: 'user_member' };
      if (!socket.connected) {
        socket.connect();
      }
      socket.on('new_message', (data) => {
        dispatch({ type: SET_NEW_MESSAGE, payload: data });
      });
    }
    return () => {
      socket.off('new_message');
      socket.disconnect();
    };
  }, [state.user]);
  return (
    <>
      <AuthContext.Provider
        value={{ ...state, dispatch, isLoading, setIsLoading }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthContextProvider, useAuthContext };
