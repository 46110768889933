import { useMutation, useQuery, useQueryClient } from 'react-query';
import { HttpClient } from '../../../rest/utils/http';
import {
  IPayment,
  IPaymentResponse,
} from '../../../Types/Payments/PaymentsTypes';
import { BankAccount } from '../Component/Bank/BankList';
import { GetResponseType } from '../../../rest/types';
import { API_PAYMENTS } from '../constant/paymentConstant';
import { showToast } from '../../../Toaster/Toaster';
import { IAddBankTypeFormData } from '../type/payment_type';

export function usePaymentQuery() {
  const { data, isLoading, error } = useQuery<IPaymentResponse, Error>(
    [API_PAYMENTS.PAYMENT],
    async () =>
      await HttpClient.get<IPaymentResponse>(`${API_PAYMENTS.PAYMENT}`),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allPayments: data?.data ?? ([] as IPayment[]),
    isLoading,
    error,
  };
}

export function useBankList() {
  return useQuery(
    [API_PAYMENTS.BANK_LIST],
    async () =>
      await HttpClient.get<GetResponseType<BankAccount[]>>(
        `${API_PAYMENTS.BANK_LIST}`
      ),
    {
      cacheTime: 10 * 60 * 1000, // Cache for 10 minutes
      staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false, // Prevents refetch when the component remounts
    }
  );
}

export const useBankPostMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: IAddBankTypeFormData) =>
      await HttpClient.post(API_PAYMENTS.BANK_POST, data),
    {
      onSuccess: () => {
        showToast('Successfully created', 'success');
        queryClient.invalidateQueries({
          queryKey: [API_PAYMENTS.BANK_LIST],
        });
      },
      onError: (error: any) => {
        showToast(
          `${error?.response?.data?.message}` || 'Something went wrong',
          'error'
        );
      },
    }
  );
};

export const useBankUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ data, id }: { data?: any; id?: number }) =>
      await HttpClient.patch(`${API_PAYMENTS.BANK_EDIT}/${id || ''}`, data),
    {
      onSuccess: () => {
        showToast('Successfully updated', 'success');
        queryClient.invalidateQueries({
          queryKey: [API_PAYMENTS.BANK_LIST],
        });
      },
      onError: (error: any) => {
        showToast(
          `${error?.response?.data?.message}` || 'Something went wrong',
          'error'
        );
      },
    }
  );
};
export const useBankDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }: { id: number }) => {
      // Ensure this returns a promise
      return await HttpClient.delete(`${API_PAYMENTS.BANK_DELETE}/${id}`);
    },
    {
      onSuccess: async (_, { id }) => {
        showToast('Successfully deleted', 'success');
        await queryClient.setQueryData(
          [API_PAYMENTS.BANK_LIST],
          (oldData: any) => {
            if (!oldData) return oldData;
            return {
              ...oldData,
              data: oldData?.data?.filter((item: any) => item.id !== id),
            };
          }
        );
      },
      onError: (error: any) => {
        showToast(
          `${error?.response?.data?.message || 'Something went wrong'}`,
          'error'
        );
      },
    }
  );
};
