import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Divider,
  Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { url } from '../../Helpers/utils/Constant';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import moment from 'moment';
import PageLoader from '../Spinner/PageLoader';

type GetAllComplain = {
  id: number;
  member_id: number;
  content: string;
  created_by: string;
  created_at: string;
  member_name: string;
};

type IProps = {
  setSelectedComplain: React.Dispatch<
    React.SetStateAction<{
      complain_id: string;
      member_id: string;
    }>
  >;
  selectedComplain: {
    complain_id: string;
    member_id: string;
  };
  setIsCompalinList: React.Dispatch<React.SetStateAction<number | undefined>>;
};
const ComplainCardDetails = ({
  selectedComplain,
  setSelectedComplain,
  setIsCompalinList,
}: IProps) => {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState<Boolean>(false);
  const [data, setData] = useState<GetAllComplain[]>([]);
  //const adminId = JSON.parse(localStorage.getItem('auth') as any);
  useEffect(() => {
    const getDownloadData = async () => {
      const token = localStorage.getItem('haab_admin');
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/atab/complain?member_id=${user?.user_member_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (data?.success) {
          setData(data?.data);
          setLoading(false);
          setIsCompalinList(data?.data?.length);
        } else {
          setData([]);
        }
      } catch (err) {
        setData([]);
      }
    };

    getDownloadData();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        padding: '20px',
      }}
    >
      {loading ? (
        <Grid container display={'flex'} justifyContent={'center'}>
          <Grid item>
            <PageLoader />
          </Grid>
        </Grid>
      ) : (
        <>
          {data?.map((item) => (
            <Card
              sx={{
                width: 300,
                background:
                  Number(selectedComplain.complain_id) === item.id
                    ? '#FFE4C9'
                    : '',
              }}
              onClick={() =>
                setSelectedComplain({
                  ...selectedComplain,
                  complain_id: String(item.id),
                  member_id: String(item.member_id),
                })
              }
            >
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Complain Details{`(${item.created_by})`}{' '}
                    <span style={{ color: 'gray' }}>
                      {moment(item.created_at).fromNow()}
                    </span>
                    <Divider />
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {item.content}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
          width: '100%',
        }}
      >
        {data?.length === 0 && <h4>No Complain Found</h4>}
      </Box>
    </Box>
  );
};

export default ComplainCardDetails;
