import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  TextField,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ModalsWrapper from '../../../../Components/Modals/ModalsWrapper';
import { BankAccount } from './BankList';
import { removeUndefinedAndNull } from '../../../../Common/removeUndefinedAndNull';
import { useBankUpdateMutation } from '../../api/paymentEndPoint';
export default function BankDetailsEdit({ data }: { data: BankAccount }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    mutate,
    isLoading: loading,
    isSuccess: success,
  } = useBankUpdateMutation();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState<any>({
    account_holder_name: '',
    account_number: '',
    bank_name: '',
    district_name: '',
    branch_name: '',
    routing_number: '',
    mobile_number: '',
    is_active: '',
  });
  const onSubmit = () => {
    const removeData = removeUndefinedAndNull(info);
    mutate({ data: removeData, id: data.id });
  };

  useEffect(() => {
    if (success) {
      setOpen(false);
      setInfo({});
    }
  }, [success]);

  return (
    <>
      <Box onClick={() => setOpen(true)}>
        <Button variant='text'>
          <BorderColorIcon fontSize='small' />
        </Button>
      </Box>
      <ModalsWrapper
        modalData={{ title: 'Update your Bank information' }}
        showModal={open}
        setShowModal={setOpen}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 600,
            margin: '0 auto',
            padding: '20px',
            bgcolor: 'background.paper',
            boxShadow: 3,
          }}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Account Holder Name'
                variant='outlined'
                size='small'
                {...register('account_holder_name')}
                defaultValue={data.account_holder_name}
                onChange={(e) =>
                  setInfo({ ...info, account_holder_name: e.target.value })
                }
                placeholder='Enter Account Holder Name'
                InputLabelProps={{ shrink: true }}
                error={!!errors.account_holder_name}
                helperText={errors.account_holder_name?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Account Number'
                variant='outlined'
                size='small'
                {...register('account_number')}
                defaultValue={data.account_number}
                onChange={(e) =>
                  setInfo({ ...info, account_number: e.target.value })
                }
                placeholder='Enter Account Number'
                InputLabelProps={{ shrink: true }}
                error={!!errors.account_number}
                helperText={errors.account_number?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Bank Name'
                variant='outlined'
                placeholder='Enter Bank Name'
                InputLabelProps={{ shrink: true }}
                size='small'
                {...register('bank_name')}
                defaultValue={data.bank_name}
                onChange={(e) =>
                  setInfo({ ...info, bank_name: e.target.value })
                }
                error={!!errors.bank_name}
                helperText={errors.bank_name?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='District Name'
                variant='outlined'
                size='small'
                placeholder='Enter District Name'
                InputLabelProps={{ shrink: true }}
                {...register('district_name')}
                defaultValue={data.district_name}
                onChange={(e) =>
                  setInfo({ ...info, district_name: e.target.value })
                }
                error={!!errors.district_name}
                helperText={errors.district_name?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Branch Name'
                variant='outlined'
                size='small'
                placeholder='Enter Branch Name'
                InputLabelProps={{ shrink: true }}
                {...register('branch_name')}
                defaultValue={data.branch_name}
                onChange={(e) =>
                  setInfo({ ...info, branch_name: e.target.value })
                }
                error={!!errors.branch_name}
                helperText={errors.branch_name?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Routing Number'
                variant='outlined'
                size='small'
                placeholder='Enter Routing Number'
                InputLabelProps={{ shrink: true }}
                {...register('routing_number')}
                defaultValue={data.routing_number}
                onChange={(e) =>
                  setInfo({ ...info, routing_number: e.target.value })
                }
                error={!!errors.routing_number}
                helperText={errors.routing_number?.message as string}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Mobile Number'
                variant='outlined'
                size='small'
                placeholder='Enter Mobile Number'
                InputLabelProps={{ shrink: true }}
                {...register('mobile_number')}
                defaultValue={data.mobile_number}
                onChange={(e) =>
                  setInfo({ ...info, mobile_number: e.target.value })
                }
                type='number'
                error={!!errors.mobile_number}
                helperText={errors.mobile_number?.message as string}
              />
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex' }}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '10px',
                }}
              >
                <Grid item>Status</Grid>
                <Grid item>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    defaultValue={data.is_active}
                    onChange={(e) =>
                      setInfo({ ...info, is_active: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value='1'
                      control={<Radio />}
                      label='Active'
                    />
                    <FormControlLabel
                      value='0'
                      control={<Radio />}
                      label='inActive'
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent={'center'}>
                <Grid item xs={8}>
                  <Button
                    type='submit'
                    variant='outlined'
                    color='primary'
                    fullWidth
                  >
                    {loading ? 'loading..' : 'Update Bank Account'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ModalsWrapper>
    </>
  );
}
