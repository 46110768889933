import {
  IEventResponse,
  IMeetingResponse,
  INoticeResponse,
  IServiceResponse,
} from '../../../Types/DashvoardHomeType';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { HttpClient } from '../../../rest/utils/http';
import { IDashboardDataResponse } from '../info/CompanyInfoTypes';

export const fetchDashboardData = async (id: number) => {
  const fetchData = await HttpClient.get<IDashboardDataResponse>(
    `${API_ENDPOINTS.DASHBOARD_DATA}/${id}`
  );
  return fetchData;
};
export const fetchDashboardService = async () => {
  const fetchData = await HttpClient.get<IServiceResponse>(
    `${API_ENDPOINTS.DASHBOARD_SERVICE}`
  );
  return fetchData;
};
export const fetchDashboardMeeting = async () => {
  const fetchData = await HttpClient.get<IMeetingResponse>(
    `${API_ENDPOINTS.DASHBOARD_MEETING}`
  );
  return fetchData;
};
export const fetchDashboardEvents = async () => {
  const fetchData = await HttpClient.get<IEventResponse>(
    `${API_ENDPOINTS.DASHBOARD_EVENTS}`
  );
  return fetchData;
};
export const fetchDashboardNotice = async (member_id: number) => {
  const fetchData = await HttpClient.get<INoticeResponse>(
    `${API_ENDPOINTS.DASHBOARD_NOTICE}?member_id=${member_id}`
  );
  return fetchData;
};

// post query
export const PostJoinedMeeting = async (data: any) => {
  return await HttpClient.post(`${API_ENDPOINTS.JOINED_TRAINING}`, data);
};
export const PostJoinedEvent = async (data: any) => {
  return await HttpClient.post(`${API_ENDPOINTS.JOINED_EVENT}`, data);
};
