import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { showToast } from '../../../Toaster/Toaster';
import { ICompanyDocument } from '../../../rest/types';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { UpdateCompanyDocumentMutation } from '../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation';
import { removeUndefinedAndNull } from '../../../Common/removeUndefinedAndNull';

interface IProps {
  memberId: number;
  companyDocument: ICompanyDocument;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateCompanyDocument = ({ companyDocument, setToggle }: IProps) => {
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const { register, handleSubmit } = useForm();
  const [officeAgreement, setOfficeAgreement] = useState<string>(
    companyDocument.company_office_agreement
  );
  const [ownershipStatus, setOwnershipStatus] = useState(
    companyDocument.company_ownership_status
  );
  const [info, setInfo] = useState({
    company_office_agreement: '',
    company_partnership_deed_file: '',
    company_agreement_file: '',
    company_staff_list_file: '',
    company_signboard_file: '',
    company_memorandum_file: '',
    company_form_117: '',
    company_form_12: '',
    company_incorporation_certificate: '',
  });
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyDocumentMutation(data);
    },
    onSuccess: () => {
      showToast('successfully updated', 'success');
      setToggle(false);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_COMPANY_DOCUMENT],
      });
    },
  });

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    const removeData = removeUndefinedAndNull(info);
    Object.keys(removeData).forEach((key) => {
      formData.append(key, removeData[key]);
    });

    if (Object.keys(removeData).length === 0) {
      showToast('Please Change any field', 'error');
    } else {
      mutation.mutate(formData);
    }
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <FormControl disabled sx={{ width: '100%' }} size='small'>
              <InputLabel id='demo-simple-select-label'>
                Ownership status
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                label='Ownership status'
                value={companyDocument.company_ownership_status}
              >
                <MenuItem value={'Proprietor'}>Proprietor</MenuItem>
                <MenuItem value={'Partnership'}>Partnership</MenuItem>
                <MenuItem value={'Limited'}>Limited</MenuItem>
              </Select>
            </FormControl>
          </Box>{' '}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <TextField
              size='small'
              label='Staff list on office pad with seal and signature of owner'
              id='outlined-basic'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              type='file'
              fullWidth
              {...register('company_staff_list_file')}
              onChange={(e: any) => {
                setInfo({
                  ...info,
                  company_staff_list_file: e.target.files[0],
                });
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <TextField
              size='small'
              label='Upload internal &#38; external photographs of office including
                Signboard (pdf)'
              id='outlined-basic'
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: 'application/pdf',
              }}
              variant='outlined'
              type='file'
              fullWidth
              {...register('company_signboard_file')}
              onChange={(e: any) => {
                setInfo({
                  ...info,
                  company_signboard_file: e.target.files[0],
                });
              }}
            />
          </Box>
        </Grid>

        {ownershipStatus === 'Partnership' && (
          <Grid item xs={12} sm={6} md={4}>
            <Box mt={2}>
              <Box>
                <TextField
                  size='small'
                  label='Photocopy of deed of partnership'
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('company_partnership_deed_file')}
                  onChange={(e: any) => {
                    setInfo({
                      ...info,
                      company_partnership_deed_file: e.target.files[0],
                    });
                  }}
                />
              </Box>
            </Box>
          </Grid>
        )}
        {ownershipStatus === 'Limited' && (
          <Grid item xs={12} sm={6} md={4}>
            <Box mt={2}>
              <TextField
                size='small'
                label='Upload incorporation certificate'
                id='outlined-basic'
                variant='outlined'
                type='file'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                {...register('company_incorporation_certificate')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_incorporation_certificate: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
        )}

        {ownershipStatus === 'Limited' && (
          <Grid item xs={12} sm={6} md={4}>
            <Box mt={2}>
              <TextField
                size='small'
                id='outlined-basic'
                label='Memorandum document'
                variant='outlined'
                type='file'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                fullWidth
                {...register('company_memorandum_file')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_memorandum_file: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
        )}
        {ownershipStatus === 'Limited' && (
          <Grid item xs={12} sm={6} md={4}>
            <Box mt={2}>
              <TextField
                size='small'
                id='outlined-basic'
                label='Upload Form 12 (PDF)'
                variant='outlined'
                type='file'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                fullWidth
                {...register('company_form_12')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_form_12: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
        )}
        {ownershipStatus === 'Limited' && (
          <Grid item xs={12} sm={6} md={4}>
            <Box mt={2}>
              <TextField
                size='small'
                id='outlined-basic'
                label='Upload Form 117 (PDF)'
                variant='outlined'
                type='file'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                fullWidth
                {...register('company_form_117')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_form_117: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <Box mt={2}>
            <FormControl sx={{ width: '100%' }} size='small'>
              <InputLabel id='demo-simple-select-label'>
                Office agreement
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                label=' Office agreement'
                value={officeAgreement}
                onChange={(e: any) => setOfficeAgreement(e.target.value)}
              >
                <MenuItem value={'Self premise'}>Self premise</MenuItem>
                <MenuItem value={'Rented premise'}>Rented premise</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {officeAgreement && (
            <Box mt={2}>
              <TextField
                size='small'
                id='outlined-basic'
                label={
                  officeAgreement === 'Self premise'
                    ? 'Deed of agreement of self premise office space'
                    : 'Deed of agreement of rented premise office space'
                }
                InputLabelProps={{
                  shrink: true,
                }}
                variant='outlined'
                type='file'
                fullWidth
                {...register('company_agreement_file')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_agreement_file: e.target.files[0],
                  });
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            Loading..
          </Button>
        ) : adminId.user_member_account_status !== 'active' ? (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            Save
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyDocument;
