import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { IWalletAccount } from './WalletList';
import ModalsWrapper from '../../../../Components/Modals/ModalsWrapper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { removeUndefinedAndNull } from '../../../../Common/removeUndefinedAndNull';
import UpdateDataApi from '../../../../Common/FetchData/UpdateDataApi';
export default function WalletEdit({
  setRefetch,
  data,
}: {
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  data: IWalletAccount;
}) {
  const { loading, updateData, success, setSuccess } = UpdateDataApi(
    `/api/atab/beneficiary/mobile-wallet/${data.id}`
  );
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({
    account_holder_name: '',
    mobile_number: '',
    mobile_operator_name: '',
    account_type: '',
    wallet_type: '',
    is_active: '',
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const walletType = watch('walletType');
  const accountType = watch('accountType');

  const onSubmit = (data: any) => {
    const removeData = removeUndefinedAndNull(info);
    updateData(removeData);
  };

  useEffect(() => {
    if (success) {
      setRefetch(true);
      setOpen(false);
      setSuccess(false);
    }
  }, [success]);
  return (
    <>
      <Box onClick={() => setOpen(true)}>
        <Button variant='text'>
          <BorderColorIcon fontSize='small' />
        </Button>
      </Box>

      <ModalsWrapper
        modalData={{ title: 'Update your Wallet information' }}
        showModal={open}
        setShowModal={setOpen}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 600,
            margin: '0 auto',
            padding: '20px',
            bgcolor: 'background.paper',
            boxShadow: 3,
          }}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Account Holder Name'
                variant='outlined'
                size='small'
                placeholder='Account Holder Name'
                InputLabelProps={{ shrink: true }}
                defaultValue={data.account_holder_name}
                {...register('account_holder_name')}
                onChange={(e) =>
                  setInfo({ ...info, account_holder_name: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Mobile Number'
                variant='outlined'
                size='small'
                placeholder='Mobile Number'
                InputLabelProps={{ shrink: true }}
                {...register('mobile_number')}
                defaultValue={data.mobile_number}
                onChange={(e) =>
                  setInfo({ ...info, mobile_number: e.target.value })
                }
              />
            </Grid>

            {/* Account Type */}
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Select Operator Type</InputLabel>
                <Select
                  fullWidth
                  label='Mobile Operator Name'
                  variant='outlined'
                  placeholder='Mobile Operator Name'
                  {...register('mobile_operator_name')}
                  defaultValue={data.mobile_operator_name}
                >
                  <MenuItem value='Grameenphone'>Grameenphone</MenuItem>
                  <MenuItem value='Robi'>Robi</MenuItem>
                  <MenuItem value='Airtel'>Airtel</MenuItem>
                  <MenuItem value='Banglalink'>Banglalink</MenuItem>
                  <MenuItem value='Teletalk'>Teletalk</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Select Account Type</InputLabel>
                <Select
                  value={accountType}
                  defaultValue={data.account_type}
                  label='Select Account Type'
                  placeholder='Mobile Operator Name'
                  {...register('account_type')}
                  onChange={(e) =>
                    setInfo({ ...info, account_type: e.target.value })
                  }
                >
                  <MenuItem value='Merchant'>Merchant</MenuItem>
                  <MenuItem value='Agent'>Agent</MenuItem>
                  <MenuItem value='Personal'>Personal</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Wallet Type */}
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>Select Wallet Type</InputLabel>
                <Select
                  value={walletType}
                  label='Select Wallet Type'
                  defaultValue={data.wallet_type}
                  {...register('wallet_type')}
                  onChange={(e) =>
                    setInfo({ ...info, wallet_type: e.target.value })
                  }
                >
                  <MenuItem value='bKash'>bKash</MenuItem>
                  <MenuItem value='Nagad'>Nagad</MenuItem>
                  <MenuItem value='Upay'>Upay</MenuItem>
                  <MenuItem value='ROCKET'>ROCKET</MenuItem>
                  <MenuItem value='MYCash'>MYCash</MenuItem>
                  <MenuItem value='mCash'>mCash</MenuItem>
                  <MenuItem value='TeleCash'>TeleCash</MenuItem>
                  <MenuItem value='Islamic Wallet'>Islamic Wallet</MenuItem>
                  <MenuItem value='Meghna Pay'>Meghna Pay</MenuItem>
                  <MenuItem value='OK Wallet'>OK Wallet</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex' }}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '10px',
                }}
              >
                <Grid item>Status</Grid>
                <Grid item>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    defaultValue={data.is_active}
                    onChange={(e) =>
                      setInfo({ ...info, is_active: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value='1'
                      control={<Radio />}
                      label='Active'
                    />
                    <FormControlLabel
                      value='0'
                      control={<Radio />}
                      label='inActive'
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'center'}>
                <Grid xs={8}>
                  <Button
                    type='submit'
                    variant='outlined'
                    color='primary'
                    fullWidth
                  >
                    {loading ? 'loading..' : 'Update Wallet'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>{' '}
      </ModalsWrapper>
    </>
  );
}
