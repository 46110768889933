import { useForm } from 'react-hook-form';
import { Box, Grid, Button, TextField } from '@mui/material';
import { showToast } from '../../../Toaster/Toaster';
import { ICompanyOwnerList } from '../../../rest/types';
import { useEffect, useState } from 'react';
import { removeUndefinedAndNull } from '../../../Common/removeUndefinedAndNull';
import moment from 'moment';
import { useOwnerInformationUpateMutation } from './apiEndpoints';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
interface IProps {
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: boolean;
  setUpdateModal: (value: boolean) => void;
  singleOwner: ICompanyOwnerList;
  mainOwener: boolean;
}
const UpdateCompanyOwner = ({
  singleOwner,
  mainOwener,
  setUpdateModal,
}: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [info, setInfo] = useState({
    company_owner_name: '',
    company_owner_designation: '',
    company_owner_nid_file: '',
    company_owner_signature: '',
    company_owner_phone: '',
    company_owner_email: '',
    company_owner_biodata: '',
    company_owner_address: '',
    company_owner_photo: '',
    company_owner_passport: '',
    company_owner_nid: '',
    date_of_birth: '',
  });
  const { mutate, isLoading, isSuccess } = useOwnerInformationUpateMutation();

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    const removeData = removeUndefinedAndNull(info);

    Object.keys(removeData).forEach((key) => {
      formData.append(key, removeData[key]);
    });

    if (Object.keys(removeData).length === 0) {
      showToast('Please Change any field', 'error');
    } else {
      mutate({ data: formData, id: singleOwner.company_owner_id });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setUpdateModal(false);
    }
  }, [isSuccess]);
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label="Owner's name"
                type='text'
                defaultValue={singleOwner?.company_owner_name}
                fullWidth
                sx={{ pb: 2 }}
                disabled
                {...register('company_owner_name')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                defaultValue={singleOwner?.company_owner_designation}
                label="Owner's Designation in agency"
                type='text'
                fullWidth
                disabled
                sx={{ pb: 2 }}
                {...register('company_owner_designation')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                type='text'
                fullWidth
                defaultValue={singleOwner?.company_owner_address}
                label="Owner's Home address"
                sx={{ pb: 2 }}
                {...register('company_owner_address')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_owner_address: e.target.value,
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                size='small'
                id='date'
                type='text'
                defaultValue={singleOwner?.company_owner_phone}
                label="Owner's Phone Number"
                sx={{ width: '100%', pb: 2 }}
                {...register('company_owner_phone')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_owner_phone: e.target.value,
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner email'
                defaultValue={singleOwner?.company_owner_email}
                type='email'
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_owner_email')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_owner_email: e.target.value,
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                size='small'
                id='date'
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={moment(singleOwner?.date_of_birth).format(
                  'YYYY-MM-DD'
                )}
                label="Owner's Date of Birth"
                sx={{ width: '100%', pb: 2 }}
                {...register('date_of_birth')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    date_of_birth: e.target.value,
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label={
                  <span style={{ display: 'flex', gap: 1 }}>
                    Owner's Photo (JPG/JPEG/PNG)
                  </span>
                }
                InputLabelProps={{
                  shrink: true,
                }}
                type='file'
                fullWidth
                sx={{ pb: 2 }}
                {...register('company_owner_photo')}
                inputProps={{
                  accept: 'image/jpeg, image/png, image/jpg',
                }}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_owner_photo: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                size='small'
                id='date'
                type='text'
                defaultValue={singleOwner?.company_owner_nid}
                label="Owner's NID Number"
                sx={{ width: '100%', pb: 2 }}
                {...register('company_owner_nid')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_owner_nid: e.target.value,
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                required={
                  singleOwner.company_owner_nid_file ? false : mainOwener
                }
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Company owner nid photo(Both side) pdf'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                type='file'
                fullWidth
                sx={{ pb: 2 }}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_owner_nid_file: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label="Owner's Biodata pdf"
                InputLabelProps={{
                  shrink: true,
                }}
                type='file'
                fullWidth
                sx={{ pb: 2 }}
                inputProps={{ accept: 'application/pdf' }}
                {...register('company_owner_biodata')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_owner_biodata: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                required={
                  singleOwner.company_owner_signature ? false : mainOwener
                }
                size='small'
                id='outlined-basic'
                variant='outlined'
                label="Owner's Signature "
                InputLabelProps={{
                  shrink: true,
                }}
                type='file'
                fullWidth
                sx={{ pb: 2 }}
                inputProps={{
                  accept: 'image/jpeg, image/png, image/jpg',
                }}
                {...register('company_owner_signature')}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_owner_signature: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
            disabled={
              user?.user_member_account_status === 'active' ? true : false
            }
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyOwner;
