// import { useMutation } from "react-query";
// import { postIDCard } from ".";
// import { useState } from "react";
// import { showToast } from "../Toaster/Toaster";

// export function useCreateIDCard() {
//   const [formError, setFormError] = useState(null);
//   const { mutate, isLoading, isSuccess } = useMutation(postIDCard, {
//     onSuccess: (data) => {
//       if (data?.success) {
//         showToast(`Successfully created ID card`, "success");
//       }
//     },
//     onError: (error) => {
//       const {
//         response: { data },
//       }: any = error ?? {};

//       showToast(`${data.message}`, "error");
//       setFormError(data?.message);
//     },
//     // onSettled: () => {
//     // 	queryClient.invalidateQueries({ queryKey: [API_ENDPOINTS.USERS_ME] });
//     // },
//   });

//   return { mutate, isLoading, formError, setFormError, isSuccess };
// }

import { useMutation, useQueryClient } from "react-query";
import { postIDCard } from ".";
import { useState } from "react";
import { showToast } from "../Toaster/Toaster";
import { API_ENDPOINTS } from "./utils/api-endpoints";

export function useCreateIDCard() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(postIDCard, {
    onSuccess: (data) => {
      if (data?.success) {
        showToast(`Successfully created ID card`, "success");
      }
    },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};

      showToast(`${data.message}`, "error");
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.ALL_ID_CARD],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}
