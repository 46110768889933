import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { getStatusLabel } from '../../Utils/Label/status-label';
import ModalsWrapper from '../../Components/Modals/ModalsWrapper';
import CreateDuplicateApplication from './CreateDuplicateApplication';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import TableNoDataMessage from '../../Components/TableMessage/TableNoDataMessage';
import TableLoader from '../../Components/Spinner/TableLoader';
import { Button, Grid } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useDuplicateCertificateQuery } from './apiEndpoints/apiEndpoints';
import { useState } from 'react';
interface Data {
  duplicate_certificate_application_id: number;
  duplicate_certificate_application_status: string;
  duplicate_certificate_application_created_at: string;
  action?: string;
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'duplicate_certificate_application_id',
    numeric: false,
    disablePadding: false,
    label: 'Application ID',
  },
  {
    id: 'duplicate_certificate_application_status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'duplicate_certificate_application_created_at',
    numeric: false,
    disablePadding: false,
    label: 'Created at',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({ setOpen }: { setOpen: any }) {
  const { user } = useAuthContext();
  return (
    <Grid
      container
      justifyContent={'space-between'}
      sx={{ p: 2 }}
      alignContent={'center'}
    >
      <Grid item>
        <Typography variant='h6' id='tableTitle' component='div'>
          Duplicate certificate application
        </Typography>
      </Grid>
      <Grid item>
        {' '}
        {user?.user_member_account_status !== 'pending' &&
          user?.user_member_account_status !== 'inactive' &&
          user?.user_member_account_status !== 'rejected' && (
            <Button
              onClick={() => setOpen(true)}
              size='small'
              component='label'
              variant='contained'
              startIcon={<BorderColorIcon />}
              fullWidth
            >
              Create Duplicate certificate application
            </Button>
          )}{' '}
      </Grid>
    </Grid>
  );
}

export default function DuplicateCertificateApplication() {
  const { user } = useAuthContext();
  const { data, isLoading } = useDuplicateCertificateQuery(
    user.user_member_id!
  );
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Box sx={{ width: '100%', mt: 5 }}>
        <Paper sx={{ width: '100%', mb: 2, mt: 5 }}>
          <EnhancedTableToolbar setOpen={setOpen} />
          <TableContainer>
            <Table aria-labelledby='tableTitle' size={'medium'}>
              <EnhancedTableHead />
              <TableBody>
                {data?.data?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell id={labelId} align='left'>
                        {row.duplicate_certificate_application_id}
                      </TableCell>
                      <TableCell align='left'>
                        {getStatusLabel(
                          row.duplicate_certificate_application_status!
                        )}
                      </TableCell>
                      <TableCell align='left'>
                        {moment(
                          `${row.duplicate_certificate_application_created_at}`
                        ).format('DD-MMM-YYYY')}
                      </TableCell>
                      <TableCell align='left'>
                        <Tooltip title='View'>
                          <Link
                            to={`/duplicate-certificate-application/${row.duplicate_certificate_application_id}`}
                          >
                            <IconButton>
                              <VisibilityIcon fontSize='small' />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {!isLoading && data?.data?.length === 0 && (
                  <TableNoDataMessage />
                )}
                {isLoading && (
                  <TableRow>
                    <TableCell align='center' colSpan={6}>
                      <TableLoader />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={data?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <ModalsWrapper
        modalData={{ title: 'Create duplicate certificate application' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <CreateDuplicateApplication handelClose={setOpen} />
      </ModalsWrapper>
    </>
  );
}
