import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import PostDataApis from '../../../../Common/FetchData/PostDataApis';

export default function WalletForm({
  setBankOpen,
  setRefetch,
}: {
  setBankOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { loading, postData, success } = PostDataApis(
    '/api/atab/beneficiary/mobile-wallet'
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const walletType = watch('walletType');
  const accountType = watch('accountType');

  const onSubmit = (data: any) => {
    postData(data);
  };

  useEffect(() => {
    if (success) {
      setBankOpen(false);
      setRefetch(true);
    }
  }, [success]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
        padding: '20px',
        bgcolor: 'background.paper',
        boxShadow: 3,
      }}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Account Holder Name'
            variant='outlined'
            size='small'
            placeholder='Account Holder Name'
            InputLabelProps={{ shrink: true }}
            {...register('account_holder_name', {
              required: 'Account holder name is required',
            })}
            error={!!errors.account_holder_name}
            helperText={errors.account_holder_name?.message as string}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Mobile Number'
            variant='outlined'
            size='small'
            placeholder='Mobile Number'
            InputLabelProps={{ shrink: true }}
            {...register('mobile_number', {
              required: 'Mobile number is required',
            })}
            error={!!errors.mobile_number}
            helperText={errors.mobile_number?.message as string}
          />
        </Grid>

        {/* Account Type */}
        <Grid item xs={6}>
          <FormControl fullWidth size='small'>
            <InputLabel>Select Operator Type</InputLabel>
            <Select
              fullWidth
              label='Mobile Operator Name'
              variant='outlined'
              placeholder='Mobile Operator Name'
              {...register('mobile_operator_name', {
                required: 'Mobile operator name is required',
              })}
              error={!!errors.mobile_operator_name}
            >
              <MenuItem value='Grameenphone'>Grameenphone</MenuItem>
              <MenuItem value='Robi'>Robi</MenuItem>
              <MenuItem value='Airtel'>Airtel</MenuItem>
              <MenuItem value='Banglalink'>Banglalink</MenuItem>
              <MenuItem value='Teletalk'>Teletalk</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth size='small'>
            <InputLabel>Select Account Type</InputLabel>
            <Select
              value={accountType}
              defaultValue={'Personal'}
              label='Select Account Type'
              placeholder='Mobile Operator Name'
              //   onChange={handleAccountTypeChange}
              {...register('account_type', {
                required: 'Account type is required',
              })}
              error={!!errors.account_type}
            >
              <MenuItem value='Merchant'>Merchant</MenuItem>
              <MenuItem value='Agent'>Agent</MenuItem>
              <MenuItem value='Personal'>Personal</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Wallet Type */}
        <Grid item xs={6}>
          <FormControl fullWidth size='small'>
            <InputLabel>Select Wallet Type</InputLabel>
            <Select
              value={walletType}
              label='Select Wallet Type'
              defaultValue={'bKash'}
              {...register('wallet_type', {
                required: 'Wallet type is required',
              })}
              error={!!errors.wallet_type}
            >
              <MenuItem value='bKash'>bKash</MenuItem>
              <MenuItem value='Nagad'>Nagad</MenuItem>
              <MenuItem value='Upay'>Upay</MenuItem>
              <MenuItem value='ROCKET'>ROCKET</MenuItem>
              <MenuItem value='MYCash'>MYCash</MenuItem>
              <MenuItem value='mCash'>mCash</MenuItem>
              <MenuItem value='TeleCash'>TeleCash</MenuItem>
              <MenuItem value='Islamic Wallet'>Islamic Wallet</MenuItem>
              <MenuItem value='Meghna Pay'>Meghna Pay</MenuItem>
              <MenuItem value='OK Wallet'>OK Wallet</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={'center'}>
            <Grid xs={8}>
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                fullWidth
              >
                Add Wallet
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
