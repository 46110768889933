import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import GetDataApi from '../../../../../Common/FetchData/GetDataApi';
import { BankAccount } from '../../../Component/Bank/BankList';
import PostDataApis from '../../../../../Common/FetchData/PostDataApis';
import { useEffect } from 'react';
import {
  IActiveForAdmin,
  IInvoiceList,
  IPaymentManagementPostType,
  IWalletPaymentFormData,
} from '../../../type/payment_type';
import BearerCashCheque from '../../Component/PaymentTypewise/BearerCashCheque';
import Payorder from '../../Component/PaymentTypewise/Payorder';
import { useSearchParams } from 'react-router-dom';
const DWalletPayment = ({
  singleInvoice,
  setSingleInvoice,
  setRefetch,
  setManualPayment,
  setBeneficiaryType,
}: {
  singleInvoice: IInvoiceList | undefined;
  setSingleInvoice: React.Dispatch<
    React.SetStateAction<IInvoiceList | undefined>
  >;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  setManualPayment: React.Dispatch<React.SetStateAction<string>>;
  setBeneficiaryType: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<any>();
  const payment_type = watch('payment_type');
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries(searchParams.entries());
  //active bank account api
  const { response: activeMobileBankingForAdmin } = GetDataApi<IActiveForAdmin>(
    '/api/atab/account/member?account_type=Mobile Banking'
  );
  const {
    loading: postLoading,
    postData,
    success,
  } = PostDataApis('/api/atab/member-payment');
  useEffect(() => {
    if (success) {
      setRefetch(true);
      reset();
      setManualPayment('list');
      setSearchParams({ tabValue: 'list' });
    }
  }, [success]);
  useEffect(() => {
    if (activeMobileBankingForAdmin) {
      // Ensure values are updated once the data is fetched
      setValue(
        'wallet_account_type',
        activeMobileBankingForAdmin?.wallet_account_type || ''
      );
      setValue('account_holder_name', activeMobileBankingForAdmin?.name || '');
      setValue('account_number', activeMobileBankingForAdmin?.account_no || '');
      setValue('admin_account_id', activeMobileBankingForAdmin?.id || '');
      setValue('mobile_number', activeMobileBankingForAdmin?.account_no || '');
      setValue('wallet_type', activeMobileBankingForAdmin?.wallet_type || '');
      setValue(
        'mobile_operator_name',
        activeMobileBankingForAdmin?.mobile_operator_name || ''
      );
    }
  }, [activeMobileBankingForAdmin, setValue]);

  useEffect(() => {
    setValue('amount', singleInvoice?.atab_payment_invoice_grand_total);
    setValue('voucher_no', singleInvoice?.voucher_no);
    setValue('invoice_id', singleInvoice?.atab_payment_invoice_id);
  }, [singleInvoice]);
  const onSubmit = (data: IWalletPaymentFormData) => {
    console.log(data);
    const { payment_date, transaction_id, admin_account_id, invoice_id } = data;
    // const formdata = new FormData();
    // const {
    //   account_holder_name,
    //   bank_name,
    //   account_number,
    //   beneficiary_type,
    //   ...all
    // } = data;
    // for (const key in all) {
    //   if (all.hasOwnProperty(key)) {
    //     if (key === 'voucher_photo' && typeof all[key] === 'object') {
    //       Object.keys(all.voucher_photo).forEach((photoKey) => {
    //         formdata.append(`voucher_photo`, all.voucher_photo[0]);
    //       });
    //     } else if (key === 'cheque_photo' && typeof all[key] === 'object') {
    //       Object.keys(all.cheque_photo).forEach((photoKey) => {
    //         formdata.append(`cheque_photo`, all.cheque_photo[0]);
    //       });
    //     } else if (key === 'payorder_photo' && typeof all[key] === 'object') {
    //       Object.keys(all.payorder_photo).forEach((photoKey) => {
    //         formdata.append(`payorder_photo`, all.payorder_photo[0]);
    //       });
    //     } else {
    //       formdata.append(key, all[key as keyof typeof all]);
    //     }
    //   }
    // }

    postData({
      payment_date,
      trx_id: transaction_id,
      admin_account_id,
      invoice_id,
    });
  };
  useEffect(() => {
    // On component mount, check if there's saved data in localStorage
    if (!singleInvoice) {
      const savedInvoice = localStorage.getItem('singleInvoice');
      if (savedInvoice) {
        setSingleInvoice(JSON.parse(savedInvoice));
      }
    }
  }, [singleInvoice]);
  return (
    <Card sx={{ mt: 5 }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: 600,
          margin: '0 auto',
          padding: '20px',
          bgcolor: 'background.paper',
          boxShadow: 3,
        }}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth size='small'>
              <InputLabel>Beneficiary Type</InputLabel>
              <Select
                fullWidth
                variant='outlined'
                label='Beneficiary Type'
                placeholder='beneficiary Type'
                {...register('beneficiary_type', {
                  required: 'beneficiary Type is required',
                })}
                onChange={(e: any) => {
                  setBeneficiaryType(e.target.value);
                  setSearchParams({
                    ...currentParams,
                    beneficiaryType: 'bank',
                  });
                }}
                error={!!errors.beneficiary_type}
                defaultValue={'wallet'}
              >
                <MenuItem value='bank'>Bank Account</MenuItem>
                <MenuItem value='wallet'>Mobile Wallet</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size='small'
              fullWidth
              disabled
              label='Select Account Type'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              {...register('wallet_account_type')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Account Name'
              variant='outlined'
              size='small'
              disabled
              placeholder='Account Name'
              InputLabelProps={{ shrink: true }}
              {...register('account_holder_name', {
                // required: 'Beneficiary Bank name is required',
              })}
              error={!!errors.account_holder_name}
              helperText={errors.account_holder_name?.message as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Mobile Number'
              variant='outlined'
              size='small'
              disabled
              placeholder='Mobile Number'
              InputLabelProps={{ shrink: true }}
              {...register('account_number', {
                // required: 'Account number is required',
              })}
              error={!!errors.account_number}
              helperText={errors.account_number?.message as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size='small'
              fullWidth
              disabled
              label='Mobile Operator Name'
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              {...register('mobile_operator_name', {
                required: 'Mobile operator name is required',
              })}
              error={!!errors.mobile_operator_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='voucher no'
              variant='outlined'
              size='small'
              disabled
              placeholder='voucher no'
              InputLabelProps={{ shrink: true }}
              {...register('voucher_no', {
                required: 'voucher no is required',
              })}
              error={!!errors.voucher_no}
              helperText={errors.voucher_no?.message as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Amount'
              variant='outlined'
              size='small'
              placeholder='Amount'
              InputLabelProps={{ shrink: true }}
              {...register('amount', {
                required: 'amount is required',
              })}
              disabled
              error={!!errors.amount}
              helperText={errors.amount?.message as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Transaction ID'
              variant='outlined'
              size='small'
              placeholder='Transaction ID'
              InputLabelProps={{ shrink: true }}
              {...register('transaction_id', {
                required: 'Transaction ID is required',
              })}
              error={!!errors.transaction_id}
              helperText={errors.transaction_id?.message as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Payment Date'
              variant='outlined'
              size='small'
              placeholder='Payment Date'
              InputLabelProps={{ shrink: true }}
              {...register('payment_date', {
                required: 'Payment date is required',
              })}
              type='date'
              error={!!errors.payment_date}
              helperText={errors.payment_date?.message as string}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={'center'}>
              <Grid xs={8}>
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  fullWidth
                >
                  {postLoading ? 'Loading...' : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
export default DWalletPayment;
