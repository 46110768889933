import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import GetDataApi from '../../../Common/FetchData/GetDataApi';
import { IAllTicket } from '../type/type';
import TableLoader, {
  TableNotFound,
} from '../../../Components/Spinner/TableLoader';
import ModalsWrapper from '../../../Components/Modals/ModalsWrapper';
import TableRowTicket from './TableRowTicket';
import CreateTicket from './CreateTicket';

const TicketList = () => {
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<{
    page: string | number;
    listType: string | number;
    limit: string | number;
  }>({
    listType: 'all',
    page: 1,
    limit: 30,
  });
  const { response, loading, getData, total } = GetDataApi<IAllTicket[]>(
    `/api/atab/user-member/ticket?status=${
      filter.listType == 'all' ? '' : filter.listType
    }`,
    true
  );
  useEffect(() => {
    getData();
  }, [filter]);
  return (
    <Box sx={{ mt: 5 }}>
      <Card>
        <CardHeader
          title='Ticket List'
          action={
            <Box
              sx={{
                width: 320,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={6}>
                <Select
                  size='small'
                  fullWidth
                  variant='outlined'
                  placeholder='Payment type'
                  defaultValue={'all'}
                  sx={{ padding: 0, height: 35, width: 200 }}
                  onChange={(e) =>
                    setFilter({ ...filter, listType: e.target.value })
                  }
                >
                  <MenuItem value='all'>All</MenuItem>
                  <MenuItem value='pending'>Pending</MenuItem>
                  <MenuItem value='completed'>Completed</MenuItem>
                </Select>
              </Grid>
              {adminId?.is_applied > 0 &&
                adminId?.user_member_account_status !== 'pending' &&
                adminId?.user_member_account_status !== 'inactive' &&
                adminId?.user_member_account_status !== 'rejected' && (
                  <Button
                    size='small'
                    variant='contained'
                    onClick={() => setOpen(true)}
                  >
                    Create Ticket
                  </Button>
                )}
            </Box>
          }
        />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SL</TableCell>

                <TableCell>Type</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableLoader />
            ) : response?.length ? (
              <TableBody>
                {response?.map((singleGuest, index) => {
                  return (
                    <TableRowTicket
                      data={singleGuest}
                      index={index}
                      refetch={getData}
                    />
                  );
                })}
              </TableBody>
            ) : (
              <TableNotFound />
            )}
          </Table>
          <Box p={2}>
            <TablePagination
              component='div'
              count={total as number}
              onPageChange={(_event: any, newPage: number) =>
                setFilter({ ...filter, page: newPage })
              }
              onRowsPerPageChange={(event) =>
                // setLimit(parseInt(event.target.value))
                setFilter({ ...filter, limit: event.target.value })
              }
              page={Number(filter.page) && Number(filter.page)}
              rowsPerPage={Number(filter.limit)}
              rowsPerPageOptions={[30, 50, 75, 100]}
            />
          </Box>
        </TableContainer>
      </Card>

      <ModalsWrapper
        modalData={{ title: 'Create Ticket' }}
        showModal={open}
        setShowModal={setOpen}
      >
        <CreateTicket refetch={getData} setOpen={setOpen} />
      </ModalsWrapper>
    </Box>
  );
};

export default TicketList;
