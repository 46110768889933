import { Box, CardContent, Grid, Typography } from "@mui/material";
import { imgUrl } from "../../../Helpers/utils/Constant";
import PageLoader from "../../../Components/Spinner/PageLoader";
import moment from "moment";
import { ICompanyCivilAviation } from "../../../rest/types";
import Description, { DescriptionChild } from "../../../Common/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PdfAndImageView from "../../../Common/PdfAndImageView";
type IProps = {
  data: ICompanyCivilAviation;
  loading: boolean;
};

const CardCivilAviation = ({ data, loading }: IProps) => {
  return (
    <>
      {!loading ? (
        <CardContent>
          <Box maxWidth={1000} sx={{ margin: "0 auto" }}>
            <Description>
              <DescriptionChild label="Certificate number">
                {data.certificate_number}
              </DescriptionChild>
              <DescriptionChild label="Download certificate file English">
                <PdfAndImageView file={data.certificate_file} color="#00e" />
              </DescriptionChild>
              <DescriptionChild label="Download certificate file Bangla">
                <PdfAndImageView file={data.certificate_file_bn} color="#00e" />
              </DescriptionChild>
              <DescriptionChild label="Certificate issue date">
                {moment(data.certificate_issue_date).format("Do-MMM-YYYY")}
              </DescriptionChild>
              <DescriptionChild label="Certificate expiry date">
                {moment(data.certificate_expiry_date).format("Do-MMM-YYYY")}
              </DescriptionChild>
            </Description>
          </Box>
        </CardContent>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default CardCivilAviation;
