import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ChangeEvent, useEffect } from 'react';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useProposerStatusUpdate } from '../../rest/useRequest';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';

type IProps = {
  setOpen: Function;
  id: number;
};

export default function UpdateProposerStatus({ setOpen, id }: IProps) {
  const { user } = useAuthContext();
  const [value, setValue] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const { isLoading, mutate, isSuccess } = useProposerStatusUpdate();

  useEffect(() => {
    if (isSuccess) {
      setOpen();
    }
  });
  const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!reason) {
      mutate({
        user_member_id: user.user_member_id!,
        proposer_request_id: id,
        proposer_request_status: value,
      });
      return;
    } else {
      mutate({
        user_member_id: user.user_member_id!,
        proposer_request_id: id,
        proposer_request_status: value,
        proposer_request_rejected_reason: reason,
      });
      return;
    }
  };
  return (
    <Box
      component='form'
      sx={{
        '& .MuiTextField-root': { mb: 2, width: '100%' },
        p: 3,
      }}
      onSubmit={onSubmit}
      noValidate
      autoComplete='off'
      width='360px'
    >
      <TextField
        id='outlined-select-currency'
        select
        name='proposer_request_status'
        label='Please select a status'
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        <MenuItem value='accepted'>Accepted</MenuItem>
        <MenuItem value='rejected'>Rejected</MenuItem>
      </TextField>
      <br />
      {value === 'rejected' && (
        <>
          <TextField
            id='outlined-multiline-static'
            label='Please write some text why rejected'
            multiline
            rows={4}
            onChange={(e) => setReason(e.target.value)}
          />
          <br />
        </>
      )}

      <Button
        disabled={isLoading}
        fullWidth
        variant='contained'
        size='small'
        type='submit'
      >
        {isLoading ? 'Loading...' : 'Submit'}
      </Button>
    </Box>
  );
}
