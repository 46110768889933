import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setCookie } from "nookies";
import { muiStyles } from "../../Utils/Styles";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { showToast } from "../../Toaster/Toaster";
import {
  AUTH_USER_FAILED,
  AUTH_USER_SUCCESS,
  url,
} from "../../Helpers/utils/Constant";

const useStyles = muiStyles;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const LoginVerificationModal = ({ open, handleClose, inputData }: any) => {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const classes = useStyles();
  const [otp, setOTp] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (otp && otp?.length === 6) {
      axios
        .post(`${url}/api/atab/common/match-email-otp`, {
          email: inputData.email,
          otp,
          type: "login_member",
        })
        .then(function (res) {
          if (res.data.success) {
            try {
              setIsLoading?.(true);
              axios
                .post(`${url}/api/auth/member/login`, inputData)
                .then((res) => {
                  if (res.data.success) {
                    showToast(`Successfully login`, "success");
                    localStorage.setItem(
                      "auth",
                      JSON.stringify(res?.data?.data)
                    );
                    dispatch?.({
                      type: AUTH_USER_SUCCESS,
                      payload: res?.data?.data,
                    });
                    setIsLoading?.(false);
                    setCookie(null, "haab_ua", res?.data?.token);
                    handleClose();
                    navigate("/");
                  }
                });
            } catch (err: any) {
              showToast(
                `Log in failed! \n ${err.response?.data?.message as string}`,
                "error"
              );

              dispatch?.({ type: AUTH_USER_FAILED });
            } finally {
              setIsLoading?.(false);
            }
          }
        })
        .catch(function (err: any) {
          showToast(`${err?.response?.data.message}`, "error");
          setIsLoading(false);
        });
    } else {
      showToast(`minimum 6 digits required`, "error");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <div className="pt-3">
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                color: "#0c4da2",
                pb: 3,
              }}
            >
              Enter OTP
            </Typography>
          </div>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              type="number"
              label="Enter OTP"
              onChange={(e: any) => setOTp(e.target.value)}
              variant="filled"
              className={classes.inputText}
              InputProps={{ classes }}
            />

            <Box>
              {isLoading ? (
                <Button
                  disabled
                  fullWidth
                  variant="contained"
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                    background: "#1113a2",
                  }}
                  className={classes.signInButton}
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                    background: "#31805F",
                  }}
                  className={classes.signInButton}
                >
                  Submit
                </Button>
              )}
            </Box>

            <Box textAlign={"end"}>
              <Button
                variant="outlined"
                onClick={handleClose}
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                className={classes.signInButton}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default LoginVerificationModal;
