import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModalsWrapper from '../../Components/Modals/ModalsWrapper';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import TableLoader from '../../Components/Spinner/TableLoader';
import moment from 'moment';
import IdCardFormV2 from './IdCardFormV2';
import { imgUrl } from '../../Helpers/utils/Constant';
import UpdateDataApi from '../../Common/FetchData/UpdateDataApi';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import TableNoDataMessage from '../../Components/TableMessage/TableNoDataMessage';
import { getStatusLabel } from '../../Utils/Label/status-label';
import { useAllIdCardQuery } from './api/idCardEndPoint';

const IdCard = () => {
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const { data, isLoading, refetch } = useAllIdCardQuery(user?.user_member_id!);

  const isDuplicateIdCard = data?.data?.find(
    (item) => item.c_type === 'DUPLICATE_ID_CARD'
  );
  const { updateData, success } = UpdateDataApi(
    `/api/atab/user-member/certificate`
  );

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);
  return (
    <>
      <Card sx={{ mt: 5 }}>
        <CardHeader
          action={
            user?.user_member_account_status !== 'pending' &&
            user?.user_member_account_status !== 'inactive' &&
            user?.user_member_account_status !== 'rejected' && (
              <Button
                variant='contained'
                onClick={() => setOpen(true)}
                size='small'
                disabled={isDuplicateIdCard?.status === 1 ? true : false}
              >
                Request for id card
              </Button>
            )
          }
          title={
            <Typography
              sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}
            >
              ID Cards
            </Typography>
          }
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700, fontSize: 16 }}>ID</TableCell>
                <TableCell
                  align='center'
                  sx={{ fontWeight: 700, fontSize: 16 }}
                >
                  Type
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ fontWeight: 700, fontSize: 16 }}
                >
                  Issue Date
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ fontWeight: 700, fontSize: 16 }}
                >
                  Status
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ fontWeight: 700, fontSize: 16 }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableLoader />
              ) : (
                <>
                  {data?.data?.length ? (
                    <>
                      {data?.data?.map((sCertificate) => (
                        <TableRow
                          key={sCertificate.c_id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component='th' scope='row'>
                            {sCertificate.c_id}
                          </TableCell>
                          <TableCell align='center'>
                            {sCertificate.c_type === 'ID_CARD' ? (
                              <> New membership ID Card </>
                            ) : (
                              <>Duplicate ID Card</>
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            {moment(sCertificate.c_issue_date).format(
                              'DD MMM YYYY'
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            {sCertificate.status ? (
                              getStatusLabel(
                                sCertificate.status ? 'active' : ''
                              )
                            ) : (
                              <Box sx={{ color: 'green' }}>
                                You are Already Downloaded
                              </Box>
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            <Tooltip
                              title={
                                sCertificate.c_type === 'ID_CARD' &&
                                !sCertificate.status
                                  ? 'You are already Downloaded. if need apply for duplicate certificate.'
                                  : 'Download'
                              }
                            >
                              <IconButton>
                                {sCertificate.c_type === 'ID_CARD' &&
                                !sCertificate.status ? (
                                  ''
                                ) : (
                                  <a
                                    onClick={() =>
                                      sCertificate.c_type === 'ID_CARD' &&
                                      sCertificate.status
                                        ? updateData(
                                            { status: 0 },
                                            sCertificate.c_id
                                          )
                                        : ''
                                    }
                                    style={{ color: 'black' }}
                                    href={`${imgUrl}/${
                                      sCertificate.c_type === 'ID_CARD'
                                        ? sCertificate.c_photo
                                        : sCertificate.c_photo
                                    }`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    <ArrowCircleDownIcon />
                                  </a>
                                )}
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      <TableNoDataMessage />
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <ModalsWrapper
        modalData={{ title: 'Request for ID card' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <IdCardFormV2 handleClose={() => setOpen(false)} />
        {/* <IdCardForm handleClose={() => setOpen(false)} /> */}
        {/* <RequestIdCardForm handleClose={() => setOpen(false)} /> */}
      </ModalsWrapper>
    </>
  );
};

export default IdCard;
