import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Grid, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { useAddressChangeMutation } from '../../rest/useAddressChange';
import AddressSelect from './AddressSelect';
import { showToast } from '../../Toaster/Toaster';
import { useCompanyDetailsQuery } from '../../rest/useCompanyInfo';

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
};

const CreateAddressChangeApplication = ({ handelClose, setReload }: IProps) => {
  const { companyData } = useCompanyDetailsQuery();
  const { register, handleSubmit, setValue } = useForm();
  const [info, setInfo] = useState<{ area_id: number }>();
  const { createData, data, loading } = useAddressChangeMutation();

  useEffect(() => {
    setValue('address_change_application_email', companyData.email);
    setValue(
      'address_change_application_phone',
      companyData?.phones?.length ? companyData?.phones[0] : undefined
    );
    setValue('address_change_application_cellphone', companyData.cellphone);
  }, [companyData]);
  useEffect(() => {
    if (data.success) {
      handelClose(false);
      setReload((prev) => !prev);
    }
  }, [data.success, handelClose, setReload]);

  const onSubmit = async (data: any) => {
    let total: number = 0;
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === 'object') {
        total += data[item][0].size / (1024 * 1024);
      }
    });

    if (Math.ceil(total) > 5) {
      showToast(`File size more than 5 mb`, 'error');
      return;
    }

    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === 'object') {
        formData.append(item, data[item][0]);
      } else {
        formData.append(item, data[item]);
      }
    });
    if (info?.area_id) {
      formData.append(
        'address_change_application_area_id',
        String(info?.area_id)
      );
    }

    createData(formData);
  };

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2}>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Address change Email'
              required
              InputLabelProps={{
                shrink: true,
              }}
              id='outlined-required'
              type='email'
              {...register('address_change_application_email')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label='Address change Telephone Number'
              id='outlined-required'
              type='number'
              {...register('address_change_application_cellphone')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label='Address change Mobile Number'
              id='outlined-required'
              type='number'
              {...register('address_change_application_phone')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Addresss'
              InputLabelProps={{
                shrink: true,
              }}
              id='outlined-required'
              placeholder='House/Road no'
              type='text'
              {...register('address_change_application_addressline')}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Civil Aviation Certificate (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('address_change_application_civil_aviation')}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Trade License (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('address_change_application_trade_license')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Hajj License (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('address_change_application_hajj_license')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              size='small'
              fullWidth
              label='Address change postal code'
              required
              id='outlined-required'
              type='number'
              {...register('address_change_application_postal_code')}
            />
          </Grid>
          <AddressSelect
            setInfo={setInfo}
            register={register}
            companyData={companyData}
          />
        </Grid>
        <Box textAlign={'end'} mt={5}>
          {!loading ? (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              size='small'
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator='Loading...'
              variant='contained'
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateAddressChangeApplication;
