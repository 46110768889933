import { useEffect, useState } from 'react';
import { HttpClient } from '../../rest/utils/http';
import { showToast } from '../../Toaster/Toaster';

interface IResponse<T> {
  success: boolean;
  data: T;
  message?: string;
  total?: number;
}

const GetDataApi = <T,>(url: string, isFetchingForSingle?: boolean) => {
  const [response, setResponse] = useState<T | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [total, setTotal] = useState<number | undefined>();

  const getData = async () => {
    setError(null);

    try {
      setLoading(true);
      const res: IResponse<T> = await HttpClient?.get(url);
      if (res.success) {
        setSuccess(true);
        setResponse(res?.data);
        setTotal(res?.total);
        setRefetch(false);
      } else {
        setSuccess(false);
        setError('something went wrong');
        showToast('something went wrong', 'error');
      }
    } catch (err: any) {
      setSuccess(false);
      setError(err?.message);
      if (err?.response?.status === 500) {
        showToast('something went wrong', 'error');
      } else {
        showToast(err?.message, 'error');
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    //refetch is work when isFetchingForSingle is not available, this time you can use getData for refetch
    if (!isFetchingForSingle) {
      if (refetch) {
        getData();
      } else {
        getData();
      }
    }
  }, [refetch]);
  return { response, loading, error, getData, success, setRefetch, total };
};

export default GetDataApi;
