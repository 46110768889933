import React, { useState } from 'react';
import {
  // Typography,
  Divider,
  Card,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CardCivilAviation from '../card-components/CardCompanyCivilAviation';
import AddCivilAviationInfo from '../components/AddCivilAviationInfo';
import { useCompanyCivilAviation } from '../../../rest/useCompanyInfo';
import UpdateCivilAviationInfo from '../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCivilAviationInfo';
const CivilAviationIndex = () => {
  const { companyCivilAviation, isLoading: civilLoading } =
    useCompanyCivilAviation();
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Card>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {companyCivilAviation.certificate_number && (
            <Tooltip title='Update' sx={{ mr: 2 }}>
              <IconButton onClick={() => setToggle(!toggle)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Divider light />

        {toggle ? (
          <UpdateCivilAviationInfo
            companyCivilAviation={companyCivilAviation}
            setToggle={setToggle}
          />
        ) : (
          <>
            {Object.keys(companyCivilAviation).length > 0 ? (
              <CardCivilAviation
                data={companyCivilAviation}
                loading={civilLoading}
              />
            ) : (
              <AddCivilAviationInfo />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default CivilAviationIndex;
