import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { showToast } from '../../../Toaster/Toaster';
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from '@mui/material';
import AddressSelect from '../../address-change-application/AddressSelect';
import { ICompanyDetails } from '../../../rest/types';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateCompanyMutation } from '../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { removeUndefinedAndNull } from '../../../Common/removeUndefinedAndNull';
import MultiplePhoneNumbers from './MultiplePhoneNumbers';

const UpdateCompanyInformation = ({
  companyData,
  setToggle,
}: {
  companyData: ICompanyDetails;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm();
  const [info, setInfo] = useState({
    member_company_name: '',
    police_station: '',
    postalcode: '',
    cellphone: '',
    phone: '',
    addressline1: '',
    member_company_website: '',
    member_company_stablished_date: '',
    member_company_logo: '',
    area_id: '',
    member_company_form17: '',
    member_company_form12: '',
    phones: '',
  });
  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyMutation(data);
    },
    onSuccess: () => {
      showToast('successfully updated', 'success');
      setToggle(false);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_DETAILS],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    const removeData = removeUndefinedAndNull(info);
    const formData = new FormData();
    Object.keys(removeData).forEach((key) => {
      if (key === 'phones') {
        formData.append(key, JSON.stringify(removeData[key]));
      } else {
        formData.append(key, removeData[key]);
      }
    });
    // console.table(Object.fromEntries(formData));
    if (Object.keys(removeData).length === 0) {
      showToast('Please Change any field', 'error');
    } else {
      mutation.mutate(formData);
    }
  };

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Box>
              <Box>
                <FormControl fullWidth variant='outlined' size='small'>
                  <InputLabel
                    sx={{ overflow: 'hidden' }}
                    id='demo-simple-select-label'
                  >
                    Select zone
                  </InputLabel>

                  <Select
                    disabled
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    defaultValue={companyData.zone_id}
                    label='Select zone'
                    placeholder='Select zone'
                  >
                    <MenuItem value={1}>Dhaka</MenuItem>
                    <MenuItem value={2}>Chittagong</MenuItem>
                    <MenuItem value={3}>Sylhet</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mt={2}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  label='Agency Type'
                  inputProps={{
                    readOnly: true,
                  }}
                  defaultValue={companyData.member_company_type}
                  placeholder='Enter Agency type'
                  type='text'
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <TextField
                  required
                  size='small'
                  id='date'
                  type='date'
                  label='Agency established date'
                  defaultValue={
                    companyData?.member_company_stablished_date?.split('T')[0]
                  }
                  onChange={(e) => {
                    setInfo({
                      ...info,
                      member_company_stablished_date: e.target.value,
                    });
                  }}
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  required
                  size='small'
                  id='outlined-basic'
                  label=' Which police station does the Agency belong to?'
                  variant='outlined'
                  defaultValue={companyData.police_station}
                  placeholder='Enter Agency police station'
                  type='text'
                  fullWidth
                  onChange={(e) =>
                    setInfo({ ...info, police_station: e.target.value })
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box>
              <TextField
                required
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Agency Telephone Number'
                placeholder='Agency Telephone Number'
                type='text'
                defaultValue={companyData.cellphone}
                fullWidth
                {...register('cellphone')}
                onChange={(e) =>
                  setInfo({ ...info, cellphone: e.target.value })
                }
              />
            </Box>
            <Box mt={2}>
              <MultiplePhoneNumbers
                info={info}
                setInfo={setInfo}
                companyData={companyData}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <label htmlFor=''></label>
              <TextField
                required
                size='small'
                id='outlined-basic'
                label='Agency Address'
                variant='outlined'
                defaultValue={companyData.addressline1}
                placeholder='House/Road No'
                type='text'
                fullWidth
                {...register('addressline1')}
                onChange={(e) =>
                  setInfo({ ...info, addressline1: e.target.value })
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                required
                size='small'
                id='outlined-basic'
                label='Agency postal code'
                variant='outlined'
                defaultValue={companyData.postalcode}
                placeholder='Enter Agency postal code'
                type='text'
                fullWidth
                onChange={(e) =>
                  setInfo({ ...info, postalcode: e.target.value })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box>
              <Box>
                <TextField
                  size='small'
                  label='Agency logo'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    accept: 'image/jpeg, image/png, image/jpg',
                  }}
                  onChange={(e: any) =>
                    setInfo({ ...info, member_company_logo: e.target.files[0] })
                  }
                  id='outlined-basic'
                  variant='outlined'
                  type='file'
                  fullWidth
                />
              </Box>

              <Box mt={2}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  variant='outlined'
                  defaultValue={companyData.email}
                  label='Agency email address'
                  placeholder='Enter Agency email address'
                  type='email'
                  fullWidth
                  disabled
                />
              </Box>
              <Box mt={2}>
                <TextField
                  required
                  size='small'
                  label='Agency website'
                  id='outlined-basic'
                  defaultValue={companyData.member_company_website}
                  variant='outlined'
                  placeholder='Enter Agency website'
                  type='text'
                  fullWidth
                  {...register('member_company_website')}
                  onChange={(e) =>
                    setInfo({ ...info, member_company_website: e.target.value })
                  }
                />
              </Box>

              {/* {companyData.member_company_type === 'Limited' && (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    size='small'
                    label='Form XII'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required={companyData.member_company_form12 ? false : true}
                    onChange={(e: any) =>
                      setInfo({
                        ...info,
                        member_company_form12: e.target.files[0],
                      })
                    }
                    id='outlined-basic'
                    variant='outlined'
                    type='file'
                    fullWidth
                  />
                </Box>
              )} */}
            </Box>
          </Grid>
          <AddressSelect
            setInfo={setInfo}
            info={info}
            register={register}
            companyData={companyData}
          />
        </Grid>

        {/* <Box mt={2}>
          {companyData.member_company_logo && (
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              <PdfAndImageView
                text={"Download company logo file"}
                file={companyData.member_company_logo}
                color="#00e"
              />
            </Typography>
          )}
        </Box> */}
        <Box sx={{ pt: 2, textAlign: 'end', mb: 2 }}>
          {mutation.isLoading ? (
            <Button
              disabled
              variant='contained'
              disableElevation
              className='add-remove-button'
            >
              Loading..
            </Button>
          ) : adminId.user_member_account_status !== 'active' ? (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              className='add-remove-button'
            >
              Save
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UpdateCompanyInformation;
