import { useMutation, useQuery } from 'react-query';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { HttpClient } from '../../../rest/utils/http';
import {
  GetResponseType,
  IDuplicateCertificate,
  IDuplicateSingle,
} from '../../../rest/types';
import { showToast } from '../../../Toaster/Toaster';

// Fetch multiple duplicate certificates
export const useDuplicateCertificateQuery = (memberId: number) => {
  return useQuery(
    [API_ENDPOINTS.DUPLICATE_CERTIFICATE, memberId],
    async () => {
      const data = await HttpClient.get<
        GetResponseType<IDuplicateCertificate[]>
      >(API_ENDPOINTS.DUPLICATE_CERTIFICATE, {
        duplicate_certificate_application_member_id: memberId,
      });
      return data;
    },
    {
      staleTime: Infinity,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,

      onError: (error) => {
        showToast('Something went wrong', 'error');
      },
    }
  );
};

// Fetch single duplicate certificate by ID
export const useDuplicateSingleQuery = (memberId: number) => {
  return useQuery(
    [API_ENDPOINTS.DUPLICATE_CERTIFICATE, memberId],
    async () =>
      await HttpClient.get<GetResponseType<IDuplicateSingle>>(
        `${API_ENDPOINTS.DUPLICATE_CERTIFICATE}/${memberId}`
      ),
    {
      staleTime: Infinity,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError: (error) => {
        showToast('Something went wrong', 'error');
      },
    }
  );
};

// Mutation for creating a new duplicate certificate
export const useDuplicateCertificateMutation = () => {
  return useMutation(
    (data: any) => HttpClient.post(API_ENDPOINTS.DUPLICATE_CERTIFICATE, data),
    {
      onSuccess: () => {
        showToast(
          'Successfully created Duplicate certificate application',
          'success'
        );
      },
      onError: (error) => {
        console.error('Error creating certificate application:', error);
        showToast('Error creating duplicate certificate', 'error');
      },
    }
  );
};
