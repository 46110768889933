import { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Grid, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../../rest/utils/api-endpoints';
import { useDuplicateCertificateMutation } from './apiEndpoints/apiEndpoints';

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
};

const CreateDuplicateApplication = ({ handelClose }: IProps) => {
  const queryClient = useQueryClient();
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();

  const { mutate, isLoading, isSuccess } = useDuplicateCertificateMutation();
  useEffect(() => {
    if (isSuccess) {
      handelClose(false);
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.DUPLICATE_CERTIFICATE],
      });
    }
  }, [isSuccess]);

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === 'object') {
        formData.append(item, data[item][0]);
      }
    });
    formData.append(
      'duplicate_certificate_application_member_id',
      user.user_member_id!?.toString()
    );
    mutate(formData);
  };

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2} maxWidth={'sm'}>
          <Grid xs={12} md={6} item>
            <TextField
              size='small'
              fullWidth
              label='Previous Certficate (if any, PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('duplicate_certificate_application_memberhsip_card')}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              size='small'
              fullWidth
              label='Duplicate Certificate Application (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register(
                'duplicate_certificate_application_application_file'
              )}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              size='small'
              fullWidth
              label='Tax Return Certificate (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('duplicate_certificate_application_tax_return')}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              size='small'
              fullWidth
              label='Membership Card (Both Sides, PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('duplicate_certificate_application_trade_license')}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              size='small'
              fullWidth
              label='Trade License (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('duplicate_certificate_application_trade_license')}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              size='small'
              fullWidth
              label='Civil Aviation Certificate (PDF)'
              required
              inputProps={{
                accept: 'application/pdf',
              }}
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('duplicate_certificate_application_civil_aviation')}
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          {!isLoading ? (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              size='small'
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator='Loading...'
              variant='contained'
              disabled
              size='small'
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateDuplicateApplication;
