import { useQuery } from 'react-query';
import { HttpClient } from '../../../../rest/utils/http';
import { API_ENDPOINTS } from '../../../../rest/utils/api-endpoints';
import {
  ICertificate,
  ICertificateResponse,
} from '../../../../Types/UpdateInfotypes/CompanyInformation';

const fetchAllCertificate = async (id: number) => {
  const fetchData = await HttpClient.get<ICertificateResponse>(
    `${API_ENDPOINTS.CERTIFICATE}/${id}?c_type=MEMBERSHIP_CERTIFICATE`
  );
  return fetchData;
};

// query
export function useAllCertificateQuery(id: number) {
  const { data, isLoading, error, refetch } = useQuery<
    ICertificateResponse,
    Error
  >([API_ENDPOINTS.CERTIFICATE, { id }], () => fetchAllCertificate(id), {
    refetchOnWindowFocus: false,
  });
  return {
    certificate: data?.data ?? ([] as ICertificate[]),
    isLoading,
    error,
    refetch,
  };
}
