import { useState } from 'react';
import ModalsWrapper from '../../../Components/Modals/ModalsWrapper';
import GetDataApi from '../../../Common/FetchData/GetDataApi';
import { IVoucherSingleView } from '../type/payment_type';
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import TableLoader from '../../../Components/Spinner/TableLoader';
import TableNoDataMessage from '../../../Components/TableMessage/TableNoDataMessage';
import moment from 'moment';
const ViewAllVoucher = ({ voucherId }: { voucherId: number }) => {
  const [open, setOpen] = useState(false);
  // Fetch voucher data on button click
  const { response, setRefetch, getData } = GetDataApi<IVoucherSingleView>(
    `/api/atab/invoice/get/single/${voucherId}`,
    true
  );
  const [voucherData, setVoucherData] = useState<IVoucherSingleView | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const handleOpen = async () => {
    getData();
    setOpen(true);
  };

  return (
    <>
      <Button variant='text' color='secondary' onClick={handleOpen}>
        View
      </Button>
      <Box>
        <ModalsWrapper
          modalData={{
            title: (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '20px',
                }}
              >
                Voucher Details
              </Box>
            ),
          }}
          showModal={open}
          setShowModal={setOpen}
        >
          <Box sx={{ width: '100%', maxWidth: '1000px', pl: 2, pr: 2 }}>
            <TableContainer>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell size='small' align='center'>
                      SL no
                    </TableCell>
                    <TableCell align='center' size='small'>
                      Voucher for
                    </TableCell>
                    <TableCell align='center' size='small'>
                      Year
                    </TableCell>
                    <TableCell align='center' size='small'>
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableLoader />
                  ) : (
                    <>
                      {response?.invoiceItems.length ? (
                        <>
                          {response?.invoiceItems?.map((sPayment, index) => (
                            <TableRow
                              key={sPayment.atab_payment_invoice_item_id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align='center' size='small'>
                                {index + 1}
                              </TableCell>
                              <TableCell align='center' size='small'>
                                {sPayment?.atab_payment_invoice_item_name}
                              </TableCell>

                              <TableCell align='center' size='small'>
                                {`${sPayment.atab_payment_invoice_item_start_year}-${sPayment.atab_payment_invoice_item_end_year}`}
                              </TableCell>
                              <TableCell align='center' size='small'>
                                {Number(
                                  sPayment.atab_payment_invoice_item_amount
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      ) : (
                        <TableNoDataMessage />
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container rowGap={1}>
              <Grid item xs={12}>
                <Divider sx={{ mt: 4 }} />
              </Grid>
              <Grid item xs={6} sx={{}}>
                <Typography variant='h5' component='h2'>
                  Total Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                : {response?.atab_payment_invoice_grand_total} tk
              </Grid>
              <Grid item xs={6} sx={{}}>
                <Typography variant='h5' component='h2'>
                  Due
                </Typography>
              </Grid>
              <Grid item xs={6}>
                :{' '}
                <Box sx={{ color: 'red', display: 'inline-block' }}>
                  {response?.due}
                </Box>{' '}
                tk
              </Grid>
              <Grid item xs={6} sx={{}}>
                <Typography variant='h5' component='h2'>
                  Email
                </Typography>
              </Grid>
              <Grid item xs={6}>
                : {response?.user_member_email}
              </Grid>
              <Grid item xs={6} sx={{}}>
                <Typography variant='h5' component='h2'>
                  Date
                </Typography>
              </Grid>
              <Grid item xs={6}>
                :{' '}
                {moment(response?.atab_payment_invoice_issue_date).format(
                  'DD-MMM-YYYY'
                )}
              </Grid>
              <Grid item xs={6} sx={{}}>
                <Typography variant='h5' component='h2'>
                  Phone Number
                </Typography>
              </Grid>
              <Grid item xs={6}>
                : {response?.user_member_phone}
              </Grid>
            </Grid>
          </Box>
        </ModalsWrapper>
      </Box>
    </>
  );
};

export default ViewAllVoucher;
