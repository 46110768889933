import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { getToken } from '../rest/utils/get-token';
import { url } from '../Helpers/utils/Constant';
import { IconButton } from '@mui/material';
import { showToast } from '../Toaster/Toaster';

type Member = {
  user_member_id: number;
  user_member_id_card_number: string;
  user_member_representative_name: string | null;
  user_member_company_name: string;
  user_member_phone: string;
  user_member_representative_photo: string | null;
  user_member_account_status: string;
  user_member_zone_name: string;
  user_member_zone_id: number;
  user_member_hajj_license: string;
  company_owner_name: string | null;
};

const fetchData = async (name?: string) => {
  const token = getToken();
  try {
    const response = await fetch(
      name
        ? `${url}/api/atab/user-member/search-member/by-company/${name}?status=active&zone=0`
        : `${url}/api/atab/user-member/get/all/by/date/zone/status/for-admin?status=active&skip=0&limit=30`,
      {
        method: 'GET', // or POST, PUT, etc. depending on your API
        headers: {
          'Content-Type': 'application/json', // Ensure you're sending JSON
          Authorization: `Bearer ${token}`, // Replace with your token
        },
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error(error);
    return []; // Return an empty array in case of an error
  }
};

export default function SearchCompanyByLicenseNo({
  register,
  seTMemberId,
  setValue,
  multiple,
  setWholeValue,
  label,
  variant,
  placeholder,
}: {
  register?: UseFormRegister<FieldValues>;
  seTMemberId?: React.Dispatch<React.SetStateAction<any>>;
  setValue?: UseFormSetValue<FieldValues>;
  multiple?: boolean;
  setWholeValue?: any;
  label?: any;
  placeholder?: any;
  variant?: 'filled' | 'outlined' | 'standard';
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState<Member[]>([]);

  const debounce = (fun: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (arg: any) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fun(arg);
      }, delay);
    };
  };
  const searchTerms = debounce(async (arg: any) => {
    const data = await fetchData(arg); // Fetch data based on input
    setLoading(false);
    setUsers(data);
  }, 500);
  const handleOpen = (event: any /* React.ChangeEvent<HTMLInputElement> */) => {
    const searchTerm = event.target.value;
    setOpen(true);
    setLoading(true);
    if (searchTerm) {
      searchTerms(searchTerm);
    } else {
      if (users.length === 0) {
        fetchData().then((data) => {
          setUsers(data);
          setLoading(false);
        });
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setUsers([]);
  };
  const handleChange = (event: any, value: any /* Member | Member[] */) => {
    if (multiple && Array.isArray(value)) {
      const member_ids = value.map((member: Member) => member.user_member_id);
      seTMemberId && seTMemberId(member_ids); // Handle array of Member
      setValue && setValue('member_id', member_ids);
      setWholeValue && setWholeValue(value);
    } else if (value && typeof value === 'object' && !Array.isArray(value)) {
      seTMemberId && seTMemberId((value as Member).user_member_id);
      setValue && setValue('member_id', (value as Member).user_member_id); // Handle single Member object
      setWholeValue && setWholeValue(value);
    }
  };
  return (
    <Autocomplete
      size='small'
      fullWidth
      filterOptions={(x) => x}
      open={open}
      freeSolo
      multiple={multiple}
      onChange={handleChange}
      onOpen={handleOpen}
      onClose={handleClose}
      isOptionEqualToValue={(option: Member, value: Member) =>
        option?.user_member_company_name === value?.user_member_company_name
      }
      getOptionLabel={(option: any) => option?.user_member_company_name || 'kk'}
      options={users || []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant || 'standard'}
          onChange={handleOpen} // Update onChange to call handleOpen
          label={label || ''}
          placeholder={placeholder || 'Type License Number'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {/* clear icon access */}
                <span onClick={() => setWholeValue({})}>
                  {params.InputProps.endAdornment}
                </span>
              </React.Fragment>
            ),
          }}
          defaultValue={'dsfa'}
        />
      )}
    />
  );
}
