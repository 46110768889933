import React, { useState } from 'react';
import { Grid, Button, TextField, Tooltip, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import { showToast } from '../../../Toaster/Toaster';
import { ICompanyProposer } from '../../../rest/types';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateCompanyProposerMutation } from '../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import SearchCompanyByLicenseNo from '../../../Common/SearchCompanyByLicenseNo';

interface IProps {
  memberId: number;
  allData: ICompanyProposer[];
  companyProposer: ICompanyProposer;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder: string;
  index: number;
}

const UpdateCompanyProposer = ({
  memberId,
  companyProposer,
  setToggle,
  placeholder,
  index,
  allData,
}: IProps) => {
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const { handleSubmit } = useForm();
  const queryClient = useQueryClient();
  const [proposerInput1, setProposerInput1] = useState<{
    user_member_id: number;
    user_member_hajj_license: number;
    company_owner_email: string;
    company_owner_phone: string;
    company_owner_designation: string;
    company_owner_name: string;
  }>();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyProposerMutation(data);
    },
    onSuccess: () => {
      showToast('successfully updated', 'success');
      setToggle(false);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_PROPOSER_UPDATE],
      });
    },
  });

  const onSubmit = async (inputData: any) => {
    if (
      allData?.some(
        (item) => item.proposer_request_to === proposerInput1?.user_member_id
      )
    ) {
      showToast('Already exist this Proposer', 'error');
    } else {
      inputData.proposer_request_id = companyProposer.proposer_request_id;
      inputData.proposer_request_from = memberId;
      inputData.proposer_request_to = proposerInput1?.user_member_id;
      mutation.mutate(inputData);
    }
  };
  return (
    <Box
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      p={2}
      mb={1}
      sx={{ position: 'relative' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container rowGap={1}>
            <Grid xs={12}>
              <label htmlFor=''>{index ? 'Second' : 'First'} Proposer</label>
              <Box>
                <SearchCompanyByLicenseNo
                  placeholder={placeholder}
                  setWholeValue={(e: any) => {
                    setProposerInput1(e);
                    // if (index) {
                    //   setProposerId({
                    //     ...proposerId,
                    //     proposer2: e.user_member_id,
                    //   });
                    // } else {
                    //   setProposerId({
                    //     ...proposerId,
                    //     proposer1: e.user_member_id,
                    //   });
                    // }
                  }}
                />
              </Box>
            </Grid>
            {proposerInput1?.user_member_id && (
              <>
                <Grid xs={12}>
                  <TextField
                    variant='standard'
                    disabled
                    defaultValue={proposerInput1?.user_member_hajj_license}
                    size='small'
                    label='Hajj License Number'
                    id='outlined-basic'
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    disabled
                    defaultValue={proposerInput1?.company_owner_name}
                    size='small'
                    label="License Owner's Name"
                    id='outlined-basic'
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    disabled
                    defaultValue={proposerInput1?.company_owner_designation}
                    size='small'
                    label="Owner's Designation"
                    id='outlined-basic'
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    disabled
                    defaultValue={proposerInput1?.company_owner_phone}
                    size='small'
                    label="Owner's Mobile Number"
                    id='outlined-basic'
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    disabled
                    defaultValue={proposerInput1?.company_owner_email}
                    size='small'
                    label="Owner's Email Address"
                    id='outlined-basic'
                    variant='standard'
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
        <Tooltip title='Update' sx={{ mr: 2 }}>
          <IconButton onClick={() => setToggle((prv) => !prv)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            Loading..
          </Button>
        ) : adminId.user_member_account_status !== 'active' ? (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            Save
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyProposer;
