import { Divider, Card } from '@mui/material';
import { useCompanyOwnerQuery } from '../../../rest/useCompanyInfo';
import CardCompanyOwner from '../card-components/CardCompanyOwner';
import { AddCompanyOwner } from '../components/AddCompanyOwner';
const CompanyOwnerIndex = () => {
  const { companyOwnerData, isLoading } = useCompanyOwnerQuery();

  return (
    <>
      <Card>
        <Divider light />
        {companyOwnerData?.length > 0 ? (
          <CardCompanyOwner data={companyOwnerData} loading={isLoading} />
        ) : (
          <AddCompanyOwner />
        )}
      </Card>
    </>
  );
};

export default CompanyOwnerIndex;
