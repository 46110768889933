import { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import CompanyInfo from '../Components/Dashboard/info/CompanyInfo';
import DashborHomeToaster from '../Toaster/DashborHomeToaster';
import MemberEvents from '../Components/Dashboard/MemberEvents/MemberEvents';
import Services from '../Components/Dashboard/Services/Services';
import ModalsWrapper from '../Components/Modals/ModalsWrapper';
import ServiceModal from '../Components/Dashboard/Services/ServiceModal';
import MemberNotice from '../Components/Dashboard/MemberNotice/MemberNotice';
import Meeting from '../Components/Dashboard/Meeting/Meeting';
import { useDashboardQuery } from '../Components/Dashboard/api/dashboardEndPoint';

const DashboardHome = () => {
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const [open, setOpen] = useState(false);
  const { dashboardData, isLoading } = useDashboardQuery(
    adminId?.user_member_id
  );

  return (
    <Box mt={5}>
      <div className='dashboard-main-section'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {/* 1st column start */}
            <Grid item xs={12} sm={6}>
              <Grid container rowGap={2}>
                <Grid item xs={12} sm={12}>
                  <div
                    className='box-container' /*  style={{ minHeight: 500 }} */
                  >
                    <h2>Notice Board</h2>
                    <MemberNotice />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={0}>
                  <div className='box-container'>
                    <h3>Upcoming meeting</h3>
                    <div className='payment-info mt-4'>
                      <div className=''>
                        <Meeting />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className='box-container'>
                    <h3>Upcoming events</h3>
                    <div className='payment-info mt-4'>
                      <div className=''>
                        <MemberEvents />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* 2nd column start */}
            <Grid item xs={12} sm={6}>
              <Grid container rowGap={2}>
                <Grid item xs={12} sm={12} md={0}>
                  <CompanyInfo memberInfo={dashboardData} loading={isLoading} />
                </Grid>{' '}
                <Grid item xs={12} sm={12}>
                  {dashboardData.user_member_due_payment ? (
                    <DashborHomeToaster
                      message='Please complete payments'
                      pathName='payments'
                    />
                  ) : (
                    ''
                  )}
                  <div className='box-container'>
                    <h2>Payment Information</h2>
                    <div className='payment-info mt-3'>
                      <ul>
                        <li>
                          <span>Total Dues</span> :
                          <p>
                            {dashboardData?.user_member_due_payment
                              ? dashboardData?.user_member_due_payment
                              : '0.00'}
                          </p>{' '}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className='box-container mt-3'>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h3>Get services</h3>
                      {adminId?.user_member_account_status === 'active' ? (
                        <Button
                          onClick={() => setOpen(true)}
                          variant='contained'
                          size='small'
                        >
                          Request for service
                        </Button>
                      ) : (
                        ''
                      )}
                    </Box>
                    <div className='payment-info mt-4'>
                      <div className=''>
                        <Services />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>

      <ModalsWrapper
        modalData={{ title: 'Request for service' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <ServiceModal handleClose={() => setOpen(false)} />
      </ModalsWrapper>
    </Box>
  );
};

export default DashboardHome;
