import React, { useState } from 'react';
import { Grid, Button, TextField, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { imgUrl } from '../../../Helpers/utils/Constant';
import { showToast } from '../../../Toaster/Toaster';
import { ICompanyCivilAviation } from '../../../rest/types';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { UpdateCompanyCivilAviationMutation } from '../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation';
import { removeUndefinedAndNull } from '../../../Common/removeUndefinedAndNull';
import moment from 'moment';

interface IDate {
  certificate_expiry_date: string;
  certificate_issue_date: string;
}

interface IProps {
  companyCivilAviation: ICompanyCivilAviation;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}
const UpdateCivilAviationInfo = ({
  companyCivilAviation,
  setToggle,
}: IProps) => {
  const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const { register, handleSubmit } = useForm();
  const queryClient = useQueryClient();

  const [info, setInfo] = useState({
    certificate_expiry_date: '',
    certificate_issue_date: '',
    certificate_file_bn: '',
    certificate_file: '',
    certificate_number: '',
  });
  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyCivilAviationMutation(data);
    },
    onSuccess: (data: any) => {
      showToast('successfully updated', 'success');
      setToggle(false);
    },
    onError: (error: any) => {
      console.log(error.response.data.message);
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_CIVIL_AVIATION],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    const removeData = removeUndefinedAndNull(info);
    Object.keys(removeData).forEach((key) => {
      formData.append(key, removeData[key]);
    });
    // console.table(Object.fromEntries(formData));
    if (Object.keys(removeData).length === 0) {
      showToast('Please Change any field', 'error');
    } else {
      mutation.mutate(formData);
    }
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Certificate number'
                placeholder='Certificate number'
                fullWidth
                defaultValue={companyCivilAviation.certificate_number}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    certificate_number: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='date'
                type='date'
                label='Issue date'
                defaultValue={moment(
                  companyCivilAviation.certificate_issue_date
                )?.format('YYYY-MM-DD')}
                placeholder='Issue date'
                onChange={(e) =>
                  setInfo({
                    ...info,
                    certificate_issue_date: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='date'
                placeholder='Expiry date'
                label='Expiry date'
                type='date'
                defaultValue={moment(
                  companyCivilAviation.certificate_expiry_date
                )?.format('YYYY-MM-DD')}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    certificate_expiry_date: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                label='Upload Certificate Bangla (pdf)'
                placeholder='Upload Certificate'
                variant='outlined'
                type='file'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    certificate_file_bn: e.target.files[0],
                  })
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                label='Upload Certificate English (pdf)'
                placeholder='Upload Certificate'
                variant='outlined'
                type='file'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    certificate_file: e.target.files[0],
                  })
                }
              />
            </Box>

            {/* <a
              href={`${imgUrl}/${companyCivilAviation.certificate_file}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              Download certificate file
            </a> */}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Loading..
          </Button>
        ) : adminId.user_member_account_status !== 'active' ? (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Save
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCivilAviationInfo;
