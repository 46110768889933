import React, { useState } from 'react';
import { Grid, Button, TextField, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { showToast } from '../../../Toaster/Toaster';
import { ICompanyownerPassport } from '../../../rest/types';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { UpdateCompanyOwnerPassportMutation } from '../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation';
import { removeUndefinedAndNull } from '../../../Common/removeUndefinedAndNull';
import moment from 'moment';

interface IProps {
  companyOwnerpass: ICompanyownerPassport;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}
const UpdatePassport = ({ companyOwnerpass, setToggle }: IProps) => {
  const { register, handleSubmit } = useForm();
  const queryClient = useQueryClient();

  const [info, setInfo] = useState({
    expiry_date: '',
    issue_date: '',
    passport_file: '',
    passport_number: '',
  });
  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyOwnerPassportMutation(data);
    },
    onSuccess: (data: any) => {
      setToggle(false);
      showToast('successfully updated', 'success');
    },
    onError: (error: any) => {
      console.log(error.response.data.message);
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_PASSPORT],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    const removeData = removeUndefinedAndNull(info);
    Object.keys(removeData).forEach((key) => {
      formData.append(key, removeData[key]);
    });
    // console.table(Object.fromEntries(formData));
    if (Object.keys(removeData).length === 0) {
      showToast('Please Change any field', 'error');
    } else {
      mutation.mutate(formData);
    }
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                label='Passport number'
                placeholder='Passport number'
                fullWidth
                defaultValue={companyOwnerpass.passport_number}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    passport_number: e.target.value,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='date'
                type='date'
                label='Issue date'
                defaultValue={moment(companyOwnerpass.issue_date)?.format(
                  'YYYY-MM-DD'
                )}
                placeholder='Issue date'
                onChange={(e) =>
                  setInfo({
                    ...info,
                    issue_date: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {' '}
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='date'
                placeholder='Expiry date'
                label='Expiry date'
                type='date'
                defaultValue={moment(companyOwnerpass.expiry_date)?.format(
                  'YYYY-MM-DD'
                )}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    expiry_date: e.target.value,
                  })
                }
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                size='small'
                id='outlined-basic'
                label='Owner passport (pdf)'
                placeholder='Upload passport'
                variant='outlined'
                type='file'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: 'application/pdf',
                }}
                onChange={(e: any) =>
                  setInfo({
                    ...info,
                    passport_file: e.target.files[0],
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
            size='small'
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdatePassport;
