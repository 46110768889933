import { useMutation, useQueryClient } from 'react-query';
import { HttpClient } from '../../../rest/utils/http';
import { showToast } from '../../../Toaster/Toaster';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';

export const useOwnerInformationUpateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ data, id }: { data?: any; id?: number }) =>
      HttpClient.patch(
        `/api/atab/member-company/member/owner/${id || ''}`,
        data
      ),
    {
      onSuccess: () => {
        showToast('Successfully updated', 'success');
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.OWNER_DETAILS],
        });
      },
      onError: (error) => {
        showToast('Something went wrong', 'error');
      },
    }
  );
};
export const useAddOwnerInformationMutation = () => {
  return useMutation(
    ({ data }: { data?: any }) =>
      HttpClient.post(`/api/atab/member-company/member/owner`, data),
    {
      onSuccess: () => {
        showToast('Successfully Created', 'success');
      },
      onError: (error) => {
        showToast('Something went wrong', 'error');
      },
    }
  );
};
