import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const ConfirmDelete = ({
  confirmDelete,
}: {
  confirmDelete: () => Promise<void>;
}) => {
  const [open, setOpen] = useState(false);

  // Close the confirmation dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handle the delete action
  const handleDelete = () => {
    // deleteData(id);
    confirmDelete();
    console.log('item deleted');
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <DeleteForeverIcon
          style={{ color: 'red', cursor: 'pointer' }}
          fontSize='small'
          onClick={() => setOpen(true)}
        />
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          sx: {
            backgroundColor: 'white', // Ensure default white background
          },
        }}
      >
        <DialogTitle id='alert-dialog-title'>{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this item? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => handleDelete()} color='error' autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDelete;
