import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  Button,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableNoDataMessage from '../../../../Components/TableMessage/TableNoDataMessage';
import TableLoader from '../../../../Components/Spinner/TableLoader';
import ModalsWrapper from '../../../../Components/Modals/ModalsWrapper';
import WalletForm from './WalletForm';
import { useEffect, useState } from 'react';
import GetDataApi from '../../../../Common/FetchData/GetDataApi';
import ConfirmDelete from '../../../../Common/ConfirmDelete';
import WalletEdit from './WalletEdit';
import DeleteDataApi from '../../../../Common/FetchData/DeleteDataApi';
import { useAuthContext } from '../../../../Context/AuthContext/AuthContext';
const WalletList = () => {
  const { user } = useAuthContext();
  const [walletOpen, setWalletOpen] = useState(false);
  const { response, setRefetch, loading } = GetDataApi<IWalletAccount[]>(
    '/api/atab/beneficiary/mobile-wallet'
  );
  const { deleteData, success, setSuccess } = DeleteDataApi(
    '/api/atab/beneficiary/mobile-wallet'
  );
  useEffect(() => {
    if (success) {
      setRefetch(true);
      setSuccess(false);
    }
  }, [success]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}>
            Wallet Information
          </Typography>
        }
        action={
          user?.is_applied &&
          user?.user_member_account_status !== 'inactive' &&
          user?.user_member_account_status !== 'rejected' ? (
            <Button
              variant='outlined'
              size='small'
              onClick={() => setWalletOpen(true)}
            >
              Add Wallet Account
            </Button>
          ) : (
            <></>
          )
        }
      />
      <Divider />

      <Box>
        {/* <TabPanel value={value}> */}
        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell size='small' align='center'>
                  Serial no
                </TableCell>
                <TableCell align='center' size='small'>
                  Account Holder Name
                </TableCell>
                <TableCell align='center' size='small'>
                  Mobile Number
                </TableCell>
                <TableCell align='center' size='small'>
                  Mobile Operator Name
                </TableCell>
                <TableCell align='center' size='small'>
                  Account Type
                </TableCell>
                <TableCell align='center' size='small'>
                  Wallet Type
                </TableCell>
                <TableCell align='center' size='small'>
                  Status
                </TableCell>
                <TableCell align='center' size='small'>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {response?.length ? (
                    <>
                      {response?.map((sPayment, index) => (
                        <TableRow
                          key={sPayment.id}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align='center' size='small'>
                            {index + 1}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.account_holder_name}
                          </TableCell>

                          <TableCell align='center' size='small'>
                            {sPayment.mobile_number}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.mobile_operator_name}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.account_type}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.wallet_type}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            {sPayment.is_active ? (
                              <Button
                                size='small'
                                variant='text'
                                color='success'
                              >
                                Active
                              </Button>
                            ) : (
                              <Button size='small' variant='text' color='error'>
                                InActive
                              </Button>
                            )}
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              paddingTop: 1,
                              paddingBottom: 1,
                            }}
                          >
                            <WalletEdit
                              setRefetch={setRefetch}
                              data={sPayment}
                            />
                            <ConfirmDelete
                              confirmDelete={() => deleteData(sPayment.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableNoDataMessage />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ModalsWrapper
        modalData={{ title: 'Add your Wallet information' }}
        showModal={walletOpen}
        setShowModal={setWalletOpen}
      >
        <WalletForm setBankOpen={setWalletOpen} setRefetch={setRefetch} />
      </ModalsWrapper>
    </Card>
  );
};

export default WalletList;
export interface IWalletAccount {
  id: number;
  account_holder_name: string;
  mobile_number: string;
  mobile_operator_name: string;
  account_type: string;
  wallet_type: string;
  user_member_id: number;
  is_active: number;
  created_at: string;
}
