import { useState } from 'react';
import { IInvoiceList } from '../../../type/payment_type';
import { useSearchParams } from 'react-router-dom';
import DBankPayment from './DBankPayment';
import DWalletPayment from './DWalletPayment';
const DirectPayment = ({
  singleInvoice,
  setSingleInvoice,
  setRefetch,
  setManualPayment,
}: {
  singleInvoice: IInvoiceList | undefined;
  setSingleInvoice: React.Dispatch<
    React.SetStateAction<IInvoiceList | undefined>
  >;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  setManualPayment: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [beneficiaryType, setBeneficiaryType] = useState(
    searchParams.get('beneficiaryType') || 'bank'
  );
  return (
    <>
      {beneficiaryType === 'bank' ? (
        <DBankPayment
          singleInvoice={singleInvoice}
          setSingleInvoice={setSingleInvoice}
          setRefetch={setRefetch}
          setManualPayment={setManualPayment}
          setBeneficiaryType={setBeneficiaryType}
        />
      ) : (
        <DWalletPayment
          singleInvoice={singleInvoice}
          setSingleInvoice={setSingleInvoice}
          setRefetch={setRefetch}
          setManualPayment={setManualPayment}
          setBeneficiaryType={setBeneficiaryType}
        />
      )}
    </>
  );
};
export default DirectPayment;
