import React, { useState } from 'react';
import { Divider, Card, Box, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useCompanyOwnerPassport } from '../../../rest/useCompanyInfo';
import AddPassport from '../components/AddPassport';
import CardPassport from '../card-components/CardPassport';
import UpdatePassport from '../../CompanyUpdateInfo/UpdateCompanyInformation/UpdatePassport';
const OwnerPassport = () => {
  const { companyOwnerpass, isLoading: civilLoading } =
    useCompanyOwnerPassport();
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Card>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {companyOwnerpass?.passport_number && (
            <Tooltip title='Update' sx={{ mr: 2 }}>
              <IconButton onClick={() => setToggle(!toggle)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Divider light />

        {toggle ? (
          <UpdatePassport
            companyOwnerpass={companyOwnerpass}
            setToggle={setToggle}
          />
        ) : (
          <>
            {Object.keys(companyOwnerpass).length > 0 ? (
              <CardPassport data={companyOwnerpass} loading={civilLoading} />
            ) : (
              <AddPassport />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default OwnerPassport;
