import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import axios from 'axios';
import { url } from '../../Helpers/utils/Constant';
import { IConversation } from '../../Types/ConversationTypes/ConversationTypes';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { Card } from '@mui/material';
import ComplainDetailsPage from './ComplainDetailsPage';
const ViewComplain = () => {
  const { user } = useAuthContext();
  const [supportShow, setSupportShow] = useState<boolean>(false);
  const [conversation, setConversation] = useState<IConversation>(
    {} as IConversation
  );

  useEffect(() => {
    if (supportShow && !conversation?.support_conversation_id) {
      (async () => {
        const convo = await axios.post(
          `${url}/api/atab/support-conversation/create-or-update-conversation`,
          {
            support_conversation_member_id: user.user_member_id!,
          }
        );
        if (convo.data.success) {
          setConversation(convo.data.data);
        }
      })();
    }
  }, [
    supportShow,
    conversation?.support_conversation_id,
    user.user_member_id!,
  ]);

  return (
    <Box sx={{ mt: 5 }}>
      <Card>
        <ComplainDetailsPage />
      </Card>
    </Box>
  );
};

export default ViewComplain;
