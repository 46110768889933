import { Box, Grid, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { useEffect } from 'react';
import { useAgencyInfoUpdateApplication } from '../../rest/useAgencyInfoUpdateApplication';
import { showToast } from '../../Toaster/Toaster';
import { useCompanyOwnerQuery } from '../../rest/useCompanyInfo';

type IProps = {
  handleClose: () => void;
};

const AddAgencyIfoModal = ({ handleClose }: IProps) => {
  const { companyOwnerData } = useCompanyOwnerQuery();
  const mainOwnerId = companyOwnerData?.find(
    (item) => item.company_owner_is_contact_person === 1
  );

  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const { isLoading, mutate, isSuccess } = useAgencyInfoUpdateApplication();

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  const onSubmit = async (data: any) => {
    let isData = false;
    Object.keys(data).forEach((item) => {
      if (item === 'member_company_logo') {
        if (data[item].length) {
          isData = true;
        }
      } else {
        if (data[item]) {
          isData = true;
        }
      }
    });

    if (isData) {
      data.agency_member_id = user.user_member_id!;
      const formData = new FormData();
      Object.keys(data).forEach((item) => {
        if (typeof data[item] === 'object') {
          if (data[item][0]) {
            formData.append(item, data[item][0]);
          }
        } else {
          formData.append(item, data[item]);
        }
      });
      mutate(formData);
    } else {
      showToast(`You don't fill any input field `, 'error');
    }
  };

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2} maxWidth={'sm'}>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Owner Name'
              id='outlined-required'
              InputLabelProps={{
                shrink: true,
              }}
              type='url'
              disabled
              value={mainOwnerId?.company_owner_name || ''}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Owner Designation'
              id='outlined-required'
              InputLabelProps={{
                shrink: true,
              }}
              type='url'
              disabled
              value={mainOwnerId?.company_owner_designation || ''}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              label='Updated Logo (JPG/JPEG/PNG)'
              InputLabelProps={{
                shrink: true,
              }}
              id='outlined-required'
              type='file'
              {...register('member_company_logo')}
              inputProps={{
                accept: '.jpg, .jpeg, .png', // restricts to JPG, JPEG, and PNG
              }}
              required
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              label='Owner Updated Photo (JPG/JPEG/PNG)'
              InputLabelProps={{
                shrink: true,
              }}
              id='outlined-required'
              type='file'
              {...register('user_member_representative_photo')}
              inputProps={{
                accept: '.jpg, .jpeg, .png', // restricts to JPG, JPEG, and PNG
              }}
              required
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Updated Mobile Number'
              id='outlined-required'
              type='number'
              {...register('phone')}
              required
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Updated Telephone Number'
              id='outlined-required'
              type='number'
              {...register('cellphone')}
              required
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <TextField
              size='small'
              fullWidth
              label='Updated Website'
              id='outlined-required'
              type='url'
              {...register('member_company_website')}
              required
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          {!isLoading ? (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              size='small'
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator='Loading...'
              variant='contained'
              fullWidth
              disabled
              size='small'
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AddAgencyIfoModal;
