import React from 'react';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import Typography from '@mui/material/Typography';
import TableLoader from '../../Components/Spinner/TableLoader';
import { useAllProposerRequestQuery } from '../proposer-receiver/api/proposerEndPoint';

const ProposerRequest = () => {
  const { user } = useAuthContext();
  const [value, setValue] = React.useState('pending');
  const { requester, isLoading } = useAllProposerRequestQuery(
    user.user_member_id!,
    value
  );

  return (
    <div>
      {' '}
      <h2>Proposer request you sent</h2>
      <>
        <Grid pt={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                width: '100%',
                typography: 'body1',
              }}
            >
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <TabList
                    onChange={(e: any, newValue: any) => setValue(newValue)}
                    aria-label='lab API tabs example'
                  >
                    <Tab label='pending' value='pending' />
                    <Tab label='accepted' value='accepted' />
                    <Tab label='rejected' value='rejected' />
                  </TabList>
                </Box>
                {isLoading ? (
                  <TableLoader />
                ) : (
                  <>
                    {requester.length ? (
                      <Box>
                        <TabPanel value={value}>
                          <TableContainer component={Paper}>
                            <Table aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell align='center'>
                                    Request id
                                  </TableCell>
                                  <TableCell align='center'>
                                    Company name
                                  </TableCell>
                                  <TableCell align='center'>Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {requester.map((sRequester) => {
                                  const {
                                    proposer_request_id,
                                    proposer_request_to_company_name,
                                    proposer_request_status,
                                  } = sRequester;
                                  return (
                                    <TableRow
                                      key={proposer_request_id}
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell align='center'>
                                        {proposer_request_id}
                                      </TableCell>
                                      <TableCell align='center'>
                                        {proposer_request_to_company_name}
                                      </TableCell>
                                      <TableCell align='center'>
                                        <p
                                          className={
                                            proposer_request_status ===
                                            'accepted'
                                              ? 'approved'
                                              : proposer_request_status ===
                                                'pending'
                                              ? 'pending'
                                              : 'reject'
                                          }
                                        >
                                          {proposer_request_status}
                                        </p>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                      </Box>
                    ) : (
                      <Box pt={6} textAlign={'center'}>
                        <Typography variant='h5' gutterBottom>
                          No requester yet!
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </>
    </div>
  );
};

export default ProposerRequest;
