import React, { useState } from "react";
import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { imgUrl } from "../../../Helpers/utils/Constant";
import { showToast } from "../../../Toaster/Toaster";
import { ICompanyLicense } from "../../../rest/types";
import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { UpdateCompanyTradeLicenseMutation } from "../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation";
import { removeUndefinedAndNull } from "../../../Common/removeUndefinedAndNull";
import moment from "moment";
import PdfAndImageView from "../../../Common/PdfAndImageView";

interface IProps {
  companyLicense: ICompanyLicense;
  memberId: number;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateCompanyTradeLicense = ({ companyLicense, setToggle }: IProps) => {
  const adminId = JSON.parse(localStorage.getItem("auth") as any);
  const nextYaer = moment(companyLicense.company_trade_license_expiry_date)
    .add(1, "year")
    .format("YYYY-MM-DD");
  const currentYear = moment(
    companyLicense.company_trade_license_expiry_date
  ).format("YYYY-MM-DD");
  const [info, setInfo] = useState({
    company_trade_license_file: "",
    company_trade_license_expiry_date: "",
    company_trade_license: "",
  });
  const { register, handleSubmit } = useForm();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyTradeLicenseMutation(data);
    },
    onSuccess: () => {
      showToast("successfully updated", "success");
      setToggle(false);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_LICENSE],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    const removeData = removeUndefinedAndNull(info);

    Object.keys(removeData).forEach((key) => {
      formData.append(key, removeData[key]);
    });
    // console.table(Object.fromEntries(formData));
    if (Object.keys(removeData).length === 0) {
      showToast("Please Change any field", "error");
    } else {
      mutation.mutate(formData);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                label="Enter trade license number "
                type="text"
                disabled={nextYaer === currentYear ? false : true}
                defaultValue={companyLicense.company_trade_license}
                fullWidth
                sx={{ pb: 2 }}
                {...register("company_trade_license")}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    company_trade_license: String(e.target.value),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size="small"
                id="date"
                type="date"
                defaultValue={moment(
                  companyLicense.company_trade_license_expiry_date
                ).format("YYYY-MM-DD")}
                disabled={nextYaer === currentYear ? false : true}
                label="Trade license expiry date "
                onChange={(e) => {
                  setInfo({
                    ...info,
                    company_trade_license_expiry_date: String(e.target.value),
                  });
                }}
                sx={{ width: "100%", pb: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                label="Upload Trade License pdf"
                type="file"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: "application/pdf",
                }}
                {...register("company_trade_license_file")}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    company_trade_license_file: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Box>
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          display="inline"
          sx={{ pr: 1 }}
          gutterBottom
        >
          <PdfAndImageView
            file={companyLicense.company_trade_license_file}
            color="#00e"
          />
          <a
            href={`${imgUrl}/${companyLicense.company_trade_license_file}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download company trade license file
          </a>
        </Typography>
      </Box> */}

      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
            size="small"
          >
            Loading..
          </Button>
        ) : adminId.user_member_account_status !== "active" ? (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
            size="small"
          >
            Save
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyTradeLicense;
