import React, { useState } from "react";
import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { imgUrl } from "../../../Helpers/utils/Constant";
import { showToast } from "../../../Toaster/Toaster";
import { ICompanyTinBin } from "../../../rest/types";
import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { UpdateCompanyTinBIN } from "../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation";
import { removeUndefinedAndNull } from "../../../Common/removeUndefinedAndNull";

interface IProps {
  companyLicense: ICompanyTinBin;
  memberId: number;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateTin = ({ companyLicense, setToggle }: IProps) => {
  const adminId = JSON.parse(localStorage.getItem("auth") as any);
  const [info, setInfo] = useState({
    tin_number: "",
    tin_year: "",
    bin_year: "",
    bin_file: "",
    tin_file: "",
    bin_number: "",
  });
  const { register, handleSubmit } = useForm();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyTinBIN(data);
    },
    onSuccess: () => {
      showToast("successfully updated", "success");
      setToggle(false);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_TINBIN],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    const removeData = removeUndefinedAndNull(info);

    Object.keys(removeData).forEach((key) => {
      formData.append(key, removeData[key]);
    });
    // console.table(Object.fromEntries(formData));
    if (Object.keys(removeData).length === 0) {
      showToast("Please Change any field", "error");
    } else {
      mutation.mutate(formData);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                defaultValue={companyLicense.tin_number}
                label="TIN number"
                type="number"
                fullWidth
                sx={{ pb: 2 }}
                {...register("tin_number")}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    tin_number: String(e.target.value),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                defaultValue={companyLicense.tin_year}
                label="TIN year"
                type="number"
                fullWidth
                sx={{ pb: 2 }}
                {...register("tin_year")}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    tin_year: String(e.target.value),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                label="Upload TIN pdf"
                type="file"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ pb: 2 }}
                {...register("tin_file")}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    tin_file: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                label="BIN number"
                type="text"
                defaultValue={companyLicense.bin_number}
                fullWidth
                sx={{ pb: 2 }}
                {...register("bin_number")}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    bin_number: String(e.target.value),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                label="BIN year"
                type="text"
                defaultValue={companyLicense.bin_year}
                fullWidth
                sx={{ pb: 2 }}
                {...register("bin_year")}
                onChange={(e) => {
                  setInfo({
                    ...info,
                    bin_year: String(e.target.value),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                label="Upload BIN pdf"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: "application/pdf",
                }}
                type="file"
                fullWidth
                sx={{ pb: 2 }}
                {...register("bin_file")}
                onChange={(e: any) => {
                  setInfo({
                    ...info,
                    bin_file: e.target.files[0],
                  });
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
            size="small"
          >
            Loading..
          </Button>
        ) : adminId.user_member_account_status !== "active" ? (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
            size="small"
          >
            Save
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default UpdateTin;
