import { Grid, Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useCompanyCivilAviationUpdate } from '../../../rest/useCompanyInfo';

const AddCivilAviationInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useCompanyCivilAviationUpdate();

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    mutate({ input: formData });
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          {' '}
          <Box>
            <label htmlFor=''>
              Civil Aviation Certificate number
              <span style={{ color: 'red' }}>*</span>{' '}
            </label>
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              placeholder='certificate number '
              fullWidth
              {...register('certificate_number', {
                required: 'certificate_number is required',
              })}
              error={
                errors.certificate_number && Boolean(errors.certificate_number)
              }
              helperText={
                Boolean(errors?.certificate_number?.message)
                  ? `${errors?.certificate_number?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <label htmlFor=''>
              Issue date <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='date'
              required
              type='date'
              {...register('certificate_issue_date', {
                required: 'certificate_issue_date is required',
              })}
              error={
                errors.certificate_issue_date &&
                Boolean(errors.certificate_issue_date)
              }
              helperText={
                Boolean(errors?.certificate_issue_date?.message)
                  ? `${errors?.certificate_issue_date?.message}`
                  : ''
              }
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <label htmlFor=''>
              Expiry date <span style={{ color: 'red' }}>*</span>
            </label>
            <TextField
              size='small'
              id='date'
              required
              type='date'
              {...register('certificate_expiry_date', {
                required: 'expiry date is required',
              })}
              error={
                errors.certificate_expiry_date &&
                Boolean(errors.certificate_expiry_date)
              }
              helperText={
                Boolean(errors?.certificate_expiry_date?.message)
                  ? `${errors?.certificate_expiry_date?.message}`
                  : ''
              }
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {' '}
          <Box>
            <label htmlFor=''>Upload Certificate Bangla (PDF)</label>
            <span style={{ color: 'red' }}>*</span>{' '}
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              type='file'
              fullWidth
              {...register('certificate_file_bn', {
                required: 'Tourism certificate document is required',
              })}
              inputProps={{
                accept: 'application/pdf',
              }}
              error={
                errors.certificate_file_bn &&
                Boolean(errors.certificate_file_bn)
              }
              helperText={
                Boolean(errors?.certificate_file_bn?.message)
                  ? `${errors?.certificate_file_bn?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <label htmlFor=''>Upload Certificate English (PDF)</label>
            <span style={{ color: 'red' }}>*</span>{' '}
            <TextField
              size='small'
              id='outlined-basic'
              required
              variant='outlined'
              type='file'
              fullWidth
              inputProps={{
                accept: 'application/pdf',
              }}
              {...register('certificate_file', {
                required: 'Tourism certificate document is required',
              })}
              error={
                errors.certificate_file && Boolean(errors.certificate_file)
              }
              helperText={
                Boolean(errors?.certificate_file?.message)
                  ? `${errors?.certificate_file?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>
      </Grid>

      {
        <Box sx={{ mt: 5, textAlign: 'end', mb: 1 }}>
          {isLoading ? (
            <Button
              disabled
              variant='contained'
              disableElevation
              className='add-remove-button'
              size='small'
            >
              Loading...
            </Button>
          ) : (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              className='add-remove-button'
              size='small'
            >
              Save
            </Button>
          )}
        </Box>
      }
    </Box>
  );
};

export default AddCivilAviationInfo;
