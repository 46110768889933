import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import GetDataApi from '../../../../Common/FetchData/GetDataApi';
import { ISubscriptionItem } from '../../type/payment_type';

const SubscriptionType = ({
  register,
  errors,
  setValue,
  watch,
}: {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
}) => {
  const itemId = watch('itemId');
  //subscription items api
  const { response: subscriptionItems, setRefetch: dd } = GetDataApi<
    ISubscriptionItem[]
  >('/api/atab/payment/get/all/paymentable/item?status=1');
  const [price, setPrice] = useState<any>(0);
  const handleSubscriptionChange = (e: any) => {
    const selectedItem = subscriptionItems?.find(
      (item) => item.atab_paymentable_item_id === e.target.value
    );
    setPrice(Number(selectedItem?.atab_paymentable_item_bdt_amount));
    setValue('amount', Number(selectedItem?.atab_paymentable_item_bdt_amount));
  };
  console.log(subscriptionItems, '===============');

  return (
    <>
      {' '}
      <Grid item xs={6}>
        <FormControl fullWidth size='small'>
          <InputLabel>Select Subscription Type</InputLabel>
          <Select
            fullWidth
            label='Select Subscription type'
            variant='outlined'
            placeholder='Subscription type'
            error={!!errors.itemId}
            {...register('itemId', {
              required: 'Subscription type is required',
              onChange: handleSubscriptionChange,
            })}
          >
            {subscriptionItems?.map((item) => (
              <MenuItem
                key={item.atab_paymentable_item_id}
                value={item.atab_paymentable_item_id}
              >
                {item.atab_paymentable_item_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {itemId === 44 && (
        <Grid item xs={6}>
          <FormControl fullWidth size='small'>
            <InputLabel>Select Subscription Year</InputLabel>
            <Select
              fullWidth
              label='Select Subscription Year'
              variant='outlined'
              placeholder='Payment year'
              {...register('paymentYear', {
                required: 'Payment year is required',
                onChange: (e) =>
                  setValue('amount', Number(price) * Number(e?.target?.value)),
              })}
              defaultValue={'1'}
              error={!!errors.paymentYear}
            >
              <MenuItem value='1'>1 Year</MenuItem>
              <MenuItem value='2'>2 Year</MenuItem>
              <MenuItem value='3'>3 Year</MenuItem>
              <MenuItem value='4'>4 Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Amount'
          variant='outlined'
          size='small'
          placeholder='Amount'
          {...register('amount')}
          InputLabelProps={{ shrink: true }}
          disabled
          error={!!errors.amount}
          helperText={errors.amount?.message as string}
        />
      </Grid>
    </>
  );
};

export default SubscriptionType;
