import { useState } from 'react';
import {
  IAdmissionDATA,
  IAdmissionResponse,
  ICompanyAssociation,
  ICompanyAssociationResponse,
  ICompanyBusinessLicense,
  ICompanyBusinessLicenseResponse,
  ICompanyCivilAviation,
  ICompanyCivilAviationResponse,
  ICompanyDetails,
  ICompanyDocument,
  ICompanyDocumentResponse,
  ICompanyLicense,
  ICompanyLicenseResponse,
  ICompanyOwenerPassportResponse,
  ICompanyOwnerList,
  ICompanyownerPassport,
  ICompanyOwnerResponse,
  ICompanyProposer,
  ICompanyProposerResponse,
  ICompanyRepresentativeDetails,
  ICompanyRepresentativeDetailsResponse,
  ICompanyTinBin,
  ICompanyTinResponse,
} from './types';
import {
  fetchAdmissionData,
  fetchCompanyAssociation,
  fetchCompanyBusinessLicense,
  fetchCompanyCivilAviation,
  fetchCompanyOwnerpassport,
  fetchCompanyDetails,
  fetchTinLicense,
  fetchCompanyDocument,
  fetchCompanyLicense,
  fetchCompanyOwner,
  fetchCompanyProposer,
  fetchOwnerDetails,
  postCompanyAssociation,
  postCompanyBusinessLicense,
  postCompanyCivilAviation,
  postCompanyDocument,
  postCompanyLicense,
  postCompanyProposer,
  postCompanyTinBin,
  postCompanyOwnerpassport,
} from '.';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from './utils/api-endpoints';
import { showToast } from '../Toaster/Toaster';

export function useCompanyDetailsQuery() {
  const { data, isLoading, error } = useQuery(
    [API_ENDPOINTS.COMPANY_DETAILS],
    () => fetchCompanyDetails(),
    {
      staleTime: Infinity, // This makes the cached data always fresh
      cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Do not refetch on window focus
      refetchOnReconnect: false, // Do not refetch on reconnect
      refetchOnMount: false, // Do not refetch when the component remounts
    }
  );

  return {
    companyData: data?.data ?? ({} as ICompanyDetails),
    isLoading,
    error,
  };
}
export function useOwnerDetailsQuery(id: string) {
  const { data, isLoading, error } = useQuery<
    ICompanyRepresentativeDetailsResponse,
    Error
  >(
    [API_ENDPOINTS.COMPANY_REPRESENTATIVE, { id }],
    () => fetchOwnerDetails(id as string),
    {
      staleTime: Infinity, // This makes the cached data always fresh
      cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Do not refetch on window focus
      refetchOnReconnect: false, // Do not refetch on reconnect
      refetchOnMount: false, // Do not refetch when the component remounts
    }
  );
  return {
    companyRepresentativeInfo:
      data?.data ?? ({} as ICompanyRepresentativeDetails),
    isLoading,
    error,
  };
}
export function useCompanyOwnerQuery() {
  const { data, isLoading, error } = useQuery<ICompanyOwnerResponse, Error>(
    [API_ENDPOINTS.OWNER_DETAILS],
    () => fetchCompanyOwner(),
    {
      staleTime: Infinity, // This makes the cached data always fresh
      cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Do not refetch on window focus
      refetchOnReconnect: false, // Do not refetch on reconnect
      refetchOnMount: false, // Do not refetch when the component remounts
    }
  );

  return {
    companyOwnerData: data?.data ?? ([] as ICompanyOwnerList[]),
    isLoading,
    error,
  };
}

export function useCompanyLicense() {
  const { data, isLoading, error } = useQuery<ICompanyLicenseResponse, Error>(
    [API_ENDPOINTS.OWNER_LICENSE],
    () => fetchCompanyLicense(),
    {
      staleTime: Infinity, // This makes the cached data always fresh
      cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Do not refetch on window focus
      refetchOnReconnect: false, // Do not refetch on reconnect
      refetchOnMount: false, // Do not refetch when the component remounts
    }
  );
  return {
    companyLicense: data?.data ?? ({} as ICompanyLicense),
    isLoading,
    error,
  };
}
export function useTin() {
  const { data, isLoading, error } = useQuery<ICompanyTinResponse, Error>(
    [API_ENDPOINTS.OWNER_TINBIN],
    () => fetchTinLicense(),
    {
      staleTime: Infinity, // This makes the cached data always fresh
      cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Do not refetch on window focus
      refetchOnReconnect: false, // Do not refetch on reconnect
      refetchOnMount: false, // Do not refetch when the component remounts
    }
  );
  return {
    companyTin: data?.data ?? ({} as ICompanyTinBin),
    isLoading,
    error,
  };
}
export function useCompanyTinBinpost() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postCompanyTinBin, {
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, 'error');
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_TINBIN],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}
export function useCompanyLicenseUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postCompanyLicense, {
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, 'error');
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_LICENSE],
      });
    },
  });
  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyBusinessLicense() {
  const { data, isLoading, error } = useQuery<
    ICompanyBusinessLicenseResponse,
    Error
  >(
    [API_ENDPOINTS.OWNER_BUSINESS_LICENSE],
    () => fetchCompanyBusinessLicense(),
    {
      staleTime: Infinity, // This makes the cached data always fresh
      cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Do not refetch on window focus
      refetchOnReconnect: false, // Do not refetch on reconnect
      refetchOnMount: false, // Do not refetch when the component remounts
    }
  );
  return {
    companyBusinessLicense: data?.data ?? ({} as ICompanyBusinessLicense),
    isLoading,
    error,
  };
}

export function useCompanyBusinessLicenseUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(
    postCompanyBusinessLicense,
    {
      // onSuccess: (data) => {
      //   if (data?.success) {
      //     showToast(`${data.message}`, "success");
      //   }
      // },
      onError: (error) => {
        const {
          response: { data },
        }: any = error ?? {};
        showToast(`${data.message}`, 'error');
        setFormError(data?.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.OWNER_BUSINESS_LICENSE],
        });
      },
    }
  );

  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyAssociation() {
  const { data, isLoading, error } = useQuery<
    ICompanyAssociationResponse,
    Error
  >([API_ENDPOINTS.COMPANY_ASSOCIATION], () => fetchCompanyAssociation(), {
    staleTime: Infinity, // This makes the cached data always fresh
    cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
    refetchOnWindowFocus: false, // Do not refetch on window focus
    refetchOnReconnect: false, // Do not refetch on reconnect
    refetchOnMount: false, // Do not refetch when the component remounts
  });
  return {
    companyAssociation: data?.data ?? ({} as ICompanyAssociation),
    isLoading,
    error,
  };
}

export function useCompanyAssociationUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postCompanyAssociation, {
    // onSuccess: (data) => {
    //   if (data?.success) {
    //     showToast(`${data.message}`, "success");
    //   }
    // },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, 'error');
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_ASSOCIATION],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyCivilAviation() {
  const { data, isLoading, error } = useQuery<
    ICompanyCivilAviationResponse,
    Error
  >([API_ENDPOINTS.COMPANY_CIVIL_AVIATION], () => fetchCompanyCivilAviation(), {
    staleTime: Infinity, // This makes the cached data always fresh
    cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
    refetchOnWindowFocus: false, // Do not refetch on window focus
    refetchOnReconnect: false, // Do not refetch on reconnect
    refetchOnMount: false, // Do not refetch when the component remounts
  });
  return {
    companyCivilAviation: data?.data ?? ({} as ICompanyCivilAviation),
    isLoading,
    error,
  };
}

export function useCompanyOwnerPassport() {
  const { data, isLoading, error } = useQuery<
    ICompanyOwenerPassportResponse,
    Error
  >([API_ENDPOINTS.OWNER_PASSPORT], () => fetchCompanyOwnerpassport(), {
    staleTime: Infinity, // This makes the cached data always fresh
    cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
    refetchOnWindowFocus: false, // Do not refetch on window focus
    refetchOnReconnect: false, // Do not refetch on reconnect
    refetchOnMount: false, // Do not refetch when the component remounts
  });
  return {
    companyOwnerpass: data?.data ?? ({} as ICompanyownerPassport),
    isLoading,
    error,
  };
}

export function useCompanyCivilAviationUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(
    postCompanyCivilAviation,
    {
      // onSuccess: (data) => {
      //   if (data?.success) {
      //     showToast(`${data.message}`, "success");
      //   }
      // },
      onError: (error) => {
        const {
          response: { data },
        }: any = error ?? {};
        showToast(`${data.message}`, 'error');
        setFormError(data?.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.COMPANY_CIVIL_AVIATION],
        });
      },
    }
  );

  return { mutate, isLoading, formError, setFormError, isSuccess };
}
export function useCompanyOwnerPassportPost() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(
    postCompanyOwnerpassport,
    {
      // onSuccess: (data) => {
      //   if (data?.success) {
      //     showToast(`${data.message}`, "success");
      //   }
      // },
      onError: (error) => {
        const {
          response: { data },
        }: any = error ?? {};
        showToast(`${data.message}`, 'error');
        setFormError(data?.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.OWNER_PASSPORT],
        });
      },
    }
  );

  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyProposer(id: string) {
  const { data, isLoading, error } = useQuery<ICompanyProposerResponse, Error>(
    [API_ENDPOINTS.COMPANY_PROPOSER_UPDATE],
    () => fetchCompanyProposer(id as string),
    {
      staleTime: Infinity, // This makes the cached data always fresh
      cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Do not refetch on window focus
      refetchOnReconnect: false, // Do not refetch on reconnect
      refetchOnMount: false, // Do not refetch when the component remounts
    }
  );
  return {
    companyProposer: data?.data ?? ([] as ICompanyProposer[]),
    isLoading,
    error,
  };
}
export function useCompanyProposerUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postCompanyProposer, {
    // onSuccess: (data) => {
    //   if (data?.success) {
    //     showToast(`${data.message}`, "success");
    //   }
    // },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, 'error');
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_PROPOSER_UPDATE],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyDocument() {
  const { data, isLoading, error } = useQuery<ICompanyDocumentResponse, Error>(
    [API_ENDPOINTS.COMPANY_COMPANY_DOCUMENT],
    () => fetchCompanyDocument(),
    {
      staleTime: Infinity, // This makes the cached data always fresh
      cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Do not refetch on window focus
      refetchOnReconnect: false, // Do not refetch on reconnect
      refetchOnMount: false, // Do not refetch when the component remounts
    }
  );
  return {
    companyDocument: data?.data ?? ({} as ICompanyDocument),
    isLoading,
    error,
  };
}

export function useCompanyDocumentUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postCompanyDocument, {
    // onSuccess: (data) => {
    //   if (data?.success) {
    //     showToast(`Successfully updated company document`, "success");
    //   }
    // },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, 'error');
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_COMPANY_DOCUMENT],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}
/**
 * Fetches admission data for a given ID.
 *
 * @param id The ID of the admission record.
 * @returns An object containing the admission data, loading state, and error state.
 */
export function useAdmission(id: string) {
  const { data, isLoading, error } = useQuery<IAdmissionResponse, Error>(
    [API_ENDPOINTS.ADMISSION, { id }],
    () => fetchAdmissionData(id as string)
  );
  return {
    admissionData: data?.data ?? ({} as IAdmissionDATA),
    isLoading,
    error,
  };
}
