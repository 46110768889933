import { Drawer, Typography } from '@mui/material';
import SidebarMenu from './SidebarMenu';

const Copyright = (props: any) => {
  return (
    <Typography variant='body2' align='center' {...props}>
      {'Copyright © '}
      <a target='_blank' rel='noreferrer' href='https://m360ict.com/'>
        M360ICT
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export const DashboardSidebarDrawer = ({
  container,
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
}: any) => {
  return (
    <>
      {/* ====== drawer for small device ====== */}
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            '&::-webkit-scrollbar': {
              width: 4,
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#00984A',
            },
          },
        }}
      >
        {/* <ListItems /> */}
        <SidebarMenu />

        {/* <Copyright
          sx={{
            position: 'absolute',
            bottom: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            display: { xs: 'block', sm: 'none' },
          }}
        /> */}
      </Drawer>

      {/* ======= drawer for big device ======== */}

      <Drawer
        className='drawer-lg-device sidebarBg'
        variant='permanent'
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            '&::-webkit-scrollbar': {
              width: 5,
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#00984A',
            },
          },
        }}
        open
      >
        {/* <ListItems /> */}
        <SidebarMenu />
      </Drawer>
    </>
  );
};

export { Copyright };
