import { useState } from 'react';
import ModalsWrapper from '../../../Components/Modals/ModalsWrapper';
import SupportConvo from '../../../Components/SupportConvo/SupportConvo';
import DashboardContent from '../../../DashboardUtils/DashboardContent';
import { Box } from '@mui/material';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';

const Dashboard = () => {
  const { user } = useAuthContext();
  //const adminId = JSON.parse(localStorage.getItem('auth') as any);
  const [open, setOpen] = useState(
    user?.user_member_account_status === 'pending' ||
      user?.user_member_account_status === 'rejected'
      ? true
      : false
  );
  return (
    <>
      <DashboardContent />
      <SupportConvo />

      <ModalsWrapper
        modalData={{ title: '' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <Box sx={{ px: 2 }}>
          {user?.user_member_account_status === 'pending' && (
            <>
              সংঘ বিধি ধারা (৪) সদস্য (সমূহ) প্রতিষ্ঠান (সমূহ) কোম্পানির (সমূহ)
              অথবা অন্য যেকোন প্রতিষ্ঠান নিবন্ধিত, ব্যক্তিগতভাবে, যৌথ অথবা
              পৃথকভাবে হাব এর সদস্য হিসাবে প্রদত্ত চাঁদা এবং অন্যান্য বকেয়া
              সমূহ প্রদান করতে বাধ্য থাকবে। একজন সদস্য পদপ্রার্থী নির্ধারিত ছকে
              প্রয়োজনীয় সনদপত্রসহ দুই জন সদস্য কর্তৃক প্রস্তাবিত ও সমর্থিত
              পূর্বক নির্বাহী পরিষদের মহাসচিবের বরাবরে সদস্য পদের জন্য আবেদন
              করবেন। নির্বাহী পরিষদ আবেদন পত্র, ভর্তি এবং সদস্য ফি প্রাপ্তিপূর্ব
              উক্ত আবেদন পত্র যথাযথ পরীক্ষা-নিরীক্ষার পর সদস্য পদ প্রদান
              সংক্রান্ত সিদ্ধান্ত গ্রহণ করবেন। কোন কারণে সদস্য পদ দেওয়ার পক্ষে
              সিদ্ধান্ত না হলে আবেদনকারীকে আত্মপক্ষ সমর্থন করার সুযোগ দিতে হবে।
            </>
          )}
          <br /> <br />
          {user?.user_member_account_status === 'rejected' && (
            <Box>
              {' '}
              সংঘ বিধি ধারা (৬) কোন ফার্ম, কোম্পানি অথবা সংস্থা সদস্য পদ বাতিল
              হবে যদি কার্যনির্বাহী পরিষদের পূর্বানুমতি ব্যতিরেকে উহার:- <br />{' '}
              ক) মালিকানা ও অংশীদারিত্ব পরিবর্তন হয়; <br />
              খ) অবস্থান পরিবর্তন হয়; <br />
              গ) প্রধান উদ্দেশ্য পরিবর্তন করা হয়; <br />
              <br />
              সংঘ বিধি ধারা (৭) কোন ফার্ম, কোম্পানি অথবা সংস্থার নাম পরিবর্তন
              করলে উহার সদস্যপদ বাতিল হবে, তবে এই পরিবর্তন যদি প্রকৃত নামকে
              যথাসম্ভব বহাল রেখে সংক্ষিপ্ত করা হয়, বাংলা বা ইংরেজিতে রূপান্তর
              করা হয়, সেক্ষেত্রে কার্যনির্বাহী পরিষদের অনুমোদন সাপেক্ষে তার
              সদস্য পদ বহর রাখতে পারে। <br />
              <br />
              সংঘ বিধি ধারা (৯) সদস্যগণ সংগঠনের সদস্য পদ থেকে খারিজ হবেন:- ক)
              যদি বাৎসরিক চাঁদা এবং অন্যান্য বকেয়া পঞ্জিকা বৎসরের ৩০ শে জুনের
              মধ্য প্রদান করতে ব্যর্থ হন, অথবা নির্বাহী পরিষদ কর্তৃক নির্ধারিত
              তারিখের মধ্যে প্রদান করতে ব্যর্থ হন। <br />
              <br /> খ) যদি কোন সদস্য সংশ্লিষ্ট মন্ত্রণালয় কর্তৃক বাতিল ঘোষিত
              হন, সেক্ষেত্রে বাতিল ঘোষণার ৯০ দিন পর সদস্য পদ খারিজ হবে।
              মন্ত্রণালয় এর সহিদ অফিসিয়াল যোগাযোগের তারিখ হতে যদি উক্ত
              মন্ত্রণালয়ের ঘোষিত বিজ্ঞপ্তির বিরুদ্ধে কোন বাতিলকৃত সদস্য প্রমাণ
              সহকারে যথাযথ কর্তৃপক্ষের কাছে আপিল করেন, তবে তার সদস্যপথ আরো ৯০
              দিনের জন্য বর্ধিত করা হবে, যাতে করে তিনি মন্ত্রণালয়ের শহীদ
              সমঝোতার বিষয়টি নিষ্পত্তি করার সুযোগ পান। <br />
              <br />
              গ) যদি কোন সদস্যের মালিক, অংশীদার অথবা পরিচালক কোন উচ্চ আদালত
              কর্তৃক দোষী সাব্যস্ত হয়ে সাজাপ্রাপ্ত হন, অথবা উপযুক্ত আদালত
              কর্তৃক মানসিকভাবে অপ্রকৃতস্থ বিবেচিত হয়, তাহলে হাব এর সদস্যপদ
              খারিজ হয়ে যাবে। অবশ্য উক্ত সদস্য যদি অংশীদারী ফার্ম অথবা কোম্পানী
              হয় এবং সাজাপ্রাপ্ত দোষী ব্যক্তি কে উক্ত সংস্থা হতে অব্যাহতি দেন,
              তবে কার্যনির্বাহী পরিষদের অনুমোদন সাপেক্ষে সদস্যপদ বহল থাকতে পারে:
              <br /> <br />
              ঘ) যদি সরকার হতে প্রাপ্ত নিবন্ধন প্রত্যয়নপত্র নবায়ন না হয় অথবা
              উহা বাতিল হয় এবং 180 দিনের মধ্যে আপিলের মাধ্যমে নিবন্ধনের
              প্রত্যয়ন পত্র নবায়নে ব্যর্থ হয়, তবে উক্ত সদস্য
              সদস্যপদ খারেজ হবে।
            </Box>
          )}
        </Box>
      </ModalsWrapper>
    </>
  );
};

export default Dashboard;
