import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { imgUrl } from '../../Helpers/utils/Constant';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardHeader,
  Box,
} from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import moment from 'moment';
import TableLoader from '../../Components/Spinner/TableLoader';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TableNoDataMessage from '../../Components/TableMessage/TableNoDataMessage';
import { useAllCertificateQuery } from '../CompanyUpdateInfo/singleMember/api/certificateEndPoint';
import UpdateDataApi from '../../Common/FetchData/UpdateDataApi';
import { useEffect } from 'react';
import { getStatusLabel } from '../../Utils/Comibined';

const DownloadCertificate = () => {
  const { user } = useAuthContext();
  const { certificate, isLoading, refetch } = useAllCertificateQuery(
    user.user_member_id!
  );
  const { updateData, success } = UpdateDataApi(
    `/api/atab/user-member/certificate`
  );

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);

  return (
    <Card sx={{ mt: 5 }}>
      <CardHeader
        title={
          <Typography sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}>
            Certificate list
          </Typography>
        }
      />

      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700, fontSize: 16 }}>ID</TableCell>
              <TableCell align='center' sx={{ fontWeight: 700, fontSize: 16 }}>
                Type
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: 700, fontSize: 16 }}>
                Issue Date
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: 700, fontSize: 16 }}>
                Status
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: 700, fontSize: 16 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : (
              <>
                {certificate?.length ? (
                  <>
                    {certificate.map((sCertificate) => (
                      <TableRow
                        key={sCertificate.c_id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {sCertificate.c_id}
                        </TableCell>
                        <TableCell align='center'>
                          {sCertificate.c_type === 'MEMBERSHIP_CERTIFICATE' ? (
                            <> New membership </>
                          ) : sCertificate.c_type === 'RENEWAL_CERTIFICATE' ? (
                            <>Renewal Certificate</>
                          ) : (
                            <>Duplicate Certificate</>
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          {moment(sCertificate.c_issue_date).format(
                            'DD MMM YYYY'
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          {sCertificate.status ? (
                            getStatusLabel(sCertificate.status ? 'active' : '')
                          ) : (
                            <Box sx={{ color: 'green' }}>
                              You are Already Downloaded
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          <Tooltip
                            title={
                              sCertificate.c_type ===
                                'MEMBERSHIP_CERTIFICATE' && !sCertificate.status
                                ? 'You are already Downloaded. if need apply for duplicate certificate.'
                                : 'Download'
                            }
                          >
                            <IconButton>
                              {sCertificate.c_type ===
                                'MEMBERSHIP_CERTIFICATE' &&
                              !sCertificate.status ? (
                                ''
                              ) : (
                                <a
                                  onClick={() =>
                                    sCertificate.c_type ===
                                      'MEMBERSHIP_CERTIFICATE' &&
                                    sCertificate.status
                                      ? updateData(
                                          { status: 0 },
                                          sCertificate.c_id
                                        )
                                      : ''
                                  }
                                  style={{ color: 'black' }}
                                  href={`${imgUrl}/${
                                    sCertificate.c_type ===
                                    'DUPLICATE_CERTIFICATE'
                                      ? sCertificate.c_photo
                                      : sCertificate.c_type ===
                                        'RENEWAL_CERTIFICATE'
                                      ? sCertificate.c_photo
                                      : sCertificate.c_photo
                                  }`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <ArrowCircleDownIcon />
                                </a>
                              )}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    <TableNoDataMessage />
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default DownloadCertificate;
