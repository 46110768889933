import { Box, Grid, TextField, Button, Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  IOwnerInput,
  IParticularsOwners,
} from '../../../Types/UpdateInfotypes/UpdateCompanyInfoStateTypes';
import { useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { useForm } from 'react-hook-form';
import { useAddOwnerInformationMutation } from '../../CompanyUpdateInfo/UpdateCompanyInformation/apiEndpoints';

const particularsOwners: IParticularsOwners[] = [
  {
    no: 0,
    company_owner_name: 'company_owner_name',
    company_owner_designation: 'company_owner_designation',
    company_owner_nid: 'company_owner_nid',
    company_owner_address: 'company_owner_address',
    company_owner_phone: 'company_owner_phone',
    company_owner_email: 'company_owner_email',
    company_owner_photo: 'company_owner_photo',
    company_owner_nid_file: 'company_owner_nid_file',
    company_owner_biodata: 'company_owner_biodata',
    company_owner_passport: 'company_owner_passport',
    company_owner_signature: 'company_owner_signature',
    date_of_birth: 'date_of_birth',
  },
];

const ownerData: IOwnerInput = {
  no: 0,
  company_owner_address: '',
  company_owner_name: '',
  company_owner_designation: '',
  company_owner_nid: '',
  company_owner_phone: '',
  company_owner_email: '',
};
const forLimited = ['Managing Director', 'Director', 'Chairman', 'CEO'];
const forPartnerShip = ['Managing Partner', 'CEO'];
export const AddCompanyOwner = ({
  companyType,
  setOpen,
}: {
  companyType?: string | null;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const queryClient = useQueryClient();
  const [owner, setOwner] = useState<IOwnerInput[]>([ownerData]);
  const [ownerInput, setOwnerInput] =
    useState<IParticularsOwners[]>(particularsOwners);
  const [imageAndPdf, setImageAndPdf] = useState<any>();
  const formData = new FormData();

  const { mutate, isLoading, isSuccess } = useAddOwnerInformationMutation();
  const onSubmit = async () => {
    // e.preventDefault();
    Object?.keys(imageAndPdf).forEach((key) => {
      formData.append(key, imageAndPdf[key]);
    });

    formData.append(
      'owners',
      JSON.stringify(
        owner?.map((item) => {
          const { no, ...rest } = item;
          return rest;
        })
      )
    );
    mutate({ data: formData });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpen && setOpen(false);
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_DETAILS],
      });
    }
  }, [isSuccess]);
  const addOwnerData = (id: number, field: string, value: string) => {
    const newData = owner.map((item) => {
      if (item.no === id) {
        item[field] = value;
        return item;
      } else {
        return item;
      }
    });

    setOwner(newData);
  };

  const addFileToFormData = (file: any, field: string, id: number) => {
    const fieldName = field + '_' + id;
    setImageAndPdf((prev: any) => ({ ...prev, [fieldName]: file }));
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Box>
          {ownerInput?.map((singleOwner: IParticularsOwners) => {
            return (
              <Box key={singleOwner.no}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's name <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField
                      size='small'
                      id='outlined-basic1'
                      placeholder='Enter owner name'
                      variant='outlined'
                      type='text'
                      required
                      fullWidth
                      onChange={(e) =>
                        addOwnerData(
                          singleOwner.no,
                          singleOwner.company_owner_name,
                          e.target.value
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>Owner's email address</label>
                    <TextField
                      size='small'
                      id='outlined-basic2'
                      variant='outlined'
                      placeholder='Enter owner email address'
                      type='email'
                      fullWidth
                      onChange={(e) =>
                        addOwnerData(
                          singleOwner.no,
                          singleOwner.company_owner_email,
                          e.target.value
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's Home Address{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField
                      size='small'
                      id='outlined-basic2'
                      variant='outlined'
                      placeholder='Enter owner full address'
                      type='text'
                      required
                      fullWidth
                      onChange={(e) =>
                        addOwnerData(
                          singleOwner.no,
                          singleOwner.company_owner_address,
                          e.target.value
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's Photo (jpg/jpeg/png){' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField
                      size='small'
                      id='outlined-basic3'
                      variant='outlined'
                      type='file'
                      required
                      fullWidth
                      inputProps={{
                        accept: 'image/jpeg, image/png, image/jpg',
                      }}
                      onChange={(e: any) => {
                        addFileToFormData(
                          e.target.files[0],
                          singleOwner.company_owner_photo,
                          singleOwner.no
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's Designation in Agency{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Autocomplete
                      freeSolo
                      options={
                        companyType === 'Limited' ? forLimited : forPartnerShip
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Enter Owner designation'
                          required
                          size='small'
                        />
                      )}
                      onInputChange={(event, newValue) => {
                        addOwnerData(
                          singleOwner.no,
                          singleOwner.company_owner_designation,
                          newValue
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's Date of Birth
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField
                      size='small'
                      id='outlined-basic6'
                      placeholder='Enter owner date of birth'
                      variant='outlined'
                      type='date'
                      required
                      fullWidth
                      onChange={(e) =>
                        addOwnerData(
                          singleOwner.no,
                          singleOwner.date_of_birth,
                          e.target.value
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's phone number{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField
                      size='small'
                      id='outlined-basic6'
                      placeholder='Enter owner phone number'
                      variant='outlined'
                      type='number'
                      required
                      fullWidth
                      onChange={(e) =>
                        addOwnerData(
                          singleOwner.no,
                          singleOwner.company_owner_phone,
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's NID number
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField
                      size='small'
                      id='outlined-basic6'
                      placeholder='Enter owner NID number'
                      variant='outlined'
                      type='number'
                      required
                      fullWidth
                      {...register('user_nid', {
                        required: 'NID number is required',
                        onChange: (e) => {
                          const value = e.target.value;
                          if (
                            value.length === 10 ||
                            value.length === 13 ||
                            value.length === 17
                          ) {
                            clearErrors('user_nid');
                          } else if (value.length > 17) {
                            // Set error if more than 17 digits
                            setError('user_nid', {
                              type: 'manual',
                              message: 'must be exactly 10, 13, or 17 digits',
                            });
                          } else {
                            setError('user_nid', {
                              type: 'manual',
                              message: 'must be exactly 10, 13, or 17 digits',
                            });
                          }
                          addOwnerData(
                            singleOwner.no,
                            singleOwner.company_owner_nid,
                            value
                          );
                        },
                      })}
                      autoFocus
                      error={!!errors.user_nid}
                      helperText={errors.user_nid?.message as string}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's Biodata (pdf){' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField
                      size='small'
                      id='outlined-basic7'
                      variant='outlined'
                      type='file'
                      required
                      fullWidth
                      inputProps={{ accept: 'application/pdf' }}
                      onChange={(e: any) => {
                        addFileToFormData(
                          e.target.files[0],
                          singleOwner.company_owner_biodata,
                          singleOwner.no
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's Signature <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField
                      size='small'
                      id='outlined-basic4'
                      variant='outlined'
                      required
                      type='file'
                      fullWidth
                      inputProps={{
                        accept: 'image/jpeg, image/png, image/jpg',
                      }}
                      onChange={(e: any) => {
                        addFileToFormData(
                          e.target.files[0],
                          singleOwner.company_owner_signature,
                          singleOwner.no
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <label htmlFor=''>
                      Owner's NID (Both Side) (pdf){' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField
                      size='small'
                      id='outlined-basic9'
                      variant='outlined'
                      type='file'
                      required
                      fullWidth
                      inputProps={{ accept: 'application/pdf' }}
                      onChange={(e: any) => {
                        addFileToFormData(
                          e.target.files[0],
                          singleOwner.company_owner_nid_file,
                          singleOwner.no
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
      </Box>
      {
        <Box sx={{ mt: 5, textAlign: 'end', mb: 1 }}>
          {isLoading ? (
            <Button disabled variant='contained' disableElevation>
              Loading...
            </Button>
          ) : (
            <Button type='submit' variant='contained' disableElevation>
              Save
            </Button>
          )}
        </Box>
      }
    </Box>
  );
};
