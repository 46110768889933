import { useState } from 'react';
import { showToast } from '../../Toaster/Toaster';
import axios from 'axios';
import { AUTH_USER_SUCCESS, url } from '../../Helpers/utils/Constant';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { setCookie } from 'nookies';
import { useNavigate } from 'react-router-dom';

const TokenAndEmailFetchData = <T,>(urlApi: string) => {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const [response, setResponse] = useState<T | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const postData = async (payload: any, token: string) => {
    setError(null);
    const headers = {
      'Content-Type': 'application/json', // Typically for JSON payloads
      // If you have an auth token, you can add it here as well
      Authorization: `Bearer ${token}`,
    };
    try {
      setLoading(true);
      const { data } = await axios.post(`${url}${urlApi}`, payload, {
        headers,
      });

      if (data?.data) {
        setSuccess(true);
        setResponse(data.data);
        localStorage.setItem('auth', JSON.stringify(data?.data));
        dispatch?.({
          type: AUTH_USER_SUCCESS,
          payload: data?.data,
        });
        setCookie(null, 'haab_ua', data?.token);
        navigate('/');
        showToast('Successfully Login', 'success');
      } else {
        setSuccess(false);
        setError('something went wrong');
        showToast('something went wrong', 'error');
      }
    } catch (err: any) {
      setSuccess(false);
      setError(err?.response?.data?.message);
      showToast(err?.response?.data?.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, postData, success };
};

export default TokenAndEmailFetchData;
