import { Button, TextField, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import PostDataApis from '../../../Common/FetchData/PostDataApis';

const CreateTicket = ({
  refetch,
  setOpen,
}: {
  refetch: () => Promise<void>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { response, loading, postData, success } = PostDataApis<any>(
    `/api/atab/user-member/ticket`
  );
  const [formData, setFormData] = useState({
    type: '',
    details: '',
    status: 'pending',
  });

  // Function to handle input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Perform the post action (API call or state update)
    console.log('Form Data:', formData);

    postData(formData);
    // Example: send data to your API or update state here
  };

  useEffect(() => {
    if (success) {
      refetch();
      setOpen(false);
    }
  }, [success]);
  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{ width: '100%', maxWidth: 500, margin: 'auto', padding: 2 }}
    >
      {/* Title Input */}
      <TextField
        size='small'
        fullWidth
        label='Title'
        variant='outlined'
        name='type'
        value={formData.type}
        onChange={handleChange}
        margin='normal'
        required
      />

      {/* Details (Textarea) */}
      <TextField
        fullWidth
        label='Details'
        variant='outlined'
        name='details'
        value={formData.details}
        onChange={handleChange}
        multiline
        rows={4}
        margin='normal'
        required
      />

      {/* Submit Button */}
      <Button type='submit' variant='contained' fullWidth sx={{ mt: 2 }}>
        Submit
      </Button>
    </Box>
  );
};

export default CreateTicket;
