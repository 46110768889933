// import React, { useState } from "react";
// import { Button, Typography } from "@mui/material";
// import { useAuthContext } from "../../Context/AuthContext/AuthContext";
// import { useForm } from "react-hook-form";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import { CustomTextFiled } from "../../Components/CustomTextFiled/CustomTextFiled";
// import { showToast } from "../../Toaster/Toaster";
// import { useMutation, useQueryClient } from "react-query";
// import { postChangePassword } from "./api/changePasswordEndPoint";
// import { API_ENDPOINTS } from "../../rest/utils/api-endpoints";

// const ChangePasswordPublic = () => {
//   const { user } = useAuthContext();
//   const { register, handleSubmit, reset } = useForm();
//   const [password1, setPassword1] = useState("");
//   const [showPassword, setShowPassword] = React.useState(false);
//   const handleClickShowPassword = () => setShowPassword((show) => !show);
//   const handleMouseDownPassword = (event: any) => {
//     event.preventDefault();
//   };
//   const queryClient = useQueryClient();

//   const mutation = useMutation({
//     mutationFn: (data: any) => {
//       return postChangePassword(data);
//     },
//     onSuccess: () => {
//       showToast(`Successfully password change`, "success");
//       reset();
//     },
//     onError: (error: any) => {
//       showToast(`${error.response.data.message}`, "error");
//     },
//     onSettled: () => {
//       queryClient.invalidateQueries({
//         queryKey: [API_ENDPOINTS.CHANGE_PASSWORD],
//       });
//     },
//   });
//   const onSubmit = async (data: any) => {
//     if (data.new_password !== password1) {
//       showToast(`Password does not match`, "error");
//       return;
//     }
//     if (data.new_password.length < 8) {
//       showToast(`Password must be at least 8 characters`, "error");
//       return;
//     }
//     data.user_id = user.user_member_id!;
//     mutation.mutate(data);
//   };
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         py: 5,
//       }}
//     >
//       <Grid
//         sx={{
//           width: { xs: "40vh", md: "30vw" },
//           height: { md: "40vh" },
//           boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
//           backgroundColor: "#ffffff",
//           padding: { xs: 2, md: 5 },
//           borderRadius: 2,
//         }}
//       >
//         <Grid item>
//           <Box component="form" onSubmit={handleSubmit(onSubmit)}>
//             <Typography variant="h4" gutterBottom pb={2} textAlign={"center"}>
//               Change Password
//             </Typography>
//             <Box>
//               <CustomTextFiled
//                 size="small"
//                 id="outlined-basic"
//                 label="Enter old password"
//                 required
//                 sx={{
//                   mr: 2,
//                   pb: 2,
//                   width: "100%",
//                 }}
//                 variant="outlined"
//                 {...register("old_password")}
//               />
//               <br />

//               <FormControl
//                 sx={{
//                   mr: 2,
//                   pb: 2,
//                   width: "100%",
//                 }}
//                 variant="outlined"
//               >
//                 <InputLabel htmlFor="outlined-adornment-password">
//                   Enter new password <span style={{ color: "red" }}>*</span>
//                 </InputLabel>
//                 <OutlinedInput
//                   size="small"
//                   sx={{ borderRadius: "10px" }}
//                   required
//                   id="outlined-adornment-password1"
//                   type="text"
//                   {...register("new_password")}
//                   label="Enter new password"
//                 />
//               </FormControl>
//               <br />
//               <FormControl
//                 sx={{
//                   mr: 2,
//                   pb: 2,
//                   width: "100%",
//                 }}
//                 variant="outlined"
//               >
//                 <InputLabel htmlFor="outlined-adornment-password">
//                   Retype password <span style={{ color: "red" }}>*</span>
//                 </InputLabel>
//                 <OutlinedInput
//                   size="small"
//                   sx={{ borderRadius: "10px" }}
//                   id="outlined-adornment-password2"
//                   type={showPassword ? "text" : "password"}
//                   required
//                   onChange={(e: any) => setPassword1(e.target.value)}
//                   endAdornment={
//                     <InputAdornment position="end">
//                       <IconButton
//                         aria-label="toggle password visibility"
//                         onClick={handleClickShowPassword}
//                         onMouseDown={handleMouseDownPassword}
//                         edge="end"
//                       >
//                         {showPassword ? <VisibilityOff /> : <Visibility />}
//                       </IconButton>
//                     </InputAdornment>
//                   }
//                   label="Retype password"
//                 />
//               </FormControl>

//               <Box>
//                 {mutation.isLoading ? (
//                   <Button
//                     disabled
//                     fullWidth
//                     variant="contained"
//                     sx={{ width: "100%", mt: 2 }}
//                     className="add-remove-button"
//                   >
//                     Loading...
//                   </Button>
//                 ) : (
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     disableElevation
//                     sx={{ width: "100%", mt: 2 }}
//                     className="add-remove-button"
//                   >
//                     Change password
//                   </Button>
//                 )}
//               </Box>
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default ChangePasswordPublic;

import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CustomTextFiled } from '../../Components/CustomTextFiled/CustomTextFiled';
import { showToast } from '../../Toaster/Toaster';
import { useMutation, useQueryClient } from 'react-query';
import { postChangePassword } from './api/changePasswordEndPoint';
import { API_ENDPOINTS } from '../../rest/utils/api-endpoints';
import { useNavigate } from 'react-router-dom';

const ChangePasswordPublic = () => {
  const { user } = useAuthContext();
  const { register, handleSubmit, reset, setValue } = useForm();
  const [password1, setPassword1] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (user && user.user_member_email) {
      setValue('email', user.user_member_email);
    }
  }, [user, setValue]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const mutation = useMutation({
    mutationFn: (data: any) => postChangePassword(data),
    onSuccess: () => {
      showToast(`Successfully changed password`, 'success');
      reset();
      navigate('/login');
    },
    onError: (error: any) => {
      showToast(
        `${error.response?.data?.message || 'An error occurred'}`,
        'error'
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.CHANGE_PASSWORD],
      });
    },
  });

  const onSubmit = async (data: any) => {
    if (data.new_password !== password1) {
      showToast(`Passwords do not match`, 'error');
      return;
    }
    if (data.new_password.length < 8) {
      showToast(`Password must be at least 8 characters`, 'error');
      return;
    }
    data.user_id = user.user_member_id!;
    data.is_first_login = 0;
    mutation.mutate(data);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 5,
      }}
    >
      <Grid
        sx={{
          width: { xs: '40vh', md: '30vw' },
          height: { md: 'auto' },
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          backgroundColor: '#ffffff',
          padding: { xs: 2, md: 5 },
          borderRadius: 2,
        }}
      >
        <Grid item>
          <Box component='form' onSubmit={handleSubmit(onSubmit)}>
            <Typography variant='h4' gutterBottom pb={2} textAlign={'center'}>
              Change Password
            </Typography>
            <Box>
              <CustomTextFiled
                size='small'
                id='outlined-basic'
                label='Email'
                required
                disabled
                sx={{ mr: 2, pb: 2, width: '100%' }}
                variant='outlined'
                {...register('email')}
              />
              <br />
              <CustomTextFiled
                size='small'
                id='outlined-basic'
                label='Enter old password'
                type='password'
                required
                sx={{ mr: 2, pb: 2, width: '100%' }}
                variant='outlined'
                placeholder='Enter your old password'
                {...register('old_password')}
              />
              <br />
              <FormControl
                sx={{ mr: 2, pb: 2, width: '100%' }}
                variant='outlined'
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                  Enter new password <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <OutlinedInput
                  size='small'
                  sx={{ borderRadius: '10px' }}
                  required
                  id='outlined-adornment-password1'
                  type={showPassword ? 'text' : 'password'}
                  {...register('new_password')}
                  label='Enter new password'
                  placeholder='Enter your new password'
                />
              </FormControl>
              <br />
              <FormControl
                sx={{ mr: 2, pb: 2, width: '100%' }}
                variant='outlined'
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                  Retype password <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <OutlinedInput
                  size='small'
                  sx={{ borderRadius: '10px' }}
                  id='outlined-adornment-password2'
                  type={showPassword ? 'text' : 'password'}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword1(e.target.value)
                  }
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Retype password'
                  placeholder='Retype your new password'
                />
              </FormControl>

              <Box>
                <Button
                  type='submit'
                  variant='contained'
                  disableElevation
                  sx={{ width: '100%', mt: 2 }}
                  className='add-remove-button'
                  disabled={mutation.isLoading}
                >
                  {mutation.isLoading ? 'Loading...' : 'Change password'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangePasswordPublic;
