import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { imgUrl } from '../../Helpers/utils/Constant';
import {
  Card,
  CardHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Avatar,
  Typography,
} from '@mui/material';

import TableNoDataMessage from '../../Components/TableMessage/TableNoDataMessage';
import TableLoader from '../../Components/Spinner/TableLoader';
import { getStatusLabel } from '../../Utils/Comibined';
import { useJoinedMeetingQuery } from './api/JoinedEndPoint';

const JoinedMeeting = () => {
  const { user } = useAuthContext();
  const { allJoinedMeeting, isLoading } = useJoinedMeetingQuery(
    user.user_member_id!
  );

  return (
    <Card sx={{ mt: 5 }}>
      <CardHeader
        title={
          <Typography sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}>
            All joined meeting
          </Typography>
        }
      />
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Photo</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Tittle</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : (
              <>
                {allJoinedMeeting.length ? (
                  <>
                    {allJoinedMeeting.map((sMeeting) => {
                      return (
                        <TableRow key={sMeeting.member_meeting_id}>
                          <TableCell>{sMeeting.member_meeting_id}</TableCell>
                          <TableCell align='center'>
                            <Avatar
                              alt=''
                              src={`${imgUrl}/member_meeting_files/${sMeeting.member_meeting_img}`}
                              sx={{ width: 35, height: 35 }}
                            />
                          </TableCell>
                          <TableCell>{sMeeting.member_meeting_title}</TableCell>
                          <TableCell>
                            {getStatusLabel(sMeeting.member_meeting_status)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableNoDataMessage />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default JoinedMeeting;
