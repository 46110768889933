import { Card, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSingleOwnershipChangeQuery } from '../../rest/useChangeOwnership ';
import PageLoader from '../../Components/Spinner/PageLoader';
import { getStatusLabel } from '../../Utils/Label/status-label';
import PdfAndImageView from '../../Common/PdfAndImageView';
import Description, { DescriptionChild } from '../../Common/Description';

const SingleOwnershipChangeApplication = () => {
  const { id } = useParams();
  const {
    data: application,
    fetchData,
    loading,
  } = useSingleOwnershipChangeQuery(id !== undefined ? id : '');

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return !loading ? (
    <Card sx={{ mx: 2, mt: 5 }}>
      <CardContent>
        {application ? (
          <Description>
            {/* General Application Info */}
            <DescriptionChild label='Application Date'>
              {moment(
                application.ownership_change_application_created_at
              ).format('MMM Do YY')}
            </DescriptionChild>

            <DescriptionChild label='Company Name'>
              {application.user_member_company_name}
            </DescriptionChild>

            <DescriptionChild label='Status'>
              {getStatusLabel(application.ownership_change_application_status)}
            </DescriptionChild>

            {/* New Owner Information */}
            <DescriptionChild label='New Owner Name'>
              {application.company_owner_name}
            </DescriptionChild>

            <DescriptionChild label='New Owner Designation'>
              {application.company_owner_designation}
            </DescriptionChild>

            <DescriptionChild label='New Owner NID'>
              {application.company_owner_nid}
            </DescriptionChild>

            <DescriptionChild label='New Owner Address'>
              {application.company_owner_address}
            </DescriptionChild>

            <DescriptionChild label='New Owner Phone'>
              {application.company_owner_phone}
            </DescriptionChild>

            <DescriptionChild label='New Owner Email'>
              {application.company_owner_email}
            </DescriptionChild>

            <DescriptionChild label='Download Owner Photo'>
              <PdfAndImageView file={application.company_owner_photo} />
            </DescriptionChild>
            <DescriptionChild label='Download Civil Aviation'>
              <PdfAndImageView
                file={
                  application.ownership_change_application_civil_aviation_bn_en
                }
              />
            </DescriptionChild>

            <DescriptionChild label='Download TIN'>
              <PdfAndImageView
                file={application.ownership_change_application_tin}
              />
            </DescriptionChild>

            <DescriptionChild label='Download Trade License'>
              <PdfAndImageView
                file={application.ownership_change_application_trade_license}
              />
            </DescriptionChild>

            <DescriptionChild label='Download Hajj License'>
              <PdfAndImageView
                file={application.ownership_change_application_hajj_license}
              />
            </DescriptionChild>

            <DescriptionChild label='Download Forwarding Letter'>
              <PdfAndImageView
                file={
                  application.ownership_change_application_forwarding_letter
                }
              />
            </DescriptionChild>

            <DescriptionChild label='Download Ownership Change Deed'>
              <PdfAndImageView
                file={
                  application.ownership_change_application_ownership_change_deed
                }
              />
            </DescriptionChild>
            <DescriptionChild label='Download New Owner NID File'>
              <PdfAndImageView file={application.company_owner_nid_file} />
            </DescriptionChild>

            <DescriptionChild label='Download New Owner Biodata'>
              <PdfAndImageView file={application.company_owner_biodata} />
            </DescriptionChild>

            <DescriptionChild label='Download New Owner Passport'>
              <PdfAndImageView file={application.company_owner_passport} />
            </DescriptionChild>

            <DescriptionChild label='Download New Owner Signature'>
              <PdfAndImageView file={application.company_owner_signature} />
            </DescriptionChild>

            {/* Rejection Reason */}
            {application.ownership_change_application_rejected_reason && (
              <DescriptionChild label='Rejection Reason'>
                {application.ownership_change_application_rejected_reason}
              </DescriptionChild>
            )}
          </Description>
        ) : (
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            No Data found
          </Typography>
        )}
      </CardContent>
    </Card>
  ) : (
    <PageLoader />
  );
};

export default SingleOwnershipChangeApplication;
