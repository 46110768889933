import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import GetDataApi from '../../../../../Common/FetchData/GetDataApi';
import { BankAccount } from '../../../Component/Bank/BankList';
import {
  IActiveForAdmin,
  IPaymentManagementPostType,
} from '../../../type/payment_type';
import { useEffect } from 'react';
import SubscriptionType from '../../Component/SubscriptionType';
import PostDataApis from '../../../../../Common/FetchData/PostDataApis';
import BearerCashCheque from '../../Component/PaymentTypewise/BearerCashCheque';
import Payorder from '../../Component/PaymentTypewise/Payorder';
import { useSearchParams } from 'react-router-dom';
const BankPayment = ({
  setBeneficiaryType,
  setRefetch,
  setManualPayment,
}: {
  setBeneficiaryType: React.Dispatch<React.SetStateAction<any>>;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  setManualPayment: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<any>();
  const payment_type = watch('payment_type');
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries(searchParams.entries());
  //active bank account api
  const { response: activeBankForAdmin } = GetDataApi<IActiveForAdmin>(
    '/api/atab/account/member?account_type=Bank'
  );
  const {
    loading: d,
    postData,
    success,
  } = PostDataApis('/api/atab/member-payment/self-payment');
  useEffect(() => {
    if (activeBankForAdmin) {
      setValue('bank_name', activeBankForAdmin?.bank_name);
      setValue('account_holder_name', activeBankForAdmin.name);
      setValue('account_number', activeBankForAdmin.account_no);
      setValue('admin_account_id', activeBankForAdmin.id);
    }
  }, [activeBankForAdmin]);
  useEffect(() => {
    if (success) {
      setRefetch(true);
      reset();
      setManualPayment('list');
      setSearchParams({ tabValue: 'list' });
    }
  }, [success]);
  const onSubmit = (data: IPaymentManagementPostType) => {
    const formdata = new FormData();
    const { account_holder_name, bank_name, account_number, amount, ...all } =
      data;
    for (const key in all) {
      if (all.hasOwnProperty(key)) {
        if (key === 'voucher_photo' && typeof all[key] === 'object') {
          Object.keys(all.voucher_photo).forEach((photoKey) => {
            formdata.append(`voucher_photo`, all.voucher_photo[0]);
          });
        } else if (key === 'cheque_photo' && typeof all[key] === 'object') {
          Object.keys(all.cheque_photo).forEach((photoKey) => {
            formdata.append(`cheque_photo`, all.cheque_photo[0]);
          });
        } else if (key === 'payorder_photo' && typeof all[key] === 'object') {
          Object.keys(all.payorder_photo).forEach((photoKey) => {
            formdata.append(`payorder_photo`, all.payorder_photo[0]);
          });
        } else {
          formdata.append(key, all[key as keyof typeof all]);
        }
      }
    }

    postData(formdata);
  };

  return (
    <Card sx={{ mt: 5 }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: 600,
          margin: '0 auto',
          padding: '20px',
          bgcolor: 'background.paper',
          boxShadow: 3,
        }}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth size='small'>
              <InputLabel>Beneficiary Type</InputLabel>
              <Select
                fullWidth
                variant='outlined'
                label='Beneficiary Type'
                placeholder='beneficiary Type'
                {...register('beneficiary_type', {
                  required: 'beneficiary Type is required',
                })}
                onChange={(e: any) => {
                  setBeneficiaryType(e.target.value);
                  setSearchParams({
                    ...currentParams,
                    beneficiaryType: 'wallet',
                  });
                }}
                error={!!errors.beneficiary_type}
                defaultValue={'bank'}
              >
                <MenuItem value='bank'>Bank Account</MenuItem>
                <MenuItem value='wallet'>Mobile Wallet</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Beneficiary Bank Name'
              defaultValue={activeBankForAdmin && activeBankForAdmin?.bank_name}
              variant='outlined'
              size='small'
              disabled
              placeholder='Beneficiary Bank Name'
              InputLabelProps={{ shrink: true }}
              {...register('bank_name', {
                // required: 'Beneficiary Bank name is required',
              })}
              error={!!errors.bank_name}
              helperText={errors.bank_name?.message as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Beneficiary Account Name'
              variant='outlined'
              size='small'
              disabled
              placeholder='Beneficiary Account Name'
              InputLabelProps={{ shrink: true }}
              {...register('account_holder_name', {
                // required: 'Beneficiary Bank name is required',
              })}
              error={!!errors.account_holder_name}
              helperText={errors.account_holder_name?.message as string}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Account Number'
              variant='outlined'
              size='small'
              disabled
              placeholder='Account Number'
              InputLabelProps={{ shrink: true }}
              {...register('account_number', {
                // required: 'Account number is required',
              })}
              error={!!errors.account_number}
              helperText={errors.account_number?.message as string}
            />
          </Grid>

          <SubscriptionType
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />

          <Grid item xs={6}>
            <FormControl fullWidth size='small'>
              <InputLabel>Payment Type</InputLabel>
              <Select
                fullWidth
                label='Select Payment type'
                variant='outlined'
                placeholder='Payment type'
                {...register('payment_type', {
                  required: 'Payment type is required',
                })}
                defaultValue={'Cash Deposit'}
                error={!!errors.payment_type}
              >
                <MenuItem value='Cash Deposit'>Cash Deposit</MenuItem>
                <MenuItem value='Bearer Cash Cheque'>
                  Bearer Cash Cheque
                </MenuItem>
                <MenuItem value='Account Pay Cheque'>
                  Account Pay Cheque
                </MenuItem>
                <MenuItem value='Pay Order'>Pay Order</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {payment_type === 'Bearer Cash Cheque' ||
          payment_type === 'Account Pay Cheque' ? (
            <BearerCashCheque register={register} errors={errors} />
          ) : payment_type === 'Pay Order' ? (
            <Payorder register={register} errors={errors} />
          ) : (
            <></>
          )}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Deposit Voucher Number'
              variant='outlined'
              size='small'
              placeholder='Deposit Voucher Number'
              InputLabelProps={{ shrink: true }}
              {...register('vocher_number', {
                required: ' deposit voucher no. is required',
              })}
              error={!!errors.vocher_number}
              helperText={errors.vocher_number?.message as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Upload Voucher (pdf)'
              variant='outlined'
              size='small'
              placeholder='Upload Voucher (pdf)'
              InputLabelProps={{ shrink: true }}
              {...register('voucher_photo', {
                required: ' deposit voucher no. is required',
              })}
              inputProps={{
                accept: 'application/pdf', // Only allow PDF files
              }}
              type='file'
              error={!!errors.voucher_photo}
              helperText={errors.voucher_photo?.message as string}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={'center'}>
              <Grid xs={8}>
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
export default BankPayment;
