import {
  Avatar,
  Tooltip,
  Box,
  Button,
  styled,
  InputBase,
  useTheme,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import socket from '../../Helpers/socket';
import { imgUrl, url } from '../../Helpers/utils/Constant';
import { IAllSupportConversations } from './ShowAllComplain';
import moment from 'moment';

const MessageInputWrapper = styled(InputBase)(
  ({ theme }) => `
      font-size: ${theme.typography.pxToRem(18)};
      padding: ${theme.spacing(1)};
      width: 100%;
      `
);
const Input = styled('input')({
  display: 'none',
});

function ComplainBottonBar({
  selectedComplain,
  allSupportConversations,
  setAllSupportConversations,
}: {
  selectedComplain: {
    complain_id: string;
    member_id: string;
  };

  allSupportConversations: IAllSupportConversations | undefined;
  setAllSupportConversations: React.Dispatch<
    React.SetStateAction<IAllSupportConversations | undefined>
  >;
}) {
  const [textMsg, setTextMsg] = useState<string>('');
  const theme = useTheme();
  const { user, dispatch } = useAuthContext();
  const [attachFile, setAttachFile] = useState<any>(null);
  console.log(attachFile);
  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      const body = {
        content: textMsg,
        file: attachFile,
        complain_id: selectedComplain.complain_id,
        created_by: user.user_member_id!,
        type: 'member',
      };
      const formData = new FormData();
      (Object.keys(body) as (keyof typeof body)[]).forEach((item) => {
        formData.append(item, body[item]); // TypeScript now knows that 'item' is a valid key of 'body'
      });

      socket.emit('read_message', formData);
      const { data } = await axios.post(
        `${url}/api/atab/complain/reply`,
        formData
      );

      if (data.success) {
        setAllSupportConversations((prevData: any) => {
          const lastReply =
            prevData?.replies?.[prevData.replies.length - 1] || {};
          // Create a new reply object by spreading the last reply and overriding id and content
          const newReply = {
            ...lastReply,
            id: data?.data, // New ID
            content: textMsg, // New content
            type: 'member',
            created_at: moment(),
          };

          return {
            ...prevData,
            replies: [...prevData.replies, newReply],
          };
        });

        setTextMsg('');
        setAttachFile(null);
      }
    } catch (err: any) {}
  };

  return (
    <Box
      sx={{
        background: theme.colors.alpha.white[50],
        display: 'flex',
        alignItems: 'center',
        p: 1,
      }}
      component='form'
      onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => handleSubmit(e)}
    >
      <Box flexGrow={1} display='flex' alignItems='center'>
        <Avatar
          sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }}
          alt={user.user_member_company_name}
          src={`${imgUrl}/${user}`}
        />
        <MessageInputWrapper
          autoFocus
          placeholder='Write your message here...'
          fullWidth
          value={textMsg}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTextMsg(e.target.value)
          }
        />
      </Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {attachFile && (
            <>
              <Typography variant='body1'>{attachFile.name}</Typography>
              <IconButton
                onClick={() => setAttachFile(null)}
                aria-label='delete'
              >
                <CloseIcon color='primary' />
              </IconButton>
            </>
          )}
          <Input
            onChange={(e: any) => {
              console.log(e);
              setAttachFile(e.target.files[0]);
            }}
            accept='image/*'
            id='messenger-upload-file'
            type='file'
          />
          <Tooltip arrow placement='top' title='Attach a file'>
            <label htmlFor='messenger-upload-file'>
              <IconButton sx={{ mx: 1 }} color='primary' component='span'>
                <AttachFileTwoToneIcon fontSize='small' />
              </IconButton>
            </label>
          </Tooltip>
          <Box sx={{ mr: 10 }}>
            <Button
              type='submit'
              disabled={textMsg && selectedComplain.complain_id ? false : true}
              startIcon={<SendTwoToneIcon />}
              variant='contained'
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ComplainBottonBar;
