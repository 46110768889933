import { imgUrl } from '../../Helpers/utils/Constant';
import {
  Card,
  CardHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Avatar,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';

import PageLoader from '../../Components/Spinner/PageLoader';
import TableNoDataMessage from '../../Components/TableMessage/TableNoDataMessage';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';

import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { getStatusLabel } from '../../Utils/Comibined';
import { useJoinedEventQuery } from '../JoinedMeeting/api/JoinedEndPoint';

const JoinedEvent = () => {
  const { user } = useAuthContext();
  const { allJoinedEvent, isLoading } = useJoinedEventQuery(
    user.user_member_id!
  );

  return (
    <Card sx={{ mt: 5 }}>
      <CardHeader
        title={
          <Typography sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}>
            All joined event
          </Typography>
        }
      />
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Photo</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Tittle</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Joined Date</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell>
                  <PageLoader />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {allJoinedEvent.length ? (
                  <>
                    {allJoinedEvent.map((sEvent) => {
                      return (
                        <TableRow key={sEvent.member_event_id}>
                          <TableCell>{sEvent.member_event_id}</TableCell>
                          <TableCell>
                            <Avatar
                              alt=''
                              src={`${imgUrl}/${sEvent.member_event_cover_photo}`}
                              sx={{ width: 35, height: 35 }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '200px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {sEvent.member_event_name}
                          </TableCell>
                          <TableCell>
                            {moment(
                              sEvent.member_event_joined_member_joined_date
                            ).format('MMM Do YY')}
                          </TableCell>
                          <TableCell>
                            {getStatusLabel(sEvent.member_event_status)}
                          </TableCell>

                          <TableCell>
                            <Tooltip title='View'>
                              <Link
                                to={`/joined-event/${sEvent.member_event_id}`}
                              >
                                <IconButton>
                                  <VisibilityIcon />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableNoDataMessage />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default JoinedEvent;
