import React from "react";
import { useState } from "react";
import { Button, CssBaseline, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { muiStyles } from "../../Utils/Styles";
import Lottie from "react-lottie";
import { LottiedefaultOptions2 } from "../../Utils/Comibined";
import axios from "axios";
import { url } from "../../Helpers/utils/Constant";
import { showToast } from "../../Toaster/Toaster";
import WestIcon from "@mui/icons-material/West";
const useStyles = muiStyles;

const MatchOtp = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [otp, setOTp] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultOptions2 = LottiedefaultOptions2;
  const email = searchParams.get("email");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (otp) {
      axios
        .post(`${url}/api/atab/common/match-email-otp`, {
          email,
          otp,
          type: "forget_member",
        })
        .then(function (res) {
          if (res.data.success) {
            showToast(`${res.data.message}`, "success");
            localStorage.setItem("memberForgetToken", res.data.token);
            setIsLoading(false);
            navigate(`/new-password?email=${email}`);
          }
        })
        .catch(function (err) {
          showToast(`${err.response.data.message}`, "error");
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="login-page">
        <div className="login-wrapper">
          <div>
            <div className="login-left-col">
              <Lottie options={defaultOptions2} />
            </div>
          </div>
          <div>
            <div className="login-right-col">
              <Container maxWidth="sm" component="main">
                <CssBaseline />
                <div className="right-top-div">
                  <img src="/logo.png" alt="" />
                </div>

                <div className="pt-3">
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "#004e2e",
                      pb: 3,
                    }}
                  >
                    Enter security code
                  </Typography>
                </div>
                <Box>
                  <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      type="number"
                      label="Enter OTP"
                      onChange={(e: any) => setOTp(e.target.value)}
                      variant="filled"
                      className={classes.inputText}
                      InputProps={{ classes }}
                    />

                    <Box>
                      {isLoading ? (
                        <Button
                          disabled
                          fullWidth
                          variant="contained"
                          disableElevation
                          sx={{
                            mt: 3,
                            mb: 2,
                            py: 1,
                            background: "#004e2e",
                          }}
                          className={classes.signInButton}
                        >
                          Loading...
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          disableElevation
                          sx={{
                            mt: 3,
                            mb: 2,
                            py: 1,
                            background: "#004e2e",
                          }}
                          className={classes.signInButton}
                        >
                          Continue
                        </Button>
                      )}
                    </Box>

                    <Grid container>
                      <Grid item xs={12} sm={6} md={12}>
                        <Typography color="red" sx={{ fontSize: "13px" }}>
                          You will get OTP to your email.
                          <br />
                          If you do not get the OTP to Inbox please check Spam
                          or Junk.
                        </Typography>
                        <Link to="/login">
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "20px",
                            }}
                            className="text-main"
                          >
                            <WestIcon
                              sx={{ marginRight: "5px" }}
                              fontSize="small"
                            />{" "}
                            {"Go Back"}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchOtp;
