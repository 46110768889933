import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  Button,
} from '@mui/material';
import { Container } from '@mui/system';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useRenewalSingleQuery } from '../../rest/useFetchRenewalApplication';
import PageLoader from '../../Components/Spinner/PageLoader';
import { getStatusLabel } from '../../Utils/Label/status-label';
import Description, { DescriptionChild } from '../../Common/Description';
import PdfAndImageView from '../../Common/PdfAndImageView';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const SingleRenewalApplication = () => {
  const { id } = useParams();
  const {
    data: application,
    fetchData,
    loading,
  } = useRenewalSingleQuery(id !== undefined ? id : '');
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const navigate = useNavigate();

  return !loading ? (
    <Container sx={{ pt: 5 }}>
      <Card>
        <Button
          size='small'
          variant='outlined'
          color='primary'
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ m: 2, mb: 0 }} // Optional styling, e.g., margin bottom
        >
          Back
        </Button>
        <CardHeader title={`Details of Renewal application`}></CardHeader>
        <CardContent>
          {application ? (
            <Description>
              <DescriptionChild label='Application Date'>
                {moment(application.renewal_application_created_at).format(
                  'MMM Do YY'
                )}
              </DescriptionChild>

              <DescriptionChild label='Company Name'>
                {application.user_member_company_name}
              </DescriptionChild>

              <DescriptionChild label='Status'>
                {getStatusLabel(application.renewal_application_status)}
              </DescriptionChild>

              <DescriptionChild label='Download Civil Aviation'>
                <PdfAndImageView
                  file={
                    application.renewal_application_updated_civil_aviation_file
                  }
                />
              </DescriptionChild>

              <DescriptionChild label='Download Trade License'>
                <PdfAndImageView
                  file={
                    application.renewal_application_updated_trade_license_file
                  }
                />
              </DescriptionChild>

              <DescriptionChild label='Download Tax Return'>
                <PdfAndImageView
                  file={
                    application.renewal_application_tax_return_certificate_file
                  }
                />
              </DescriptionChild>

              <DescriptionChild label='Download Previous Certificate'>
                <PdfAndImageView
                  file={
                    application.renewal_application_prev_atab_certificate_file
                  }
                />
              </DescriptionChild>

              {application.renewal_application_reject_reason && (
                <DescriptionChild label='Note'>
                  {application.renewal_application_reject_reason}
                </DescriptionChild>
              )}
            </Description>
          ) : (
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              No Data found
            </Typography>
          )}
        </CardContent>
      </Card>
    </Container>
  ) : (
    <PageLoader />
  );
};

export default SingleRenewalApplication;
