import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { destroyCookie } from 'nookies';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAuthContext } from '../Context/AuthContext/AuthContext';
import { AUTH_USER_FAILED } from '../Helpers/utils/Constant';
import Notifications from '../Components/Notifications/Notifications';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
const DashboardAppBar = ({ drawerWidth, handleDrawerToggle }: any) => {
  const navigate = useNavigate();
  const { user, dispatch: authDispatch } = useAuthContext();
  const queryClient = useQueryClient();
  const { user_member_company_name } = user;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    destroyCookie(null, 'haab_ua');
    localStorage.removeItem('auth');
    authDispatch?.({
      type: AUTH_USER_FAILED,
    });
    queryClient.removeQueries();
    navigate('/login');
  };

  return (
    <>
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: '#00984a',
        }}
      >
        <Toolbar sx={{ ml: 2 }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
            mx={1}
          >
            <Box>
              <Typography variant='h6' noWrap component='div'></Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 3,
              }}
            >
              <Badge
                badgeContent={`${
                  user.user_member_account_status === 'active'
                    ? 'Active'
                    : user.user_member_account_status === 'rejected'
                    ? 'Rejected'
                    : user.user_member_account_status === 'inactive'
                    ? 'InActive'
                    : 'Pending'
                }`}
                color={`${
                  user.user_member_account_status === 'active'
                    ? 'success'
                    : 'warning'
                }`}
                sx={{
                  '& .MuiBadge-badge': {
                    padding: '13px',
                    fontSize: '12px',
                  },
                  mr: 2,
                }}
              />
              <Box sx={{ cursor: 'pointer', position: 'relative' }} pt={1}>
                <Notifications />
              </Box>

              <Box>
                <Tooltip title={user_member_company_name || ''}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt='user' src='/static/images/avatar/2.jpg' />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Link to='/profile'>
                    <MenuItem onClick={() => handleCloseUserMenu()}>
                      <Typography textAlign='center'>Profile</Typography>
                    </MenuItem>
                  </Link>
                  {
                    <MenuItem
                      onClick={() => {
                        handleCloseUserMenu();
                        handleLogout();
                      }}
                    >
                      <Typography textAlign='center'>Logout</Typography>
                    </MenuItem>
                  }
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default DashboardAppBar;
