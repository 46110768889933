import { useState } from 'react';
import CardCompanyInformation from '../card-components/CardCompanyInformation';
import AddCompanyInformation from '../components/AddCompanyInformation';
import { useCompanyDetailsQuery } from '../../../rest/useCompanyInfo';
import { Divider, Card, Box, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UpdateCompanyInformation from '../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCompanyInformation';

const CompanyInformationIndex = () => {
  const { companyData, isLoading } = useCompanyDetailsQuery();
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Card>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <>
            {companyData?.member_company_name && (
              <Tooltip title='Update' sx={{ mr: 2 }}>
                <IconButton onClick={() => setToggle(!toggle)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        </Box>
        <Divider light />

        {toggle ? (
          <UpdateCompanyInformation
            companyData={companyData}
            setToggle={setToggle}
          />
        ) : (
          <>
            {companyData.member_company_name ? (
              <CardCompanyInformation loading={isLoading} data={companyData} />
            ) : (
              <AddCompanyInformation companyData={companyData} />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default CompanyInformationIndex;
